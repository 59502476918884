import styled from 'styled-components';
import { palette } from 'styled-theme';

const AntCheckbox = (ComponentName) => styled(ComponentName)`
  &.ant-checkbox-wrapper {
    font-size: 13px;
    color: ${palette('text', 1)};
    vertical-align: middle;

    &.customCheckBox{
      width: 12px;
      height: 12px;

      :after{
        display: none;
      }
      
      > span.ant-checkbox > span.ant-checkbox-inner{
        width: 12px;
        height: 12px;
      }
      
      > span.ant-checkbox > span.ant-checkbox-inner:after{
        width: 4px;
        height: 7px;
      }
    }
  }
`;

export default AntCheckbox;
