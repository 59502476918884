import React, { memo, useEffect, useState } from 'react';
import { Row, Col } from 'antd';
import Switch from '@iso/components/uielements/switch';
import Select, { SelectOption } from '@iso/components/uielements/select';
import Form from '@iso/components/uielements/form';
import PageHeader from '@iso/components/utility/pageHeader';
import Box from '@iso/components/utility/box';
import LayoutWrapper from '@iso/components/utility/layoutWrapper';
import ContentHolder from '@iso/components/utility/contentHolder';
import basicStyle from '@iso/assets/styles/constants';
import { switchOptions, selectOptions, defaultValues } from '../../AdvancedUI/CodeMirror/config';
import CodeMirror from '@uiw/react-codemirror';
import { StreamLanguage } from "@codemirror/language";
import { shell } from "@codemirror/legacy-modes/mode/shell";
import { yaml } from "@codemirror/legacy-modes/mode/yaml";
import CreateTaskHeader from '../../ScrumBoard/Task/TaskCreateOrUpdate/CreateTaskHeader/CreateTaskHeader';
import { useContext } from 'react';
import { AppContext } from '../../../context/ContextProvider';
// import { javascript } from '@codemirror/lang-javascript';
import { androidstudio, androidstudioInit } from '@uiw/codemirror-theme-androidstudio';
import { BreadcrumbContext } from '../../../context/breadcrumbContext';
import { validateYaml, yamlLinter } from '../../../context/helper';
import { lintGutter } from '@codemirror/lint';
import { linter } from '@codemirror/lint';
import { EditorState } from "@codemirror/state";
import useDebounce from '../../Dashboard/CustomHooks/useDebounce';
// import { tags as t } from '@lezer/highlight';

const FormItem = Form.Item;
const Option = SelectOption;

let code = `%TAG ! tag:clarkevans.com,2002:
--- !shape
  # Use the ! handle for presenting
  # tag:clarkevans.com,2002:circle
- !circle
  center: &ORIGIN {x: 73, y: 129}
  radius: 7
- !line
  start: *ORIGIN
  finish: { x: 89, y: 102 }
- !label
  start: *ORIGIN
  color: 0xFFEEBB
  text: Pretty vector drawing.`;

export default memo(({
  data,
  height,
  minHeight,
  type,
  setData,
  setError,
  customPadding,
  onChange,
  form,
  values,
  submitCount,
  onCancel,
  onDelete,
  onEditCancel,
  tabSelected,
  readOnly
}) => {
  // const { rowStyle, colStyle, gutter } = basicStyle;
  const { openRightDrawer, setOpenRightDrawer, allClusters, setAllClusters, marketplaceData, setMarketplaceData } = useContext(AppContext);
  // const [newYAMLCode, setNewYAMLCode] = useState(data);
  const [extensions, seExtensions] = useState([]);
  const { pageHeading } = useContext(BreadcrumbContext);

  const options = {

  }

  console.log("Re-rendering, :");

  useEffect(() => {
    if (type === "script") {
      seExtensions([
        StreamLanguage.define(shell),
      ]);
    }
    else {
      seExtensions([
        StreamLanguage.define(yaml),
        lintGutter(), // Linting gutter
        linter((view) => yamlLinter(view, form)), // Custom linter function
        EditorState.readOnly.of(readOnly)
      ]);
    }
  }, []);

  // useEffect(() => {
  //   if (marketplaceData.overviewData) {
  //     marketplaceData.overviewData.yaml.updatedContent = newYAMLCode;
  //     setMarketplaceData({ ...marketplaceData });
  //   }
  // }, [newYAMLCode]);

  // useEffect(() => {
  //   console.log("Old and new: ", data, newYAMLCode);
  //     if (data != newYAMLCode) {
  //       console.log("data :", data);
  //       console.log("newYAMLCode :", newYAMLCode);
  //       setNewYAMLCode(data);
  //     }
  // }, [data]);

  const handleChange = (value) => {
    console.log("Calling handleChange: ", value);
    // setNewYAMLCode(value);
    if (type === "script") {
      setData(value);
      setError(false);
    }
    else if (["Add Services", "Edit Services", "Clone Environment Services", "Add New Services"].includes(pageHeading.title)) {
      setData(value);
    }
    else {
      // setNewYAMLCode(value);
      if (marketplaceData.overviewData) {
        marketplaceData.overviewData.yaml.updatedContent = value;
        setMarketplaceData({ ...marketplaceData });
      }
    }

    if (onChange) {
      onChange(value);
    }
  }

  const debouncedChange = useDebounce(handleChange, 300);

  console.log("My debounce: ", debouncedChange);

  return (
    <LayoutWrapper style={{ padding: type === "script" ? "0" : customPadding ? customPadding : "40px 20px", cursor: "text" }}>
      <Box style={{ padding: 0 }}>
        <ContentHolder style={{ marginTop: "0" }}>
          <CodeMirror
            value={data}
            height={height}
            minHeight={minHeight ? minHeight : ""}
            theme={androidstudio}
            // extensions={[javascript({ jsx: true })]}


            // extensions={type === "script" ? [StreamLanguage.define(shell)] : [StreamLanguage.define(yaml)]}

            options={{
              mode: 'yaml', // YAML mode
              lineNumbers: true,
              lint: type !== "script" ? true : false, // Enable linting
              gutters: type !== "script" ? ['CodeMirror-lint-markers'] : [], // Show lint markers
            }}
            extensions={[...extensions]}

            // options={{
            //   theme: 'androidstudio',
            //   keyMap: 'sublime',
            //   mode: 'text/x-yaml',
            // }}
            // options={{
            //   "lineNumbers": true,
            //   "indentWithTabs": true,
            //   "mode": "css",
            //   "gutters": ['CodeMirror-lint-markers'],
            //   "lint": true
            // }}

            onChange={debouncedChange}
          />
        </ContentHolder>
      </Box>
    </LayoutWrapper>
  );
});