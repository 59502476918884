import { React, useState, useEffect, useContext } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { Row, Col, Tooltip, Form, Spin } from 'antd';
import Box from '@iso/components/utility/box';
import LayoutWrapper from '@iso/components/utility/layoutWrapper';
import { SelectOption } from '@iso/components/uielements/select';
import { useLocation, useNavigate } from 'react-router-dom';
import ContentHolder from '@iso/components/utility/contentHolder';
import api from '../../../api';
import { AppContext } from '../../../context/ContextProvider';
import { AlertContext } from '../../../context/alertContext';
// import awsLogo from '../../../assets/images/aws.svg';
// import customScriptIcon from '@iso/assets/images/custom-script-icon.svg';
// import secretDetectionIcon from '@iso/assets/images/secret-detection-icon.svg';
// import sonarcubeIcon from '@iso/assets/images/sonarcube-icon.svg';
// import checkmarkIcon from '@iso/assets/images/checkmark-icon.svg';
import { formatErrors, marketplaceStatuses, timeSince } from '../../../context/helper';
import CreateTaskHeader from '../../ScrumBoard/Task/TaskCreateOrUpdate/CreateTaskHeader/CreateTaskHeader';
import { StickerWidgetWrapper } from '../../Widgets/SmallCards/SmallCards.styles';
import noDataPlusIcon from '@iso/assets/images/nodata-plus-icon.svg';
import noDataImg from '@iso/assets/images/no-data-found.svg';
import plusIcon from '@iso/assets/images/plusIcon.svg';
import Select from '@iso/containers/Deployments/Deployment.styles';
import CreateTaskFooter from '../../ScrumBoard/Task/TaskCreateOrUpdate/CreateTaskFooter/CreateTaskFooter';

const Option = SelectOption;

const noDataSubTitle = {
    color: "#323232",
    fontFamily: "Poppins",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "400",
    marginLeft: "5px"
};
let boxTitleStyle = {
    color: "#1f1f1f",
    fontFamily: "Poppins",
    fontType: "medium",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "20px",
    marginBottom: "1px",
};

let boxSubtitleStyle = {
    fontSize: "12px",
    lineHeight: "20px",
    color: "#9b9b9b",
    fontFamily: "Nunito Sans",
    fontWeight: "400",
    fontType: "regular",
}

const noDataTitle = {
    color: "#A6A6A6",
    textAlign: "center",
    fontFamily: "Nunito Sans",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "300",
};

const titleStyle = {
    color: "#7b7b7b",
    fontFamily: "Poppins",
    fontSize: "12px",
    fontWeight: "300",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
};

const subTitleStyle = {
    color: "#1F1F1F",
    fontFamily: "Poppins",
    fontSize: "15px",
    fontWeight: "600",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
};

let innerTitleStyle = {
    color: "#1f1f1f",
    fontFamily: "Poppins",
    fontType: "medium",
    fontWeight: "500",
    fontSize: "15px"
};

let innerSubtitleStyle = {
    fontSize: "13px",
    color: "#9B9B9B",
    fontFamily: "Nunito Sans",
    fontWeight: "400",
    fontType: "regular",
}

const marginsBot = {
    position: 'fixed',
    opacity: '100%',
    transition: 'all 1s ease',
    top: "7vw",
    right: "0",
    zIndex: '1000',
    width: "25%",
    padding: '15px'
};


const widgetStyle = {
    margin: "20px",
    width: "100%",
};

export default ({
    workflowNodeData,
    workflowEdgesData,
    // serviceId,
    columnId,
}) => {

    const [form] = Form.useForm();
    const navigate = useNavigate();

    const { openRightDrawer, setOpenRightDrawer, workflowData, setWorkflowData, currentWorkflowData, setCurrentWorkflowData, setOpenModel } = useContext(AppContext);
    const { alertOptions, set_alertOptions } = useContext(AlertContext);

    const [deployData, setDeployData] = useState({
        envName: currentWorkflowData?.deployConfig?.config?.env_name ? currentWorkflowData.deployConfig.config.env_name : "",
        envId: "",
        error: null,
    });

    const [envDropdown, setEnvDropdown] = useState([]);
    const [fetching, setFetching] = useState(true);
    const [error, setError] = useState(null);

    // const navigate = useNavigate();
    // const location = useLocation();

    const handelCancel = () => {
        setOpenRightDrawer({
            drawerType: false,
            drawerProps: {
                data: "",
            },
        });
    }

    useEffect(() => {
        console.log("currentWorkflowData in deployClickRightDrawer :", currentWorkflowData);
        let query = `?service_id=${currentWorkflowData?.serviceId}&account_id=${currentWorkflowData?.accountId}`;
        api.get(process.env.REACT_APP_API_URI + `/api/project_env/env/create${query}`)
            .then((res) => {
                let data = res.data;
                console.log("env list :", data);
                if (data.status) {
                    let myEnvs = [];

                    let arr = data.data.sort((a, b) => (a.name.localeCompare(b.name)));

                    let listOfEnvsAlreadySelected = currentWorkflowData.deployConfig.tools.map((item) => item.envId);
                    console.log("listOfEnvsAlreadySelected :", listOfEnvsAlreadySelected);

                    arr.map((temp, idx) => {
                        // Only active, updating and error phase env's
                        if ([0, 1, 2, 3, 8].includes(temp.phase) && !listOfEnvsAlreadySelected.includes(temp.env_unique_id)) {
                            console.log("temp :", temp);
                            myEnvs.push(<Option key={idx} name="envName" data-id={temp.env_unique_id} value={temp.name}>
                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                                    <div>
                                        <img src={temp.account_logo} style={{ width: "18px", height: "18px", marginRight: "10px" }} alt="icon" />
                                        <span>{temp.name}</span>
                                    </div>
                                    {/* <span>{temp.cluster_type}</span> */}
                                </div>
                            </Option>)

                            // when user opens deploy drawer first time - on update
                            if(currentWorkflowData.deployConfig.config.environments && temp.env_unique_id === currentWorkflowData.deployConfig.config.environments[0].env_unique_id){
                                console.log("Found :", temp);
                                setDeployData((prev) => ({envName: temp.env_name, envId: temp.env_unique_id, error: null}));
                                form.setFieldsValue({envName: temp.env_name, envId: temp.env_unique_id, error: null});
                            }
                        }
                    });

                    // If env is selected by user then below
                    if(currentWorkflowData.deployConfig.config.env_unique_id){
                        console.log("changing again :", currentWorkflowData.deployConfig.config);
                        setDeployData((prev) => ({envName: currentWorkflowData.deployConfig.config.env_name, envId: currentWorkflowData.deployConfig.config.env_unique_id, error: null}));
                        form.setFieldsValue({envName: currentWorkflowData.deployConfig.config.env_name, envId: currentWorkflowData.deployConfig.config.env_unique_id, error: null});
                    }

                    setEnvDropdown(myEnvs);
                    // setEnvList(data.data);
                    setFetching(false);
                }
                else if (!data.status) {
                    let err = formatErrors(data.error_details);
                    console.log("err in project_env/create :", err);
                    setFetching(false);
                }
            }).catch((err) => {
                console.log("err :", err);
                setFetching(false);
            });
    }, []);
    
    useEffect(() => {
        console.log("deployData in validate_env :", deployData);
        console.log("currentWorkflowData.deployConfig.config in validate_env :", currentWorkflowData?.deployConfig?.config);
        if(currentWorkflowData && deployData.envId && deployData.envId !== currentWorkflowData.deployConfig.config.env_unique_id){
            api.get(process.env.REACT_APP_API_URI + `/api/pipelines/workflows/validate_env/${deployData.envId}/${currentWorkflowData.serviceId}`)
                .then(async(res) => {
                    let data = res.data;
                    console.log("workflows/validate_env data :", data);
                    if (data.status) {
                        if(data.data.exists){
                            // let msg = data.data.message + ` If you save or execute the changes your workflow(${data.data.workflow_name}) will be replaced by the current workflow(${currentWorkflowData.workflowName}).`;
                            let msg = data.data.message;
                            setDeployData((prev) => ({...deployData, error: msg }));
                            setError(msg);
                            form.setFieldsValue({
                                ...deployData,
                                error: msg,
                            });
                        }
                        else{
                            setDeployData((prev) => ({...deployData, error: null}));
                            form.setFieldsValue({
                                ...deployData,
                                error: null,
                            });
                            setError(null);
                        }
                    }
                    else if (!data.status) {
                        let err = formatErrors(data.error_details);
                        setDeployData((prev) => ({...deployData, error: null}));
                        console.log("err in env check api :", err);
                    }
                }).catch((err) => {
                    setDeployData((prev) => ({...deployData, error: null}));
                    console.log("err :", err);
                });
        }
    }, [deployData.envId]);


    useEffect(() => {
        const test = async() => {
            if(deployData.error){
                try{
                    const values = await form.validateFields();
                    console.log('Success:', values);
                }
                catch (err) {
                    console.log("Found error :", err);
                }
            }
        }

        test();
    }, [deployData.error]);


    const handleClick = (type) => {
        console.log("clicked handleClick :", type);
        setOpenRightDrawer({
            drawerType: type,
            drawerProps: {
                data: workflowData,
                columnId: columnId,
                type: "source",
                className: "expend",
            },
        });
    }

    const saveEnvChanges = () => {
        currentWorkflowData.deployConfig.config = {
            ...currentWorkflowData.deployConfig.config,
            env_unique_id: deployData.envId,
            env_name: deployData.envName,
        };

        setCurrentWorkflowData({...currentWorkflowData});
        localStorage.setItem("Workflow", JSON.stringify({...currentWorkflowData}));
        handelCancel();

        setOpenModel({
            modalType: false,
            modalProps: {
              data: null,
            },
        });
    }

    const handleSaveDetails = async (e) => {
        console.log("clicked handleSaveDetails...",);
        try{
            const values = await form.validateFields();
            console.log('Success:', values);
            saveEnvChanges();
        }
        catch (err) {
            console.log("Found error :", err);
            if(err.errorFields[0].errors[0] === deployData.error){
                // "CONFIRM_ACTION"
                setOpenModel({
                    modalType: 'CONFIRM_ACTION',
                    modalProps: {
                        data: "",
                        title: "Save Workflow?",
                        subTitle: <span style={{display: "block"}}>
                            <p>Are you sure you want to save this environment?</p>
                            {deployData.error && <p style={{ color: "#CB444A", fontSize: "12px", marginTop: "15px" }}> Note: {deployData.error}</p>}
                        </span>,
                        cancelButton: "Cancel",
                        saveButton: "Save",
                        onConfirm: () => { console.log("onConfirm"); saveEnvChanges() },
                        onCancel: () => { console.log("onCancel") },
                        columnId: null,
                        className: "large-Box"
                    },
                });
            }
        }
    }

    const handleSelectChange = (value, event) => {
        console.log("clicked handleSelectChange...");
        if(event.name === "env-redirect"){
            navigate(`/environments/create`, {
                state: {
                    data: null,
                    from: "workflow-deploy"
                }
            });
        }
        else{
            setDeployData({ envName: value, envId: event["data-id"]});
        }
    }
    
    const handleCancel = (e) => {
        console.log("clicked handleCancel...");
        handelCancel();
    }


    return (
        <LayoutWrapper className="clusterInfo" style={{ padding: "0" }}>
            <CreateTaskHeader buttonContent={columnId} onCancel={handelCancel} />

            <div style={{ marginTop: "84px", width: "100%" }} />

            <StickerWidgetWrapper className="isoStickerWidget" style={{ ...widgetStyle, marginTop: "20px" }}>
                <Form
                    form={form}
                    initialValues={{ ...deployData }}
                    preserve={false}
                    name="dynamic_rule"
                    style={{
                        width: "100%",
                    }}
                >
                    <Box
                        title={"Environment*"}
                        subtitle={"Select you environment. Dropdown contains list of environments which have current service have environment variables at run time in it."}
                        titleStyle={boxTitleStyle}
                        subTitleStyle={boxSubtitleStyle}
                        style={{ width: "100%", marginTop: "0", border: "0", padding: "0", marginBottom: "150px" }}
                    >
                        <ContentHolder style={{ width: "100%", marginTop: "6px" }}>
                            <Form.Item
                                name="envName"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select Environment.',
                                    },
                                    {
                                        // Custom validator
                                        validator: (_, value) => {
                                            console.log("deployData in validator :", deployData);
                                            if (value && deployData.error) {
                                                return Promise.reject(new Error(error));
                                            }
                                            else{
                                                return Promise.resolve();
                                            }
                                        }
                                    }
                                ]}
                            >
                                <Select
                                    value={deployData?.envName? deployData?.envName : undefined}
                                    placeholder={"Select Environment"}
                                    onChange={handleSelectChange}
                                    style={{ width: "100%" }}
                                >
                                    {fetching ?
                                            <Option>
                                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "40px" }}>
                                                    <Spin size="small" />
                                                </div>
                                            </Option>
                                        : envDropdown.length > 0
                                        ? envDropdown
                                        : <Option key="0" name="env-redirect" data-alias="" type="integration" style={{ background: "white" }}>
                                            <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "center", height: "fit-content", padding: "3px 0" }}>
                                                <img src={noDataImg} />
                                                <span style={noDataTitle}>No Environments available.</span>
                                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                    <img src={noDataPlusIcon} />
                                                    <span style={noDataSubTitle}>Create one</span>
                                                </div>
                                            </div>
                                        </Option>
                                    }
                                </Select>
                            </Form.Item>
                        </ContentHolder>
                    </Box>
                </Form>
            </StickerWidgetWrapper>

            <CreateTaskFooter buttonContent={"Source Config"} givenButtonFunction1={handleSaveDetails} givenButtonFunction2={handleCancel} />
        </LayoutWrapper>
    );
};