import { useContext } from "react";
import { AppContext, ContextProvider } from "../../../context/ContextProvider";
import { useLocation } from "react-router-dom";
import api from "../../../api";

export const useFetchClusterData = () => {
    const { setClusterTabSelected, updateCluster, oneClickCluster } = useContext(AppContext);
    let location = useLocation();
    const fetchClusterData = async (isUpdate, isOneClick, id) => {
        let myData = {};
        //if (isUpdate) {
        console.log("MYDATA: ", myData);
        let apiStr = isUpdate ? `${process.env.REACT_APP_API_URI}/api/cluster/${id}` : `${process.env.REACT_APP_API_URI}/api/cluster/one-click-cluster/`;
        await api.get(apiStr).then((res) => {
            let data = res.data;
            console.log("Data: ", data.data.cluster_data.environment);
            setClusterTabSelected(data.data.cluster_data.environment);
            localStorage.setItem("clusterTabSelected", data.data.cluster_data.environment);
            let tags = [];
            tags = data.data.cluster_data.tags ? Object.entries(data.data.cluster_data.tags).map(([name, value]) => ({
                name: name,
                value: value
            })) : [{ name: "", value: "" }];
            tags = tags.filter(val => val.name !== "provider");
            tags = tags.length === 0 ? [{ name: "", value: "" }] : tags;
            let selectedAddons = data.data.addons_list.map((val) => {
                return {
                    id: val.addon_id,
                    name: val.name,
                    subText: val.description,
                    recommended: val.is_recommended,
                    editable: isUpdate ? val.editable : true,
                    dependsOn: [],
                    disabled: isOneClick ? true : !val.editable,
                    selected: true,
                    borderColor: "#f5f5f5",
                    message: ""
                };
            });

            if(isOneClick || isUpdate) {
                // localStorage.setItem('no_of_azs', data.data.pre_requsite_data.available_eip_quota);
                localStorage.setItem('isRegionAvailable', "true");
            }
            if (data.data.cluster_data.environment === "prod") {
                myData = {
                    id: id,
                    title: data.data.display_name,
                    accountId: data.data.target_account_id,
                    integration: data.data.integration_type,
                    integrationId: isUpdate ? updateCluster.integrationId : data.data.cluster_data.integration_id,
                    alias: data.data.target_account_alias,
                    region: data.data.cluster_data.region,
                    accountType: "",
                    zonesAvailable: [],
                    selectedCluster: data.data,
                    isDataFetched: false,
                    accountType: "aws", //needs to be changed for multiple account types
                    availability_zones: isOneClick ? data.data.pre_requsite_data.availability_zones : [],
                    vpc: "Create New VPC",
                    vpcData: {},
                    prerequisiteInfo: isOneClick ? data.data.pre_requsite_data : {},
                    prod: {
                        tags: tags,
                        naz: isOneClick ? [data.data.cluster_data.availability_zones] : data.data.cluster_data.availability_zones,
                        selectedAddons: selectedAddons,
                        publicSubnets: [],
                        privateSubnets: [],
                        subnets: [],
                        vpcCidr: data.data.cluster_data.vpc_cidr_block,
                        flowLogMax: "60",    // flow_log_max_aggregation_interval //Will be edited later
                        logRetention: data.data.cluster_data.vpc_flow_logs === "true" ? data.data.cluster_data.vpc_flow_log_retention_period : "60",    // flow_log_cloudwatch_log_group_retention_in_days
                        clusterLogRetention: data.data.cluster_data.vpc_flow_logs === "true" ? data.data.cluster_data.kubernetes_log_retention_period : "90",    // flow_log_cloudwatch_log_group_retention_in_days
                        endpointPublicAccess: true,
                        endpointPrivateAccess: true,
                        publicAccessCidr: "0.0.0.0/0",
                        versionsAvailable: [],
                        addonsData: {},
                        eks: data.data.cluster_data.kubernetes_version,
                        hostedZones: isUpdate ? [] : oneClickCluster[data.data.cluster_data.environment].hostedZones,
                        serverInsType: "",
                        instanceTypeMin: "",
                        instanceTypeMax: "",
                        backups: false,
                        natGatway: true,
                        vpns: true,
                        vpcFlowLogs: data.data.cluster_data.vpc_flow_logs === "true" ? true : false,    // enable_flow_log
                        enableLogTypes: true,
                        addonIds: {},
                        apiServer: isOneClick ? false : data.data.cluster_data.cluster_enabled_log_types ? data.data.cluster_data.cluster_enabled_log_types.includes("api_server") : false,
                        audit: isOneClick ? false : data.data.cluster_data.cluster_enabled_log_types ? data.data.cluster_data.cluster_enabled_log_types.includes("audit") : false,
                        authenticator: isOneClick ? false : data.data.cluster_data.cluster_enabled_log_types ? data.data.cluster_data.cluster_enabled_log_types.includes("authenticator") : false,
                        controllerManager: isOneClick ? false : data.data.cluster_data.cluster_enabled_log_types ? data.data.cluster_data.cluster_enabled_log_types.includes("controller_manager") : false,
                        scheduler: isOneClick ? false : data.data.cluster_data.cluster_enabled_log_types ? data.data.cluster_data.cluster_enabled_log_types.includes("scheduler") : false,
                        addons: {
                            argoFlow: data.data.addons_list.some(val => val.name === "ArgoFlow"),
                            cert_manager_enabled: data.data.addons_list.some(val => val.name === "Cert Manager"),
                            cert_manager_letsencrypt_email: "",
                            cert_manager_install_letsencrypt_http_issuers: false,
                            enable_karpenter: false,
                            karpenter_provisioner_enabled: false,
                            enable_cluster_autoscaler: true,
                            enable_cluster_propotional_autoscaler: false,
                            enable_single_az_ebs_gp3_storage_class: true,
                            create_efs_storage_class: data.data.addons_list.some(val => val.name === "EFS Storage Class"),
                            enable_amazon_eks_aws_ebs_csi_driver: true,
                            enable_amazon_eks_vpc_cni: true,
                            create_service_monitor_crd: true,
                            enable_reloader: data.data.addons_list.some(val => val.name === "Reloader"),
                            enable_metrics_server: true,
                            enable_ingress_nginx: true,
                            enable_external_secrets: false,
                            enable_keda: data.data.addons_list.some(val => val.name === "Keda"),
                            enable_istio: false,
                            enable_aws_node_termination_handler: true,
                            nginx_alb_controler: data.data.addons_list.some(val => val.name === "Ingress ALB Controller"),
                            nginx_ingress_controler: data.data.addons_list.some(val => val.name === "Nginx Ingress Controller"),
                            pgl_stack: data.data.addons_list.some(val => val.name === "PGL stack"),
                            k8s_dashboard: data.data.addons_list.some(val => val.name === "K8s Dashboard"),

                            delete_k8s_dashboard: false //this field will be false by default. It will be true when the user manually disables the enabled k8s dashboard while updating the cluster
                        },
                        updateAddons: {
                            pgl_stack: data.data.addons_list.some(val => val.name === "PGL stack"),
                            // cert_manager_enabled: data.data.addons_list.some(val => val.name === "PGL stack"),
                            // nginx_ingress_controler: data.data.addons_list.some(val => val.name === "PGL stack")
                        },
                        pglStack: {
                            appName: "",
                            recordPrefix: "",
                            hostname: "atmosly.in",
                            zoneId: "",
                            dnsRecordType: "",
                            loki: true,
                            cloudWatchCollector: true,
                            refreshInterval: "30",
                            objExpiration: "90",
                            hostedZonesList: [],
                        },
                        k8sDashboard: {
                            dnsRecordType: "",
                            hostedZonesList: [],
                            hostname: "atmosly.in",
                            recordPrefix: "",
                            zoneId: "",
                        },
                        argoFlow: {
                            dnsRecordType: isOneClick ? data.data.argoworkflow_specifications.dns_record_type : data.data.addons_list.find((val) => val.name === "ArgoFlow")?.value?.dns_record_type,
                            hostedZonesList: [],
                            hostname: isOneClick ? data.data.argoworkflow_specifications.hosted_zone : data.data.addons_list.find((val) => val.name === "ArgoFlow")?.value?.dns_record_type,
                            recordPrefix: isOneClick ? data.data.argoworkflow_specifications.record_prefix : data.data.addons_list.find((val) => val.name === "ArgoFlow")?.value?.dns_record_type,
                            zoneId: isOneClick ? data.data.argoworkflow_specifications.zone_id : data.data.addons_list.find((val) => val.name === "ArgoFlow")?.value?.dns_record_type,
                        }
                    },
                    "non-prod": {
                        tags: [{ name: "", value: "" }],
                        naz: [],
                        selectedAddons: [],
                        vpcCidr: "10.0.0.0/16",
                        flowLogMax: "600",     // flow_log_max_aggregation_interval
                        logRetention: "60",     // flow_log_cloudwatch_log_group_retention_in_days
                        clusterLogRetention: "90",  // cluster_log_retention_in_days
                        eks: "",
                        hostedZones: [],
                        serverInsType: "",
                        instanceTypeMin: "",
                        instanceTypeMax: "",
                        endpointPublicAccess: true,
                        endpointPrivateAccess: true,
                        publicAccessCidr: "0.0.0.0/0",
                        versionsAvailable: [],
                        addonsData: {},
                        backups: false,
                        natGatway: true,
                        vpns: true,
                        vpcFlowLogs: false,    // enable_flow_log
                        enableLogTypes: false,
                        addonIds: {},
                        apiServer: false,
                        audit: false,
                        authenticator: false,
                        controllerManager: false,
                        scheduler: false,
                        addons: {
                            argoFlow: data.data.addons_list.some(val => val.name === "ArgoFlow"),
                            cert_manager_enabled: true,
                            cert_manager_letsencrypt_email: "",
                            cert_manager_install_letsencrypt_http_issuers: false,
                            enable_karpenter: false,
                            karpenter_provisioner_enabled: false,
                            enable_cluster_autoscaler: true,
                            enable_cluster_propotional_autoscaler: false,
                            enable_single_az_ebs_gp3_storage_class: true,
                            create_efs_storage_class: false,
                            enable_amazon_eks_aws_ebs_csi_driver: true,
                            enable_amazon_eks_vpc_cni: true,
                            create_service_monitor_crd: true,
                            enable_reloader: false,
                            enable_metrics_server: true,
                            enable_ingress_nginx: true,
                            enable_external_secrets: false,
                            enable_keda: false,
                            enable_istio: false,
                            enable_aws_node_termination_handler: true,
                            nginx_alb_controler: false,
                            nginx_ingress_controler: true,
                            pgl_stack: true,
                            k8s_dashboard: true,

                            delete_k8s_dashboard: false //this field will be false by default. It will be true when the user manually disables the enabled k8s dashboard while updating the cluster
                        },
                        updateAddons: {},
                        pglStack: {
                            appName: "",
                            recordPrefix: "",
                            hostname: "atmosly.in",
                            zoneId: "",
                            dnsRecordType: "",
                            loki: true,
                            cloudWatchCollector: false,
                            refreshInterval: "30",
                            objExpiration: "90",
                            hostedZonesList: [],
                        },
                        k8sDashboard: {
                            dnsRecordType: "",
                            hostedZonesList: [],
                            hostname: "atmosly.in",
                            recordPrefix: "",
                            zoneId: "",
                        },
                        argoFlow: {
                            dnsRecordType: "",
                            hostedZonesList: [],
                            hostname: "atmosly.in",
                            recordPrefix: "",
                            zoneId: "",
                        }
                    },
                    dev: {
                        tags: [{ name: "", value: "" }],
                        naz: [],
                        selectedAddons: [],
                        vpcCidr: "",
                        flowLogMax: "0",
                        logRetention: "60",
                        clusterLogRetention: "90",
                        endpointPublicAccess: true,
                        endpointPrivateAccess: true,
                        publicAccessCidr: "0.0.0.0/0",
                        versionsAvailable: [],
                        addonsData: {},
                        eks: "",
                        hostedZones: [],
                        serverInsType: "",
                        instanceTypeMin: "",
                        instanceTypeMax: "",
                        backups: false,
                        natGatway: false,
                        vpns: false,
                        vpcFlowLogs: false,
                        enableLogTypes: false,
                        addonIds: {},
                        apiServer: false,
                        audit: false,
                        authenticator: false,
                        controllerManager: false,
                        scheduler: false,
                        addons: {
                            argoFlow: true,
                            cert_manager_enabled: true,
                            cert_manager_letsencrypt_email: "",
                            cert_manager_install_letsencrypt_http_issuers: false,
                            enable_karpenter: false,
                            karpenter_provisioner_enabled: false,
                            enable_cluster_autoscaler: true,
                            enable_cluster_propotional_autoscaler: false,
                            enable_single_az_ebs_gp3_storage_class: true,
                            create_efs_storage_class: false,
                            enable_amazon_eks_aws_ebs_csi_driver: true,
                            enable_amazon_eks_vpc_cni: true,
                            create_service_monitor_crd: true,
                            enable_reloader: false,
                            enable_metrics_server: true,
                            enable_ingress_nginx: true,
                            enable_external_secrets: false,
                            enable_keda: false,
                            enable_istio: false,
                            enable_aws_node_termination_handler: true,
                            nginx_alb_controler: false,
                            nginx_ingress_controler: true,
                            pgl_stack: false,
                            k8s_dashboard: false,

                            delete_k8s_dashboard: false //this field will be false by default. It will be true when the user manually disables the enabled k8s dashboard while updating the cluster
                        },
                        updateAddons: {},
                        pglStack: {
                            appName: "",
                            loki: false,
                            cloudWatchCollector: false,
                            hostname: "",
                            objExpiration: "",
                            hostedZonesList: [],
                        },
                        k8sDashboard: {
                            dnsRecordType: "",
                            hostedZonesList: [],
                            hostname: "atmosly.in",
                            recordPrefix: "",
                            zoneId: "",
                        },
                        argoFlow: {
                            dnsRecordType: "",
                            hostedZonesList: [],
                            hostname: "atmosly.in",
                            recordPrefix: "",
                            zoneId: "",
                        }
                    },
                    existingVpc: {
                        tags: [{ name: "", value: "" }],
                        selectedAddons: [],
                        naz: [],
                        publicSubnets: [],
                        privateSubnets: [],
                        subnets: [],
                        hostedZones: [],
                        vpcCidr: "10.0.0.0/16",
                        flowLogMax: "60",    // flow_log_max_aggregation_interval
                        logRetention: "60",    // flow_log_cloudwatch_log_group_retention_in_days
                        clusterLogRetention: "90",    // cluster_log_retention_in_days
                        endpointPublicAccess: true,
                        endpointPrivateAccess: true,
                        publicAccessCidr: "0.0.0.0/0",
                        versionsAvailable: [],
                        addonsData: {},
                        eks: "",
                        serverInsType: "",
                        instanceTypeMin: "",
                        instanceTypeMax: "",
                        backups: false,
                        natGatway: true,
                        vpns: true,
                        vpcFlowLogs: true,    // enable_flow_log
                        enableLogTypes: true,
                        addonIds: {},
                        apiServer: false,
                        audit: false,
                        authenticator: false,
                        controllerManager: false,
                        scheduler: false,
                        addons: {
                            cert_manager_enabled: false,
                            cert_manager_letsencrypt_email: "",
                            cert_manager_install_letsencrypt_http_issuers: false,
                            enable_karpenter: false,
                            karpenter_provisioner_enabled: false,
                            enable_cluster_autoscaler: true,
                            enable_cluster_propotional_autoscaler: false,
                            enable_single_az_ebs_gp3_storage_class: true,
                            create_efs_storage_class: false,
                            enable_amazon_eks_aws_ebs_csi_driver: true,
                            enable_amazon_eks_vpc_cni: true,
                            create_service_monitor_crd: true,
                            enable_reloader: false,
                            enable_metrics_server: true,
                            enable_ingress_nginx: true,
                            enable_external_secrets: false,
                            enable_keda: false,
                            enable_istio: false,
                            enable_aws_node_termination_handler: true,
                            nginx_alb_controler: false,
                            nginx_ingress_controler: true,
                            pgl_stack: true,
                            k8s_dashboard: true,
                            argoFlow: true,

                            delete_k8s_dashboard: false //this field will be false by default. It will be true when the user manually disables the enabled k8s dashboard while updating the cluster
                        },
                        pglStack: {
                            appName: "",
                            recordPrefix: "",
                            hostname: "atmosly.in",
                            zoneId: "",
                            dnsRecordType: "",
                            loki: true,
                            cloudWatchCollector: true,
                            refreshInterval: "30",
                            objExpiration: "90",
                            hostedZonesList: [],
                        },
                        k8sDashboard: {
                            recordPrefix: "",
                            hostname: "atmosly.in",
                            zoneId: "",
                            dnsRecordType: "",
                            hostedZonesList: [],
                        },
                        argoFlow: {
                            recordPrefix: "",
                            hostname: "atmosly.in",
                            zoneId: "",
                            dnsRecordType: "",
                            hostedZonesList: [],
                        }
                    }
                };
            }
            else {
                myData = {
                    id: id,
                    title: data.data.display_name,
                    accountId: data.data.target_account_id,
                    integration: data.data.integration_type,
                    integrationId: isUpdate ? updateCluster.integrationId : data.data.cluster_data.integration_id,
                    alias: data.data.target_account_alias,
                    region: data.data.cluster_data.region,
                    accountType: "",
                    zonesAvailable: [],
                    selectedCluster: data.data,
                    isDataFetched: false,
                    accountType: "aws", //needs to be changed for multiple account types
                    availability_zones: isOneClick ? data.data.pre_requsite_data.availability_zones : [],
                    vpc: "Create New VPC",
                    vpcData: {},
                    prerequisiteInfo: isOneClick ? data.data.pre_requsite_data : {},
                    prod: {
                        tags: [{ name: "", value: "" }],
                        naz: [],
                        selectedAddons: [],
                        vpcCidr: "10.0.0.0/16",
                        flowLogMax: "60",    // flow_log_max_aggregation_interval
                        logRetention: "60",    // flow_log_cloudwatch_log_group_retention_in_days
                        clusterLogRetention: "90",    // cluster_log_retention_in_days
                        endpointPublicAccess: true,
                        endpointPrivateAccess: true,
                        publicAccessCidr: "0.0.0.0/0",
                        versionsAvailable: [],
                        addonsData: {},
                        eks: "",
                        hostedZones: [],
                        serverInsType: "",
                        instanceTypeMin: "",
                        instanceTypeMax: "",
                        backups: false,
                        natGatway: true,
                        vpns: true,
                        vpcFlowLogs: true,    // enable_flow_log
                        enableLogTypes: true,
                        addonIds: {},
                        apiServer: false,
                        audit: false,
                        authenticator: false,
                        controllerManager: false,
                        scheduler: false,
                        addons: {
                            argoFlow: true,
                            cert_manager_enabled: true,
                            cert_manager_letsencrypt_email: "",
                            cert_manager_install_letsencrypt_http_issuers: false,
                            enable_karpenter: false,
                            karpenter_provisioner_enabled: false,
                            enable_cluster_autoscaler: true,
                            enable_cluster_propotional_autoscaler: false,
                            enable_single_az_ebs_gp3_storage_class: true,
                            create_efs_storage_class: false,
                            enable_amazon_eks_aws_ebs_csi_driver: true,
                            enable_amazon_eks_vpc_cni: true,
                            create_service_monitor_crd: true,
                            enable_reloader: false,
                            enable_metrics_server: true,
                            enable_ingress_nginx: true,
                            enable_external_secrets: false,
                            enable_keda: false,
                            enable_istio: false,
                            enable_aws_node_termination_handler: true,
                            nginx_alb_controler: false,
                            nginx_ingress_controler: true,
                            pgl_stack: true,
                            k8s_dashboard: false,

                            delete_k8s_dashboard: false //this field will be false by default. It will be true when the user manually disables the enabled k8s dashboard while updating the cluster
                        },
                        updateAddons: {},
                        pglStack: {
                            appName: "",
                            recordPrefix: "",
                            hostname: "atmosly.in",
                            zoneId: "",
                            dnsRecordType: "",
                            loki: true,
                            cloudWatchCollector: true,
                            refreshInterval: "30",
                            objExpiration: "90",
                            hostedZonesList: [],
                        },
                        k8sDashboard: {
                            dnsRecordType: "",
                            hostedZonesList: [],
                            hostname: "atmosly.in",
                            recordPrefix: "",
                            zoneId: "",
                        },
                        argoFlow: {
                            dnsRecordType: "",
                            hostedZonesList: [],
                            hostname: "atmosly.in",
                            recordPrefix: "",
                            zoneId: "",
                        }
                    },
                    "non-prod": {
                        tags: tags,
                        naz: isOneClick ? [data.data.cluster_data.availability_zones] : data.data.cluster_data.availability_zones,
                        selectedAddons: selectedAddons,
                        publicSubnets: [],
                        privateSubnets: [],
                        subnets: [],
                        vpcCidr: data.data.cluster_data.vpc_cidr_block,
                        flowLogMax: "60",     // flow_log_max_aggregation_interval
                        logRetention: data.data.cluster_data.vpc_flow_logs === "true" ? data.data.cluster_data.vpc_flow_log_retention_period : "60",    // flow_log_cloudwatch_log_group_retention_in_days
                        clusterLogRetention: data.data.cluster_data.vpc_flow_logs === "true" ? data.data.cluster_data.kubernetes_log_retention_period : "90",    // flow_log_cloudwatch_log_group_retention_in_days
                        eks: data.data.cluster_data.kubernetes_version,
                        hostedZones: isUpdate ? [] : oneClickCluster[data.data.cluster_data.environment].hostedZones,
                        serverInsType: "",
                        instanceTypeMin: "",
                        instanceTypeMax: "",
                        endpointPublicAccess: true,
                        endpointPrivateAccess: true,
                        publicAccessCidr: "0.0.0.0/0",
                        versionsAvailable: [],
                        addonsData: {},
                        backups: false,
                        natGatway: true,
                        vpns: true,
                        vpcFlowLogs: data.data.cluster_data.vpc_flow_logs === "true" ? true : false,    // enable_flow_log
                        enableLogTypes: false,
                        addonIds: {},
                        apiServer: isOneClick ? false : data.data.cluster_data.cluster_enabled_log_types ? data.data.cluster_data.cluster_enabled_log_types.includes("api_server") : false,
                        audit: isOneClick ? false : data.data.cluster_data.cluster_enabled_log_types ? data.data.cluster_data.cluster_enabled_log_types.includes("audit") : false,
                        authenticator: isOneClick ? false : data.data.cluster_data.cluster_enabled_log_types ? data.data.cluster_data.cluster_enabled_log_types.includes("authenticator") : false,
                        controllerManager: isOneClick ? false : data.data.cluster_data.cluster_enabled_log_types ? data.data.cluster_data.cluster_enabled_log_types.includes("controller_manager") : false,
                        scheduler: isOneClick ? false : data.data.cluster_data.cluster_enabled_log_types ? data.data.cluster_data.cluster_enabled_log_types.includes("scheduler") : false,
                        addons: {
                            argoFlow: data.data.addons_list.some(val => val.name === "ArgoFlow"),
                            cert_manager_enabled: data.data.addons_list.some(val => val.name === "Cert Manager"),
                            cert_manager_letsencrypt_email: "",
                            cert_manager_install_letsencrypt_http_issuers: false,
                            enable_karpenter: false,
                            karpenter_provisioner_enabled: false,
                            enable_cluster_autoscaler: true,
                            enable_cluster_propotional_autoscaler: false,
                            enable_single_az_ebs_gp3_storage_class: true,
                            create_efs_storage_class: data.data.addons_list.some(val => val.name === "EFS Storage Class"),
                            enable_amazon_eks_aws_ebs_csi_driver: true,
                            enable_amazon_eks_vpc_cni: true,
                            create_service_monitor_crd: true,
                            enable_reloader: data.data.addons_list.some(val => val.name === "Reloader"),
                            enable_metrics_server: true,
                            enable_ingress_nginx: true,
                            enable_external_secrets: false,
                            enable_keda: data.data.addons_list.some(val => val.name === "Keda"),
                            enable_istio: false,
                            enable_aws_node_termination_handler: true,
                            nginx_alb_controler: data.data.addons_list.some(val => val.name === "Ingress ALB Controller"),
                            nginx_ingress_controler: data.data.addons_list.some(val => val.name === "Nginx Ingress Controller"),
                            pgl_stack: data.data.addons_list.some(val => val.name === "PGL stack"),
                            k8s_dashboard: data.data.addons_list.some(val => val.name === "K8s Dashboard"),

                            delete_k8s_dashboard: false //this field will be false by default. It will be true when the user manually disables the enabled k8s dashboard while updating the cluster
                        },
                        updateAddons: {
                            pgl_stack: data.data.addons_list.some(val => val.name === "PGL stack"),
                        },
                        pglStack: {
                            appName: "",
                            recordPrefix: "",
                            hostname: "atmosly.in",
                            zoneId: "",
                            dnsRecordType: "",
                            loki: true,
                            cloudWatchCollector: false,
                            refreshInterval: "30",
                            objExpiration: "90",
                            hostedZonesList: [],
                        },
                        k8sDashboard: {
                            dnsRecordType: "",
                            hostedZonesList: [],
                            hostname: "atmosly.in",
                            recordPrefix: "",
                            zoneId: "",
                        },
                        argoFlow: {
                            dnsRecordType: isOneClick ? data.data.argoworkflow_specifications.dns_record_type : data.data.addons_list.find((val) => val.name === "ArgoFlow")?.value?.dns_record_type,
                            hostedZonesList: [],
                            hostname: isOneClick ? data.data.argoworkflow_specifications.hosted_zone : data.data.addons_list.find((val) => val.name === "ArgoFlow")?.value?.dns_record_type,
                            recordPrefix: isOneClick ? data.data.argoworkflow_specifications.record_prefix : data.data.addons_list.find((val) => val.name === "ArgoFlow")?.value?.dns_record_type,
                            zoneId: isOneClick ? data.data.argoworkflow_specifications.zone_id : data.data.addons_list.find((val) => val.name === "ArgoFlow")?.value?.dns_record_type,
                        }
                    },
                    dev: {
                        tags: [{ name: "", value: "" }],
                        naz: [],
                        selectedAddons: [],
                        vpcCidr: "",
                        flowLogMax: "0",
                        logRetention: "60",
                        clusterLogRetention: "90",
                        endpointPublicAccess: true,
                        endpointPrivateAccess: true,
                        publicAccessCidr: "0.0.0.0/0",
                        versionsAvailable: [],
                        addonsData: {},
                        eks: "",
                        hostedZones: [],
                        serverInsType: "",
                        instanceTypeMin: "",
                        instanceTypeMax: "",
                        backups: false,
                        natGatway: false,
                        vpns: false,
                        vpcFlowLogs: false,
                        enableLogTypes: false,
                        addonIds: {},
                        apiServer: false,
                        audit: false,
                        authenticator: false,
                        controllerManager: false,
                        scheduler: false,
                        addons: {
                            argoFlow: true,
                            cert_manager_enabled: true,
                            cert_manager_letsencrypt_email: "",
                            cert_manager_install_letsencrypt_http_issuers: false,
                            enable_karpenter: false,
                            karpenter_provisioner_enabled: false,
                            enable_cluster_autoscaler: true,
                            enable_cluster_propotional_autoscaler: false,
                            enable_single_az_ebs_gp3_storage_class: true,
                            create_efs_storage_class: false,
                            enable_amazon_eks_aws_ebs_csi_driver: true,
                            enable_amazon_eks_vpc_cni: true,
                            create_service_monitor_crd: true,
                            enable_reloader: false,
                            enable_metrics_server: true,
                            enable_ingress_nginx: true,
                            enable_external_secrets: false,
                            enable_keda: false,
                            enable_istio: false,
                            enable_aws_node_termination_handler: true,
                            nginx_alb_controler: false,
                            nginx_ingress_controler: true,
                            pgl_stack: false,
                            k8sDashboard: false
                        },
                        updateAddons: {},
                        pglStack: {
                            appName: "",
                            hostname: "",
                            loki: false,
                            cloudWatchCollector: false,
                            hostname: "",
                            objExpiration: "",
                            hostedZonesList: [],
                        },
                        k8sDashboard: {
                            dnsRecordType: "",
                            hostedZonesList: [],
                            hostname: "atmosly.in",
                            recordPrefix: "",
                            zoneId: "",
                        },
                        argoFlow: {
                            dnsRecordType: "",
                            hostedZonesList: [],
                            hostname: "atmosly.in",
                            recordPrefix: "",
                            zoneId: "",
                        }
                    },
                    existingVpc: {
                        tags: [{ name: "", value: "" }],
                        selectedAddons: [],
                        naz: [],
                        publicSubnets: [],
                        privateSubnets: [],
                        subnets: [],
                        hostedZones: [],
                        vpcCidr: "10.0.0.0/16",
                        flowLogMax: "60",    // flow_log_max_aggregation_interval
                        logRetention: "60",    // flow_log_cloudwatch_log_group_retention_in_days
                        clusterLogRetention: "90",    // cluster_log_retention_in_days
                        endpointPublicAccess: true,
                        endpointPrivateAccess: true,
                        publicAccessCidr: "0.0.0.0/0",
                        versionsAvailable: [],
                        addonsData: {},
                        eks: "",
                        serverInsType: "",
                        instanceTypeMin: "",
                        instanceTypeMax: "",
                        backups: false,
                        natGatway: true,
                        vpns: true,
                        vpcFlowLogs: true,    // enable_flow_log
                        enableLogTypes: true,
                        addonIds: {},
                        apiServer: false,
                        audit: false,
                        authenticator: false,
                        controllerManager: false,
                        scheduler: false,
                        addons: {
                            cert_manager_enabled: false,
                            cert_manager_letsencrypt_email: "",
                            cert_manager_install_letsencrypt_http_issuers: false,
                            enable_karpenter: false,
                            karpenter_provisioner_enabled: false,
                            enable_cluster_autoscaler: true,
                            enable_cluster_propotional_autoscaler: false,
                            enable_single_az_ebs_gp3_storage_class: true,
                            create_efs_storage_class: false,
                            enable_amazon_eks_aws_ebs_csi_driver: true,
                            enable_amazon_eks_vpc_cni: true,
                            create_service_monitor_crd: true,
                            enable_reloader: false,
                            enable_metrics_server: true,
                            enable_ingress_nginx: true,
                            enable_external_secrets: false,
                            enable_keda: false,
                            enable_istio: false,
                            enable_aws_node_termination_handler: true,
                            nginx_alb_controler: false,
                            nginx_ingress_controler: true,
                            pgl_stack: true,
                            k8s_dashboard: true,
                            argoFlow: true,

                            delete_k8s_dashboard: false //this field will be false by default. It will be true when the user manually disables the enabled k8s dashboard while updating the cluster
                        },
                        pglStack: {
                            appName: "",
                            recordPrefix: "",
                            hostname: "atmosly.in",
                            zoneId: "",
                            dnsRecordType: "",
                            loki: true,
                            cloudWatchCollector: true,
                            refreshInterval: "30",
                            objExpiration: "90",
                            hostedZonesList: [],
                        },
                        k8sDashboard: {
                            recordPrefix: "",
                            hostname: "atmosly.in",
                            zoneId: "",
                            dnsRecordType: "",
                            hostedZonesList: [],
                        },
                        argoFlow: {
                            recordPrefix: "",
                            hostname: "atmosly.in",
                            zoneId: "",
                            dnsRecordType: "",
                            hostedZonesList: [],
                        }
                    }
                };
            }
            console.log("Updated myData: ", myData);
            //return myData;
        }).catch((err) => {
            console.log("Error", err);
            return "error";
            //return {};
        });
        console.log("My data: ", myData);
        return myData;
    }
    //}
    return { fetchClusterData };
}