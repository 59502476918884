import { useContext, useEffect, useState } from "react";
import CreateTaskFooter from "../../ScrumBoard/Task/TaskCreateOrUpdate/CreateTaskFooter/CreateTaskFooter";
import CreateTaskHeader from "../../ScrumBoard/Task/TaskCreateOrUpdate/CreateTaskHeader/CreateTaskHeader";
import { AppContext } from "../../../context/ContextProvider";
import { GuardrailsContext } from "../../../context/guardrailsContext";
import ActionBox from "../GuardrailFlow/ActionBox";
import Box from "../../../components/utility/box";
import { Button, Calendar, DatePicker, Form, Input, Modal, Select, TimePicker, Tooltip } from "antd";
import SituationBox from "../GuardrailFlow/SituationBox";
import { Option } from "antd/es/mentions";
import styled from "styled-components";
import { Label } from "../../UsersCRUD/UsersCRUD.styles";
import dayjs from 'dayjs';
import OperationBox from "../GuardrailFlow/OperationBox";
import { useForm } from "antd/es/form/Form";
import { useGuardrailNode } from "../GuardrailFlow/useGuardrailNode";
import api from "../../../api";
import { ReactFlow } from "reactflow";
import { InfoCircleFilled } from '@ant-design/icons';


let nodeStyle = {
  width: "433px",
  height: "93px",
  padding: "21px 3px 20px 8px",
  borderRadius: "10px",
  backgroundColor: "rgba(236, 244, 251, 0.7)",
};

let titleStyle = {
  width: "128px",
  height: "20px",
  flexGrow: "0",
  margin: "0 70px 1px 0",
  fontFamily: "Poppins",
  fontSize: "14px",
  fontWeight: "500",
  fontStretch: "normal",
  fontStyle: "normal",
  lineHeight: "1.43",
  letterSpacing: "normal",
  textAlign: "left",
  color: "#1f1f1f",
};

let subTitleStyle = {
  height: "20px",
  flexGrow: "0",
  margin: "1px 0 0",
  fontFamily: "Nunito Sans",
  fontSize: "14px",
  fontWeight: "normal",
  fontStretch: "normal",
  fontStyle: "normal",
  lineHeight: "1.67",
  letterSpacing: "normal",
  textAlign: "left",
  color: "#9b9b9b",
}

const labelStyle = {
  width: "94px",
  height: "20px",
  margin: "0 93px 10px 0",
  fontFamily: "Poppins",
  fontSize: "14px",
  fontWeight: "normal",
  fontStretch: "normal",
  fontStyle: "normal",
  lineHeight: "1.43",
  letterSpacing: "-0.18px",
  textAlign: "left",
  color: "#1f1f1f",
}

export const CustomDatePicker = styled(DatePicker)`
  .myDropdown {
    width: 60%;
  }
  .myDropdown{
    .ant-picker-dropdown {
      width: 50%;
    }
    .ant-picker-dropdown .ant-picker-panel-container .ant-picker-panel {
      width: 100%;
    }
    .ant-picker-dropdown .ant-picker-date-panel {
      width: 100%;
    }
    .ant-picker-dropdown .ant-picker-panel-container .ant-picker-panel table {
      width: 100%;
    }
  }
`;

export const CustomCalendar = styled(Calendar)`
.ant-picker-cell-inner::before {
  border: 0 !important;
}
`;

export default function GuardrailInfoDrawer({ data, openGuardrailInfo, setOpenGuardrailInfo, modalLoaded, setModalLoaded }) {
  const { setOpenRightDrawer } = useContext(AppContext);
  const { setGuardrailEdgesData, setGuardrailNodesData, guardrailNodesData, guardrailEdgesData, selectedGuardrailInfo, setSelectedGuardrailInfo, setGuardarailNodesData, setGuardarailEdgesData } = useContext(GuardrailsContext);
  console.log("Data: ", data);
  const [actionsList, setActionsList] = useState([]);
  const [operationsList, setOperationsList] = useState([]);
  const [fieldsList, setFieldsList] = useState([]);
  const [showCal, setShowCal] = useState(false);
  const [frequencies, setFrequencies] = useState([]);
  const [typeList, setTypeList] = useState([]);
  const [phasesList, setPhasesList] = useState([]);
  let [, tzName] = /.*\s(.+)/.exec((new Date()).toLocaleDateString(navigator.language, { timeZoneName: 'short' }));

  const [myData, setMyData] = useState(JSON.parse(JSON.stringify({ ...selectedGuardrailInfo }))); //this ensures that selectedGuardrailInfo doesn't get updated if we update myData
  console.log("myData: ", myData);
  let conditionNo = data.heading === "Configure Condition" ? data.conditionNo : guardrailNodesData.length + 1 - 3; //after adding the new node, it's length increases by 1
  let [days, setDays] = useState([
    {
      name: "Monday",
      status: myData?.info?.repeatOn?.some(val => val === 0)
    },
    {
      name: "Tuesday",
      status: myData?.info?.repeatOn?.some(val => val === 1)
    },
    {
      name: "Wednesday",
      status: myData?.info?.repeatOn?.some(val => val === 2)
    },
    {
      name: "Thursday",
      status: myData?.info?.repeatOn?.some(val => val === 3)
    },
    {
      name: "Friday",
      status: myData?.info?.repeatOn?.some(val => val === 4)
    },
    {
      name: "Saturday",
      status: myData?.info?.repeatOn?.some(val => val === 5)
    },
    {
      name: "Sunday",
      status: myData?.info?.repeatOn?.some(val => val === 6)
    }]);
  let freqs = ["Does not repeat", "Weekly", "Monthly"];
  const [form] = Form.useForm();
  const [error, setError] = useState({
    status: "",
    msg: ""
  });
  const { handleAddNode, updateCondition } = useGuardrailNode();


  useEffect(() => {
    let arr = [];
    if (data.heading === "Configure Action") {
      myData.info?.actionsList?.forEach((val) => {
        console.log('Option 1: ', val);
        arr.push(<Option key={val.id} value={val.name}>{val.name}</Option>);
      });
      setActionsList(arr);
    }
    else {
      myData.info.actionsList.forEach((val) => {
        console.log('Option 1: ', val);
        arr.push(<Option key={val.id} value={val.name}>{val.name}</Option>);
      });
      setActionsList(arr);
      let fields = [];
      Object.keys(myData.info.filterOptions).forEach((val) => {
        fields.push(<Option name="param" key={val} value={val}>{val}</Option>);
      });
      setFieldsList(fields);
      freqs.forEach((val, idx) => {
        frequencies.push(<Option name="repeatFrequency" value={(idx + 1).toString()}>{val}</Option>);
      });
      setFrequencies([...frequencies]);

      let types = ["prod", "non-prod"];
      let typesOptions = [];
      types.forEach((val, idx) => {
        typesOptions.push(<Option key={val} name="value" value={val}>{val}</Option>);
      });
      setTypeList(typesOptions);
      let phases = ["Creating", "Created", "Addons Initialising", "Active", "Addons Destroying", "Destroying", "Failed", "Updating", "Pending", "Destroyed", "Out Of Sync", "Destroy Failed", "Cluster Updating", "Update Failed"];
      let phasesOptions = [];
      phases.forEach((val, idx) => {
        phasesOptions.push(<Option key={val} name="value" value={val}>{val}</Option>);
      });
      setPhasesList(phasesOptions);
    }

    return (() => {
      setMyData({});
    });
  }, []);

  useEffect(() => {
    if (myData.info?.selectedFilters?.length > 0 && myData.info?.selectedFilters[conditionNo - 1]?.param) {
      console.log("Executes: ", myData.info.param);
      let ops = [];
      myData.info.filterOptions[myData.info.selectedFilters[conditionNo - 1].param].forEach((val) => {
        ops.push(<Option key={val} name="filter" value={val}>{val}</Option>);
      });
      setOperationsList(ops);
    }
    return (() => {
      setOperationsList([]);
    })
  }, []);

  useEffect(() => {
    if (myData.info?.selectedFilters?.length > 0) {
      form.setFieldsValue({
        param: myData.info?.selectedFilters[conditionNo - 1]?.param,
        filter: myData.info?.selectedFilters[conditionNo - 1]?.filter,
        value: myData.info?.selectedFilters[conditionNo - 1]?.value,
        repeatFrequency: myData.info?.repeatFrequency,
        scheduledDate: myData.info?.scheduledDate === "" ? "" : dayjs(myData.info?.scheduledDate),
        scheduledTime: myData.info?.scheduledTime === "" ? "" : dayjs(myData.info?.scheduledTime, 'HH:mm'),
        repeatOccurrence: myData.info?.repeatOccurrence
      });
    }
  }, [myData]);

  const handleSaveSituation = () => {
    guardrailNodesData[0] = {
      id: "1",
      type: "what",
      data: {
        label: <SituationBox operation={myData.info.name} />
      },
      position: { x: 50, y: 100 },
      style: nodeStyle,
    };
    setGuardrailNodesData([...guardrailNodesData]);
  }

  // const nextAvailableNode = () => {
  //   for (let i = 2; i < guardrailNodesData.length; i++) {
  //     //if(guardrailNodesData[i])
  //     let connectedCount = guardrailEdgesData.filter((val) => val.source === guardrailNodesData[i].id);
  //     console.log("My connected count: ", connectedCount);
  //     if (i === 2 && connectedCount.length < 2) {
  //       return guardrailNodesData[i];
  //     }
  //     else if (connectedCount.length < 1) {
  //       return guardrailNodesData[i];
  //     }
  //   }
  //   return null;
  // }

  // const handleAddNode = () => {
  //   let availableNode = nextAvailableNode();
  //   let myNodeObj = {};
  //   if (availableNode.id === "3") {
  //     let connectedCount = guardrailEdgesData.filter((val) => val.source === availableNode.id);
  //     if (connectedCount.length === 1) {
  //       myNodeObj = {
  //         id: (guardrailNodesData.length + 1).toString(),
  //         type: "if",
  //         data: { label: <ActionBox conditionField={myData.info.selectedFilters[conditionNo - 1].param} conditionOperation={myData.info.selectedFilters[conditionNo - 1].filter} conditionValue={myData.info.selectedFilters[conditionNo - 1].value} conditionNo={conditionNo.toString()} /> },
  //         position: { x: 323 + (guardrailNodesData[guardrailNodesData.length - 1].position.x), y: 600 },
  //         style: nodeStyle,
  //       }
  //       let prevNode = guardrailNodesData[guardrailNodesData.length - 1];
  //       prevNode = {
  //         ...prevNode,
  //         position: { x: prevNode.position.x - 323, y: 600 }
  //       };
  //       guardrailNodesData[guardrailNodesData.length - 1] = prevNode;
  //     }
  //     else {
  //       myNodeObj = {
  //         id: (guardrailNodesData.length + 1).toString(),
  //         type: "if",
  //         data: { label: <ActionBox conditionField={myData.info.selectedFilters[conditionNo - 1].param} conditionOperation={myData.info.selectedFilters[conditionNo - 1].filter} conditionValue={myData.info.selectedFilters[conditionNo - 1].value} conditionNo={conditionNo.toString()} /> },
  //         position: { x: 473, y: 600 },
  //         style: nodeStyle,
  //       }
  //     }
  //   }
  //   else {
  //     myNodeObj = {
  //       id: (guardrailNodesData.length + 1).toString(),
  //       type: "if",
  //       data: { label: <ActionBox conditionField={myData.info.selectedFilters[conditionNo - 1].param} conditionOperation={myData.info.selectedFilters[conditionNo - 1].filter} conditionValue={myData.info.selectedFilters[conditionNo - 1].value} conditionNo={conditionNo.toString()} /> },
  //       position: { x: guardrailNodesData[guardrailNodesData.length - 2].position.x, y: guardrailNodesData[guardrailNodesData.length - 2].position.y + 200 },
  //       style: nodeStyle,
  //     };
  //   }
  //   let myEdgeObj = {
  //     id: "e" + (availableNode.id) + "-" + (guardrailNodesData.length + 1),
  //     source: (availableNode.id),
  //     target: (guardrailNodesData.length + 1).toString(),
  //     type: "customEdge"
  //   }
  //   console.log("New node and edge: ", myNodeObj, myEdgeObj);
  //   setGuardrailNodesData([...guardrailNodesData, myNodeObj]);
  //   setGuardrailEdgesData([...guardrailEdgesData, myEdgeObj]);

  //   //let availableNode = nextAvailableNode();
  //   // let myNodeObj = {
  //   //   id: (guardrailNodesData.length + 1).toString(),
  //   //   type: "if",
  //   //   data: { label: <ActionBox /> },
  //   //   position: { x: 473 + (guardrailNodesData[guardrailNodesData.length - 1].position.x), y: 600 },
  //   //   style: nodeStyle,
  //   // }
  //   // let myEdgeObj = {
  //   //   //id: "e" + (availableNode.id) + "-" + (guardrailNodesData.length + 1),
  //   //   id: "e3-" + (guardrailNodesData.length + 1),
  //   //   //source: (availableNode.id),
  //   //   source: "3",
  //   //   target: (guardrailNodesData.length + 1).toString(),
  //   //   type: "customEdge"
  //   // }
  //   // let addedArr = guardrailNodesData.filter((val, idx) => idx >= 3);
  //   // addedArr.push(myNodeObj);
  //   // console.log("added arr: ", addedArr);
  //   // for(let i=0;i<addedArr.length;i++){
  //   //   console.log("My guardrail idx: ", guardrailNodesData[i + 3], guardrailNodesData[addedArr.length + 1 - i].position.x);
  //   //   guardrailNodesData[i + 3] = {
  //   //     id: (i + 3 + 1).toString(),
  //   //     type: "if",
  //   //     data: {
  //   //       label: <ActionBox />
  //   //     },
  //   //     position: { x: (-guardrailNodesData[addedArr.length + 1 - i].position.x), y: 600 },
  //   //     style: nodeStyle,
  //   //     // sourcePosition: "left",
  //   //   }
  //   // }
  //   // console.log("New node and edge: ", myNodeObj, myEdgeObj);
  //   // setGuardrailNodesData([...guardrailNodesData, myNodeObj]);
  //   // setGuardrailEdgesData([...guardrailEdgesData, myEdgeObj]);

  // };

  // const updateCondition = async () => {
  //   setSelectedGuardrailInfo(myData);
  //   guardrailNodesData[conditionNo + 2] = {
  //     id: (conditionNo + 3).toString(),
  //     type: "if",
  //     data: {
  //       label: <ActionBox conditionField={myData.info.selectedFilters[conditionNo - 1].param} conditionOperation={myData.info.selectedFilters[conditionNo - 1].filter} conditionValue={myData.info.selectedFilters[conditionNo - 1].value} conditionNo={conditionNo.toString()} />
  //     },
  //     position: { x: 50, y: 600 },
  //     style: nodeStyle,
  //   };
  //   setGuardrailNodesData([...guardrailNodesData]);
  // }

  const handleSaveFrequency = async () => {
    let operationContent1 = <p>Repeat Every</p>
    let operationContent2 = <p>Run Time</p>
    let operationValue1 = /*new Date(selectedGuardrailInfo.info.scheduledDate).toLocaleString("en-US", { weekday: 'short', month: 'short', day: 'numeric', year: 'numeric' })*/freqs[myData.info.repeatFrequency - 1] === "Does not repeat" ? "Does not repeat" : myData.info.repeatOccurrence + " " + freqs[myData.info.repeatFrequency - 1].substring(0, freqs[myData.info.repeatFrequency - 1].length - 2) + (myData.info.repeatOccurrence > 1 ? 's' : '')
    let operationValue2 = myData.info.scheduledTime
    console.log("Operations: ", operationValue1, operationValue2);
    console.log("Guardrail data: ", guardrailNodesData);
    guardrailNodesData[1] = {
      id: "2",
      type: "when",
      data: {
        label: <OperationBox operationContent1={operationContent1} operationContent2={operationContent2} operationValue1={operationValue1} operationValue2={operationValue2} />
      },
      position: { x: 50, y: 243 },
      style: nodeStyle,      // targetPosition: "right",
    };
    console.log("Guardrail nodes: ", guardrailNodesData);
    setGuardrailNodesData([...guardrailNodesData]);
  }

  const submitDetails = async () => {
    let myError = "";
    try {
      await form.validateFields();
    }
    catch (error) {
      myError = error;
    }
    console.log("Repeat on: ", myData, myData.info?.repeatOn?.length, freqs[myData.info?.repeatFrequency - 1]);
    if (freqs[myData.info?.repeatFrequency - 1] === "Weekly") {
      if (myData.info?.repeatOn?.length === 0) {
        myError = "Please select a day from the above list."
        setError({
          status: true,
          msg: myError
        });
      }
    }
    if (myError === "") {
      setError({
        status: false,
        msg: myError
      });
      if (data.heading === "Configure Action") {
        console.log("MyData: ", myData);
        if (myData.info.name !== selectedGuardrailInfo.info.name) {
          setSelectedGuardrailInfo({ ...myData, actionChanged: true });
          localStorage.setItem('selectedGuardrailInfo', JSON.stringify({ ...myData, actionChanged: true }));
        }
        else {
          setSelectedGuardrailInfo({ ...myData, actionChanged: false });
          localStorage.setItem('selectedGuardrailInfo', JSON.stringify({ ...myData, actionChanged: false }));
        }
      }
      else {
        console.log("MyData...: ", myData);
        setSelectedGuardrailInfo({ ...myData, actionChanged: false });
        localStorage.setItem('selectedGuardrailInfo', JSON.stringify({ ...myData, actionChanged: false }));
      }
      if (data.heading === "Configure Action") {
        handleSaveSituation();
      }
      else if (data.heading === "Configure Condition") {
        updateCondition(myData, conditionNo);
      }
      else if (data.heading === "Add Condition") {
        handleAddNode(myData, guardrailNodesData, guardrailEdgesData, conditionNo);
        setGuardrailNodesData([...guardrailNodesData]);
        setGuardrailEdgesData([...guardrailEdgesData]);
      }
      else if (data.heading === "Configure Frequency") {
        handleSaveFrequency();
      }
      //setMyData({});
      // setOpenRightDrawer({
      //   drawerType: false,
      //   drawerProps: {
      //     data: null
      //   },
      // });
      setOpenGuardrailInfo(false);
      setModalLoaded(false);
    }
  }

  const handleCancel = () => {
    //setMyData({});
    // setOpenRightDrawer({
    //   drawerType: false,
    //   drawerProps: {
    //     data: null
    //   },
    // });
    console.log("Executes.........");
    setOpenGuardrailInfo(false);
    setModalLoaded(false);
  }

  const handleSelectChange = (value, event) => {
    if (data.heading === "Configure Action") {
      setMyData({
        ...myData,
        info: {
          ...myData.info,
          id: parseInt(event.key),
          name: value
        }
      });
    }
    else if (data.heading === "Configure Frequency") {
      console.log("Config freq: ", event);
      if (event.name === 'repeatFrequency') {
        console.log("My data set: ", myData);
        setMyData({
          ...myData,
          info: {
            ...myData.info,
            [event.name]: value,
            scheduledDate: "",
            scheduledTime: "",
            repeatOn: [],
            repeatOccurrence: "1"
          }
        });
        days = days.map(val => ({
          ...val,
          status: false
        }));
        setDays(days);
      }
      else {
        setMyData({
          ...myData,
          info: {
            ...myData.info,
            [event.name]: value
          }
        });
      }
    }
    else {
      console.log("Guardrail: ", myData, event.name, value);
      let filters = myData.info.selectedFilters;
      if (event.name === "param") {
        let ops = [];
        myData.info.filterOptions[value].forEach((val) => {
          ops.push(<Option key={val} name="filter" value={val}>{val}</Option>);
        });
        setOperationsList(ops);
      }
      filters[conditionNo - 1] = {

        ...filters[conditionNo - 1],
        [event.name]: value,
        "value": event.name === "param" ? "" : event.name === "value" ? value : filters[conditionNo - 1].value
      };
      console.log("Filters: ", filters);
      setMyData({
        ...myData,
        info: {
          ...myData.info,
          selectedFilters: filters
        }
      });
    }
  }

  const handleChange = (event) => {
    console.log("Guardrail: ", myData, selectedGuardrailInfo);
    if (data.heading === "Configure Condition" || data.heading === "Add Condition") {
      let filters = myData.info.selectedFilters;
      filters[conditionNo - 1] = {
        ...filters[conditionNo - 1],
        [event.target.name]: event.target.value
      };
      setMyData({
        ...myData,
        info: {
          ...myData.info,
          selectedFilters: filters
        }
      })
    }
    else {
      setMyData({
        ...myData,
        info: {
          ...myData.info,
          [event.target.name]: event.target.value
        }
      });
    }
  }

  const handleDateChange = (date, dateString) => {
    console.log(date, dateString);
  };

  const onSelect = (value, mode) => {
    console.log(value.format('YYYY-MM-DD'), mode);
    setMyData({
      ...myData,
      info: {
        ...myData.info,
        scheduledDate: value
      }
    });
  };

  const validateOccurrence = (rule, value, callback) => {
    if (value === "") {
      callback();
    }
    else {
      if (isNaN(value)) {
        callback("Please enter a valid occurrence period.");
      }
      else if (parseInt(value) <= 0) {
        callback("Repeat occurrence cannot be less than 0.");
      }
      else {
        callback();
      }
    }
  }

  const disabledDate = (current) => {
    // Can not select days before today and today
    return current < dayjs().startOf('day');
  };

  const range = (start, end) => {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  };

  const disabledTime = () => ({
    // disabledHours: () => {
    //   const currentHour = dayjs().hour();
    //   const disabledHourRange = [];
    //     for (let i = 0; i < currentHour; i++) {
    //       disabledHourRange.push(i);
    //     }
    //   return disabledHourRange;
    // },
    // disabledMinutes: () => {
    //   const currentMinute = dayjs().minute();
    //   const disabledMinuteRange = [];
    //   for (let i = 0; i < currentMinute; i++) {
    //     disabledMinuteRange.push(i);
    //   }
    //   return disabledMinuteRange;
    // },
  });


  return (
    <Modal
      open={openGuardrailInfo}
      footer={null}
      closable={true}
      centered
      maskClosable={true}
      onCancel={handleCancel}
      width={720}>
      <div onClick={(e) => {
        e.stopPropagation();
      }}>
        {console.log("My dayjs date and original date: ", new Date(selectedGuardrailInfo.info.scheduledDate).getDate(), new Date().getDate())}
        <Box
          title={data.heading}
          subtitle="Please specify the guardrail credentials to proceed with your configuration."
          titleStyle={{
            flexGrow: "0",
            fontFamily: "Poppins",
            fontSize: "16px",
            fontWeight: "500",
            fontStretch: "normal",
            fontStyle: "normal",
            lineHeight: "1.25",
            letterSpacing: "normal",
            textAlign: "left",
            color: "#1f1f1f",
          }}
          subTitleStyle={{
            flexGrow: 0,
            fontFamily: "Nunito Sans",
            fontSize: "14px",
            fontWeight: "normal",
            fontStretch: "normal",
            fontStyle: "normal",
            lineHeight: "1.43",
            letterSpacing: "normal",
            textAlign: "left",
            color: "#9b9b9b",
          }}
          style={{ margin: "0", border: "0" }}
        />
        {/* <CreateTaskHeader
          // data={securityData}
          buttonContent={data.heading}
          // values={{editing: true}}
          onCancel={() => {
            setOpenRightDrawer({
              drawerType: false,
              drawerProps: {
                data: null
              },
            });
          }}
        /> */}
        <Form form={form}>
          {data.heading === "Configure Action" && <Box
            //title="Select Action*"
            subtitle="Select Action from the following*"
            titleStyle={titleStyle}
            subTitleStyle={subTitleStyle}
            style={{ border: "0" }}
          >
            <div style={{ width: "100%", marginTop: "10px" }}>
              <Select placeholder="Select Action" style={{ width: "100%" }} onChange={handleSelectChange} value={myData.info?.name}>{actionsList}</Select>
            </div>

          </Box>}

          {(data.heading === "Configure Condition" || data.heading === "Add Condition") && <div style={{}}>
            <Box
              //title="Select field*"
              subtitle="Select field name from the following*"
              titleStyle={titleStyle}
              subTitleStyle={subTitleStyle}
              style={{ border: "0", margin: "0", padding: "0 20px" }}
            >
              <div style={{ width: "100%", marginTop: "10px" }}>
                <Form.Item name="param" rules={[
                  {
                    required: true,
                    message: "Please select a field"
                  }
                ]}>
                  <Select placeholder="Select Field" style={{ width: "100%" }} onChange={handleSelectChange} value={myData.info?.selectedFilters[conditionNo - 1]?.param}>{fieldsList}</Select>
                </Form.Item>
              </div>

            </Box>
            <Box
              //title="Select operator*"
              subtitle="Select operator from the following*"
              titleStyle={titleStyle}
              subTitleStyle={subTitleStyle}
              style={{ border: "0", margin: "0", padding: "0 20px" }}
            >
              <div style={{ width: "100%", marginTop: "10px" }}>
                <Form.Item name="filter" rules={[
                  {
                    required: true,
                    message: "Please select an operator."
                  }
                ]}>
                  <Select placeholder="Select Operator" style={{ width: "100%" }} onChange={handleSelectChange} value={myData.info?.selectedFilters[conditionNo - 1]?.filter}>{operationsList}</Select>
                </Form.Item>
              </div>

            </Box>
            <Box
              //title="Enter Value*"
              subtitle={<div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>Enter Value*</p>
                {myData.info?.selectedFilters[conditionNo - 1]?.param && myData.info?.selectedFilters[conditionNo - 1]?.param !== "" && <Tooltip title={`To select all ${myData.info.module}s with the specified ${myData.info?.selectedFilters[conditionNo - 1]?.param}, simply enter an asterisk (*) in the input field.`}>
                  <InfoCircleFilled style={{ color: "#8896A1" }} />
                </Tooltip>}
              </div>}
              titleStyle={titleStyle}
              subTitleStyle={subTitleStyle}
              style={{ border: "0", margin: "0", padding: "0 20px" }}
            >
              <Form.Item name="value" rules={[
                {
                  required: true,
                  message: "Please enter a value."
                }
              ]}>
                {(myData.info?.selectedFilters[conditionNo - 1]?.param !== "cluster phase" && myData.info?.selectedFilters[conditionNo - 1]?.param !== "cluster type") && <Input placeholder="Enter Value" name="value" onChange={handleChange} value={myData.info?.selectedFilters[conditionNo - 1]?.value} style={{ marginTop: "10px" }} />}
                {(myData.info?.selectedFilters[conditionNo - 1]?.param === "cluster phase" || myData.info?.selectedFilters[conditionNo - 1]?.param === "cluster type") && <Select placeholder="Select Value" onChange={handleSelectChange} value={myData.info?.selectedFilters[conditionNo - 1]?.value} style={{ marginTop: "10px" }} >
                  {myData.info?.selectedFilters[conditionNo - 1]?.param === "cluster phase" ? phasesList : typeList}
                </Select>}
              </Form.Item>
            </Box>
          </div>}

          {data.heading === "Configure Frequency" && <div style={{}}>
            <Box
              //title="Select frequency*"
              subtitle="Select Frequency from the following*"
              titleStyle={titleStyle}
              subTitleStyle={subTitleStyle}
              style={{ border: "0", padding: "0 20px" }}
            >
              <div style={{ width: "100%", marginTop: "10px" }}>
                {/* <div style={{ border: "1px solid #d0d5dd", height: "27px", borderRadius: "5px", cursor: "pointer", display: "flex", alignItems: "center", paddingLeft: "10px" }} onClick={() => {
              setShowCal(showCal => !showCal);
            }}>{new Date(myData.info?.scheduledDate).toLocaleString("en-US", { weekday: 'short', month: 'short', day: 'numeric', year: 'numeric' }) + ' every ' + days[myData.info?.repeatOn]}</div> */}
                <Form.Item name="repeatFrequency"
                  rules={[
                    {
                      required: true,
                      message: 'Please select a frequency from the following.',
                    },
                  ]}>
                  <Select style={{ width: "100%" }} value={myData.info?.repeatFrequency} onChange={handleSelectChange}>{frequencies}</Select>
                </Form.Item>
                {/* <Box style={{
              width: "100%",
              height: "547px",
              margin: "10px 0",
              padding: "17px 20px 25px 20px",
              borderRadius: "10px",
              boxShadow: "0 60px 120px 0 rgba(38, 51, 77, 0.05)",
              border: "solid 1px #dcdcdc",
              backgroundColor: "#fff"
            }}> */}
                {freqs[myData.info.repeatFrequency - 1] !== "Weekly" && <Box
                  //title="Select Date*"
                  subtitle="Select Date from the following*"
                  titleStyle={titleStyle}
                  subTitleStyle={subTitleStyle}
                  style={{ border: "0", padding: "0", margin: "0" }}
                >
                  <Form.Item name="scheduledDate" rules={[
                    {
                      required: freqs[myData.info.repeatFrequency - 1] !== "Weekly" ? true : false,
                      message: 'Please select a date.',
                    },
                  ]}>
                    <DatePicker
                      fullscreen={false}
                      onSelect={onSelect}
                      disabledDate={disabledDate}
                      value={myData.info?.scheduledDate === "" ? null : dayjs(myData.info?.scheduledDate)}
                      style={{ width: "100%", marginTop: "10px" }} />
                  </Form.Item>
                </Box>}
                <Box
                  //title="Select Time*"
                  subtitle="Select time from the following*"
                  titleStyle={titleStyle}
                  subTitleStyle={subTitleStyle}
                  style={{ border: "0", padding: "0", backgroundColor: "transparent" }}
                >
                  <div style={{ display: "flex" }}>
                    <Form.Item name="scheduledTime" rules={[
                      {
                        required: true,
                        message: 'Please select time.',
                      },
                    ]}>
                      <TimePicker style={{ width: "100%", marginTop: "10px" }} format={"HH:mm"} disabledTime={disabledTime} value={myData.info?.scheduledTime === "" ? null : dayjs(myData.info?.scheduledTime, 'HH:mm')} onChange={(time, timeString) => {
                        setMyData({
                          ...myData,
                          info: {
                            ...myData.info,
                            scheduledTime: timeString
                          }
                        });
                      }} />
                    </Form.Item>
                    <div style={{ border: "1px solid #d0d5dd", height: "33px", borderRadius: "5px", display: "flex", alignItems: "center", justifyContent: "center", padding: "10px", transform: "translate(10px, 10px)" }}>
                      {tzName}
                    </div>
                  </div>
                </Box>
                {/* </Box> */}
              </div>
              {(freqs[myData.info.repeatFrequency - 1] === "Weekly") && <div style={{ width: "90%", marginTop: "20px" }}>
                <Box
                  //title="Repeat On*"
                  subtitle="Repeat On*"
                  titleStyle={titleStyle}
                  subTitleStyle={subTitleStyle}
                  style={{ border: "0", padding: "0 0 10px 0" }}
                >
                  <div style={{ display: "flex", marginTop: "10px" }}>
                    {days.map((val, idx) => {
                      return (
                        <p style={{
                          height: "36px",
                          flexGrow: "1",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "8px",
                          padding: "8px",
                          borderRadius: "25px",
                          backgroundColor: val.status ? "#e3f0fd" : "#ffffff",
                          cursor: "pointer",
                          marginRight: "10px"
                        }} onClick={() => {
                          val.status = !val.status;
                          if (val.status) {
                            setMyData({
                              ...myData,
                              info: {
                                ...myData.info,
                                repeatOn: [...myData.info.repeatOn, idx]
                              }
                            });
                          }
                          else {
                            myData.info.repeatOn = myData.info.repeatOn.filter(x => x !== idx);
                            console.log("Updated val: ", myData.info)
                            setMyData({ ...myData });
                          }
                          setDays([...days]);
                        }}> {val.name.substring(0, 2)} </p>
                      );
                    })}
                  </div>
                  {console.error("My error: ", error)}
                  {error.status && <p className="isoHelperText">{error.msg}</p>}
                </Box>
              </div>}
              {freqs[myData.info.repeatFrequency - 1] !== "Does not repeat" && <div style={{ marginBottom: "100px" }}>
                <Box
                  //title="Repeat Every"
                  subtitle="Repeat Every*"
                  titleStyle={titleStyle}
                  subTitleStyle={subTitleStyle}
                  style={{ border: "0", padding: "0 0 10px 0", marginRight: "10px" }}
                >
                  <div style={{ display: "flex", marginTop: "10px" }}>
                    <Form.Item name="repeatOccurrence" rules={[
                      {
                        required: true,
                        message: 'Please select repeat occurrence period.',
                      },
                      {
                        validator: validateOccurrence
                      }
                    ]} style={{ width: "300px", marginRight: "10px" }}>
                      <Input style={{ width: "72px" }} name="repeatOccurrence" value={myData.info?.repeatOccurrence} onChange={handleChange} />
                    </Form.Item>
                    <p style={{
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      fontWeight: "500",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "1.5",
                      letterSpacing: "normal",
                      textAlign: "center",
                      color: " #494e50",
                      margin: "5px 10px 0 0",
                      transform: "translateX(-230px)"
                    }}>:</p>
                    <div style={{ border: "1px solid #d0d5dd", height: "33px", borderRadius: "5px", display: "flex", alignItems: "center", justifyContent: "center", padding: "10px", transform: "translateX(-230px)" }}>
                      {freqs[myData.info.repeatFrequency - 1].substring(0, freqs[myData.info.repeatFrequency - 1].length - 2)}{myData.info?.repeatOccurrence > 1 ? 's' : ''}
                    </div>
                  </div>
                </Box>
              </div>}
            </Box>
          </div>}
        </Form>
        <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "40px", marginRight: "20px" }}>
          <Button type="primary" onClick={handleCancel} style={{ backgroundColor: "white", color: "#132f44", fontSize: "16px", fontFamily: "Nunito Sans", fontWeight: "600", border: "1px solid #132f44", marginRight: "10px" }}>
            Cancel
          </Button>
          <Button type="primary" onClick={submitDetails} style={{ fontSize: "16px", fontFamily: "Nunito Sans", fontWeight: "600", backgroundColor: "#132f44" }}>
            Save
          </Button>
        </div>
        {/* <CreateTaskFooter buttonContent={data.heading} givenButtonFunction1={submitDetails} givenButtonFunction2={handleCancel} /> */}
      </div >
    </Modal >
  );
}