import React from 'react';
import * as Sentry from "@sentry/react";
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    console.log('error :', error);
    Sentry.captureException(error);
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return <p>Loading failed! Please reload.</p>;
    }

    return this.props.children;
  }
}

export default Sentry.withErrorBoundary(ErrorBoundary);
