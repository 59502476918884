import React from 'react';
// import { connect } from 'react-redux';
import { Drawer } from 'antd';
import CreateTaskForm from './Task/TaskCreateOrUpdate/TaskCreateOrUpdate';
import TaskDetails from './Task/TaskDetails/TaskDetials';
import drawerActions from '@iso/redux/drawer/actions';
import { createGlobalStyle } from 'styled-components';
import { useContext } from 'react';
import { AppContext } from '../../context/ContextProvider';
import ClusterProd from '../Clusters/Task/ClusterProd';
import ClusterDev from '../Clusters/Task/ClusterDev';
import ClusterNonDev from '../Clusters/Task/ClusterNonDev';
import CodeView from '../Clusters/Task/CodeView';
import SecurityDrawerData from '../Dashboard/SecurityVulnerabilities/SecurityDrawerData';
import AddEnvironmentVariable from '../Projects/AddEnvironmentVariable';
import BasicDetails from '../Deployments/ApplicationBasicDetails';
import GcpIntgration from '../Pages/CloudProviders/GcpIntgration';
import DockerHubIntgration from '../Pages/CloudProviders/DockerHubIntgration';
import EcrIntgration from '../Pages/CloudProviders/EcrIntgration';
import CloudFlareIntgration from '../Pages/CloudProviders/CloudFlareIntegration';
import SecurityDrawerTypeData from '../Dashboard/SecurityVulnerabilities/SecurityTypeDrawerData';
import ImportEnvironmentVariable from '../Projects/ImportEnvironmentVariable';
import ClusterMarketplaceDrawer from '../Clusters/Task/ClusterMarketplaceDrawer';
import PglStackDrawer from '../Clusters/Task/PglStackDrawer';
import AddonsOverview from '../Clusters/Task/AddonsOverview';
import K8sDashboardDrawer from '../Clusters/Task/K8sDashboardDrawer';
import PrebuildToolsRightDrawer from '../Projects/WorkflowPages/PrebuildToolsRightDrawer';
import CustomScriptRightDrawer from '../Projects/WorkflowPages/CustomScriptRightDrawer';
import SourceToolsRightDrawer from '../Projects/WorkflowPages/SourceToolsRightDrawer';
import postBuildToolsRightDrawer from '../Projects/WorkflowPages/PostBuildToolsRightDrawer';
import BuildToolsRightDrawer from '../Projects/WorkflowPages/BuildToolsRightDrawer';
import ToolsEditRightDrawer from '../Projects/WorkflowPages/ToolsEditRightDrawer';
import ArgoFlowDrawer from '../Clusters/Task/ArgoFlowDrawer';
import OnSourceClickRightDrawer from '../Projects/WorkflowPages/OnSourceClickRightDrawer';
import OnBuildClickRightDrawer from '../Projects/WorkflowPages/OnBuildClickRightDrawer';
import OnDeployClickRightDrawer from '../Projects/WorkflowPages/OnDeployClickRightDrawer';
import GuardrailInfoDrawer from '../Guardrails/GuardrailModals/GuardrailInfoDrawer';
import OneClickClusterDrawer from '../Clusters/Task/OneClickClusterDrawer';
import GitlabIntegration from '../Pages/CloudProviders/GitlabIntegration';
import PostDeployToolsRightDrawer from '../Projects/WorkflowPages/PostDeployToolsRightDrawer';
import DeployToolsRightDrawer from '../Projects/WorkflowPages/DeployToolsRightDrawer';
import PostBuildCustomScriptRightDrawer from '../Projects/WorkflowPages/PostBuildCustomScriptRightDrawer';
import WorkflowTemplatesRightDrawer from '../Projects/WorkflowPages/WorkflowTemplatesRightDrawer';
import BringYourClusterDrawer from '../Clusters/Task/BringYourClusterDrawer';

const DrawerStyles = createGlobalStyle`
  .ant-drawer .ant-drawer-body{
    padding: 0;
  }
  .ant-drawer-content-wrapper{
    width: 100% !important;
    @media (min-width: 768px){
      width: 500px!important;
    }
    @media (min-width: 1200px){
      width: 700px!important;
    }
  }
`;

const DrawerStylesExpended = createGlobalStyle`
  .ant-drawer .ant-drawer-body{
    padding: 0;
  }
  .ant-drawer-content-wrapper{
    width: 100% !important;
    @media (min-width: 768px){
      width: 700px!important;
    }
    @media (min-width: 1200px){
      width: 900px!important;
    }
  }
`;

const DRAWER_COMPONENTS = {
  CREATE_OR_EDIT_TASK: CreateTaskForm,
  CARD_DETAILS: TaskDetails,
  MORE_INFO_TASK: TaskDetails,
  prod: ClusterProd,
  existingVpc: ClusterProd,
  dev: ClusterDev,
  'non-prod': ClusterNonDev,
  'prodCodeView': CodeView,
  'security': SecurityDrawerData,
  'securityScan': SecurityDrawerTypeData,
  'addEnvironmentVariable': AddEnvironmentVariable,
  'importEnvironmentVariable': ImportEnvironmentVariable,
  'gcpintegration': GcpIntgration,
  'gitlab-integration': GitlabIntegration,
  'dockerhubintegration': DockerHubIntgration,
  'ecrintegration': EcrIntgration,
  'cloudflareintegration': CloudFlareIntgration,
  'clusterMarketplaceDrawer': ClusterMarketplaceDrawer,
  'pgl_stack': PglStackDrawer,
  'addons_overview': AddonsOverview,
  'guardrailNodeInfo': GuardrailInfoDrawer,
  'k8s_dashboard': K8sDashboardDrawer,
  'argo_flow': ArgoFlowDrawer,
  'sourceClickRightDrawer': OnSourceClickRightDrawer,
  'buildClickRightDrawer': OnBuildClickRightDrawer,
  'deployClickRightDrawer': OnDeployClickRightDrawer,
  'prebuildRightDrawer': PrebuildToolsRightDrawer,
  'postDeployRightDrawer': PostDeployToolsRightDrawer,
  'customScript': CustomScriptRightDrawer,
  'post-build-customScript': PostBuildCustomScriptRightDrawer,
  'sourceTools': SourceToolsRightDrawer,
  'postBuildRightDrawer': postBuildToolsRightDrawer,
  'buildTools': BuildToolsRightDrawer,
  'deployTools': DeployToolsRightDrawer,
  'toolsEdit': ToolsEditRightDrawer,
  'workflowTemplates': WorkflowTemplatesRightDrawer,
  'oneClickCluster': OneClickClusterDrawer,
  'bringYourCluster':BringYourClusterDrawer,
  /* other modals */
};

const DrawerRoot = ({
  // drawerType,
  // drawerProps,
  // drawerVisibility,
  // closeDrawer,
  // openDrawer,
}) => {
  
  const {openRightDrawer, setOpenRightDrawer} = useContext(AppContext);
  console.log('openRightDrawer in root drawer:', openRightDrawer);
  const SpecificDrawer = DRAWER_COMPONENTS[openRightDrawer.drawerType];
  
  if (!openRightDrawer.drawerType) {
    return null;
  }

  return (
    <Drawer
      placement="right"
      closable={false}
      onClose={() => {
        setOpenRightDrawer({
            drawerType: false,
            drawerProps: {
              data: null,
              columnId: null,
            },
          });
      }}
      open={openRightDrawer.drawerType}
      // width={700}
      destroyOnClose={true}
      className={openRightDrawer?.drawerProps?.className ? openRightDrawer.drawerProps.className : ""}
    >
      {openRightDrawer?.drawerProps?.className === "expend" ? <DrawerStylesExpended /> : <DrawerStyles />}

      <SpecificDrawer
        {...openRightDrawer.drawerProps}
        openDrawer={() => {
          setOpenRightDrawer({
            drawerType: false,
            drawerProps: {
              data: null,
              columnId: null,
            },
          });
        }}
        closeDrawer={() => {
          setOpenRightDrawer({
            drawerType: false,
            drawerProps: {
              data: null,
              columnId: null,
            },
          });
        }}
      />
    </Drawer>
  );
};

export default DrawerRoot

// export default connect(
//   state => state.drawer,
//   { ...drawerActions }
// )(DrawerRoot);
