import { React, useState, useEffect } from 'react';
import IntlMessages from '@iso/components/utility/intlMessages';
import Checkbox, { CheckboxGroup } from '@iso/components/uielements/checkbox';
import Box from '@iso/components/utility/box';
import ContentHolder from '@iso/components/utility/contentHolder';
import Button from '@iso/components/uielements/button';
import { Input, Switch, Spin, Tooltip, Alert } from 'antd';
import CreateTaskHeader from '../../ScrumBoard/Task/TaskCreateOrUpdate/CreateTaskHeader/CreateTaskHeader';
import { useContext } from 'react';
import { AppContext } from '../../../context/ContextProvider';
import styled from 'styled-components';
import Select from '../../Deployments/Deployment.styles';
import {
  SelectOption as Option,
} from '@iso/components/uielements/select';

import initialData from '../../../redux/scrumBoard/data';
import { useNavigate } from 'react-router-dom';
import upIcon from '../../../assets/images/upArrow.svg';
import downIcon from '../../../assets/images/downArrow.svg';

import {
  PlusOutlined
} from '@ant-design/icons';
import plusIcon from '../../../assets/images/plusIcon.svg';
import { Link } from 'react-router-dom';
import CreateTaskFooter from '../../ScrumBoard/Task/TaskCreateOrUpdate/CreateTaskFooter/CreateTaskFooter';
import deleteIcon from '../../../assets/images/tagsDelete.svg';
import api from '../../../api';
import { InfoCircleFilled } from '@ant-design/icons';
import { ConvertUTCToLocalDate, customFilterOption, customSortOptions } from '../../../context/helper';
import CustomInfoTooltip from '../../Dashboard/CustomComponents/CustomInfoTooltip';
import { clusterHelpChecks } from '../clusterHelpChecks';


const ProdContentHold = styled.div`
  .isoBoxWrapper {
    height: auto;
    border: none;
    margin: 0;
  }
`;

let subContentStyle = {
  fontFamily: "Nunito Sans",
  fontSize: "13px",
  fontWeight: "300",
  // text-align: left,
  color: "#afafaf",
}

const addButtonStyle = {
  marginRight: "10px",
  fontSize: "25px"
};


const serverInsTypes = ["t3.small", "m4.large", "c5.large", "r3.large"];
serverInsTypes.map((child, ind) => {
  serverInsTypes.push(<Option key={child} name="serverInsType" value={child}>{child}</Option>);
});

var optionAzs = [];

let innerTitleStyle = {
  color: "#1f1f1f",
  fontFamily: "Poppins",
  fontType: "medium",
  fontWeight: "500",
  fontSize: "14px"
};

let innerSubtitleStyle = {
  fontSize: "12px",
  color: "#9b9b9b",
  fontFamily: "Nunito Sans",
  fontWeight: "400",
  fontType: "regular",
}

const inputStyle = {
  height: "44px"
}


export default function ClusterNonDev({
  // handleSubmit,
  data,
  setData,
  values,
  submitCount,
  onCancel,
  onDelete,
  onEditCancel,
  tabSelected,
  openAddOns,
  openDrawer
}) {
  const { openRightDrawer, setOpenRightDrawer, allClusters, setAllClusters, currentCluster, setCurrentCluster, clusterTabSelected, fetchingClusterPrerequisites, updateCluster, setUpdateCluster, isUpdate, isOneClick, oneClickCluster, setOneClickCluster } = useContext(AppContext);

  const navigate = useNavigate();

  const [myClusterData, setMyClusterData] = useState(data);
  const [error, setError] = useState({
    status: false,
    msg: "Success"
  });

  const [myVersions, setMyVersions] = useState([]);
  const [isAdvanced, setIsAdvanced] = useState(true);
  const [clusterLogRetentionOptions, setClusterLogRetentionOptions] = useState([]);
  const [flowLogMaxOptions, setflowLogMaxOptions] = useState([]);
  const [vpcLogRetentionOptions, setVpcLogRetentionOptions] = useState([]);
  const [addSecondaryRange, setAddSecondaryRange] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  // const [myClusterData, setMyClusterData] = useState(data);
  // useEffect(() => {
  //   setMyClusterData(isUpdate ? updateCluster : isOneClick ? oneClickCluster : currentCluster);
  // }, [currentCluster, updateCluster, oneClickCluster]);

  useEffect(() => {
    let arr = [];
    let clusterLog = [];
    let flowLogMaxList = [];
    let vpcLog = [];
    if (isUpdate) {
      let childVerInd = -2; //Setting it to -2 such that even if we add 1, it is not equal to the index of the element
      updateCluster["non-prod"].versionsAvailable.map((child, ind) => {
        if (child.version === updateCluster.selectedCluster.cluster_data.kubernetes_version) {
          childVerInd = ind;
          arr.push(<Option key={child.version} name="eks" value={child.version}>
            <div style={{ width: "98%" }}>
              <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                <span>{child.version}</span>
                <span>current</span>
              </div>
              <p style={subContentStyle}>{`End of Extended Support : `}<b> {child.end_of_extended_support_time ? ConvertUTCToLocalDate(child.end_of_extended_support_time) : "N/A"}</b></p>
              <p style={subContentStyle}>{`End of Standard Support : `}<b> {child.end_of_standard_support_time ? ConvertUTCToLocalDate(child.end_of_standard_support_time) : "N/A"}</b></p>
            </div>
          </Option>);
        }
        else {
          arr.push(<Option key={child.version} disabled={(childVerInd + 1) !== ind} name="eks" value={child.version}>
            <div>
              <p style={{}}>{child.version}</p>
              <p style={subContentStyle}>{`End of Extended Support : `}<b> {child.end_of_extended_support_time ? ConvertUTCToLocalDate(child.end_of_extended_support_time) : "N/A"}</b></p>
              <p style={subContentStyle}>{`End of Standard Support : `}<b> {child.end_of_standard_support_time ? ConvertUTCToLocalDate(child.end_of_standard_support_time) : "N/A"}</b></p>
            </div>
          </Option>);
        }
      });
    }
    else {
      let selectedCluster = isOneClick ? oneClickCluster : currentCluster;
      selectedCluster["non-prod"].versionsAvailable.map((child, ind) => {
        arr.push(<Option key={child.version} name="eks" value={child.version}>
          <div>
            <p style={{}}>{child.version}</p>
            <p style={subContentStyle}>{`End of Extended Support : `}<b> {child.end_of_extended_support_time ? ConvertUTCToLocalDate(child.end_of_extended_support_time) : "N/A"}</b></p>
            <p style={subContentStyle}>{`End of Standard Support : `}<b> {child.end_of_standard_support_time ? ConvertUTCToLocalDate(child.end_of_standard_support_time) : "N/A"}</b></p>
          </div>
        </Option>);
      });
    }
    console.log("Initial data retention:", initialData);
    initialData.retentionPeriodInDays.map((child, ind) => {
      clusterLog.push(<Option key={child} name="clusterLogRetention" value={child}>{child}</Option>);
    });

    initialData.retentionPeriodInDays.map((child, ind) => {
      vpcLog.push(<Option key={child} name="logRetention" value={child}>{child}</Option>);
    });

    initialData.flowLogMaxInSec.map((child, ind) => {
      flowLogMaxList.push(<Option key={child} name="flowLogMax" value={child}>{child}</Option>);
    });

    setMyVersions(arr);
    console.log("Cluster log options: ", clusterLog);
    setClusterLogRetentionOptions(clusterLog);
    setVpcLogRetentionOptions(vpcLog);
    setflowLogMaxOptions(flowLogMaxList);
    setMyClusterData(isUpdate ? updateCluster : isOneClick ? oneClickCluster : currentCluster);
  }, []);

  useEffect(() => {
    if ((isUpdate && updateCluster["non-prod"].versionsAvailable.length > 0 && updateCluster["non-prod"].eks !== "") || (isOneClick && oneClickCluster["non-prod"].versionsAvailable.length > 0 && updateCluster["non-prod"].eks !== "")) {
      let result = isUpdate ? updateCluster["non-prod"].versionsAvailable.find((val) => {
        console.log("Value: ", updateCluster["non-prod"].eks);
        if (val.version === updateCluster["non-prod"].eks) {
          return val;
        }
      }) : oneClickCluster["non-prod"].versionsAvailable.find((val) => {
        if (val.version === oneClickCluster["non-prod"].eks) {
          return val;
        }
      });
      console.log("Fetching addons:...");
      fetchAddons(result);
    }
  }, [updateCluster["non-prod"].versionsAvailable, oneClickCluster["non-prod"].versionsAvailable]);


  let azs = isUpdate ? (updateCluster.availability_zones ? updateCluster.availability_zones : []) : isOneClick ? (oneClickCluster.availability_zones ? oneClickCluster.availability_zones : []) : (currentCluster.availability_zones ? currentCluster.availability_zones : []);
  optionAzs = [];
  azs.map((child, ind) => {
    optionAzs.push(<Option key={child + "-" + ind} name="naz" value={child} disabled={(isUpdate && updateCluster.selectedCluster.cluster_data.availability_zones.includes(child))}>{child}</Option>);
  });

  const handleChange = value => {
    // console.log('allClusters: ', allClusters);
    myClusterData['non-prod'][value.target.name] = value.target.checked;
    setData({ ...myClusterData, 'non-prod': myClusterData['non-prod'] });
    setMyClusterData({ ...myClusterData, 'non-prod': myClusterData['non-prod'] });
  };

  const handleSelectChange = (value, event) => {
    // console.log('updated myClusterData :', myClusterData);
    let result;
    if (isUpdate) {
      result = updateCluster["non-prod"].versionsAvailable.find((val) => {
        console.log("Value: ", val);
        if (val.version === value) {
          return val;
        }
      });
    }
    else if (isOneClick) {
      result = oneClickCluster["non-prod"].versionsAvailable.find((val) => {
        console.log("Value: ", val);
        if (val.version === value) {
          return val;
        }
      });
    }
    else {
      result = currentCluster["non-prod"].versionsAvailable.find((val) => {
        console.log("Value: ", val);
        if (val.version === value) {
          return val;
        }
      });
    }
    fetchAddons(result);
    myClusterData['non-prod'][event.name] = value;
    //myClusterData['non-prod'].selectedAddons = []; 
    setData({ ...myClusterData, 'non-prod': myClusterData['non-prod'] });
    setMyClusterData({ ...myClusterData, 'non-prod': myClusterData['non-prod'] });
    setError({
      status: false,
      msg: ""
    });
    if (isUpdate) {
      setUpdateCluster({ ...myClusterData, 'non-prod': myClusterData['non-prod'] });
    }
    else if (isOneClick) {
      setOneClickCluster({ ...myClusterData, 'non-prod': myClusterData['non-prod'] });
    }
    else {
      setCurrentCluster({ ...myClusterData, 'non-prod': myClusterData['non-prod'] });
    }
  };

  const fetchAddons = (result) => {
    console.log("Result: ", result);
    api.get(process.env.REACT_APP_API_URI + `/api/cluster/cluster-addons/${isUpdate ? updateCluster.integration : isOneClick ? oneClickCluster.integration : currentCluster.integration}/${result.id}`).then(async (res) => {
      let data = await res.data;
      myClusterData["non-prod"]["addonsData"] = data;
      if (myClusterData["non-prod"]["selectedAddons"].length > 0 && isUpdate) {
        myClusterData["non-prod"]["addonsData"].forEach((val) => {
          let ele = myClusterData["non-prod"]["selectedAddons"].data.find(x => x.id === val.addon_id);
          if (!ele) {
            myClusterData["non-prod"]["selectedAddons"].push({
              id: val.addon_id,
              name: val.display_name,
              dependsOn: val.depends_on,
              selected: false,
              disabled: false,
              recommended: val.recommended,
              editable: true,
              subText: val.info,
              borderColor: "#f5f5f5",
              message: ""
            });
          }
        });
      }
      setMyClusterData({ ...myClusterData, "non-prod": myClusterData["non-prod"] });
      if (isUpdate) {
        setUpdateCluster({ ...myClusterData, 'non-prod': myClusterData['non-prod'] });
      }
      else if (isOneClick) {
        setOneClickCluster({ ...myClusterData, 'non-prod': myClusterData['non-prod'] });
      }
      else {
        setCurrentCluster({ ...myClusterData, 'non-prod': myClusterData['non-prod'] });
      }
      setData({ ...myClusterData, "non-prod": myClusterData["non-prod"] });
      console.log("This is the addons data: ", data);
    });
  }

  const handleMultiSelectChange = (value, event) => {
    console.log('event :', event);
    // if (value.length <= 1){
    //   setError({
    //     status: true,
    //     msg: "Please provide 2 or more AZ's"
    //   });
    //   // event.value;
    //   return false;
    // }
    console.log('value :', value);
    // console.log('myClusterData["non-prod"].naz :', myClusterData['non-prod'].naz);
    if (value.length > myClusterData.prerequisiteInfo.available_eip_quota) {
      setError({
        status: true,
        msg: `You can select upto ${myClusterData.prerequisiteInfo.available_eip_quota} availability zones.`
      });
    }
    else {
      console.log('ok');
      setError({
        status: false,
        msg: ""
      })
    }
    console.log("My cluster data in AZ...: ", JSON.parse(JSON.stringify(myClusterData)), JSON.parse(JSON.stringify(currentCluster)));
    myClusterData['non-prod'].naz = value;
    if (isUpdate) {
      setData({ ...updateCluster, 'non-prod': myClusterData['non-prod'] });
      setMyClusterData({ ...updateCluster, 'non-prod': myClusterData['non-prod'] });
      //setUpdateCluster({ ...updateCluster, 'non-prod': myClusterData['non-prod'] });
      // localStorage.setItem('updateCluster', JSON.stringify({ ...myClusterData, 'non-prod': myClusterData['non-prod'] }));
    }
    else if (isOneClick) {
      setData({ ...oneClickCluster, 'non-prod': myClusterData['non-prod'] });
      setMyClusterData({ ...oneClickCluster, 'non-prod': myClusterData['non-prod'] });
      //setOneClickCluster({ ...oneClickCluster, 'non-prod': myClusterData['non-prod'] });
      // localStorage.setItem('oneClickCluster', JSON.stringify({ ...myClusterData, 'non-prod': myClusterData['non-prod'] }));
    }
    else {
      setData({ ...currentCluster, 'non-prod': myClusterData['non-prod'] });
      console.log("Current cluster printing...", currentCluster);
      setMyClusterData({ ...currentCluster, 'non-prod': myClusterData['non-prod'] });
      //setCurrentCluster({ ...currentCluster, 'non-prod': myClusterData['non-prod'] });
      // localStorage.setItem('Cluster', JSON.stringify({ ...myClusterData, 'non-prod': myClusterData['non-prod'] }));
    }
  };

  const handelInputChange = (e) => {
    let val = e.target.value.replace(/\s/g, '');
    if (e.target.name === "name" || e.target.name === "value") {
      // console
      myClusterData['non-prod'].tags[e.target.id][e.target.name] = val;
    }
    else if (["logRetention", "clusterLogRetention", 'flowLogMax'].includes(e.target.name)) {
      //const logRetentionValue = e.target.value.replace(/\D/g, '');
      myClusterData['non-prod'][e.target.name] = e.target.value;
    }
    else {
      myClusterData['non-prod'][e.target.name] = val;
    }
    console.log('updated myClusterData :', myClusterData);
    setData({ ...myClusterData, 'non-prod': myClusterData['non-prod'] });
    setMyClusterData({ ...myClusterData, 'non-prod': myClusterData['non-prod'] });
  };

  const handleAdvanceSelectChange = (value, event) => {
    myClusterData["non-prod"][event.name] = value;
    console.log('updated myClusterData :', myClusterData);
    setError({
      status: false,
      msg: ""
    });
    setData({ ...myClusterData, ["non-prod"]: myClusterData["non-prod"] });
    setMyClusterData({ ...myClusterData, ["non-prod"]: myClusterData["non-prod"] });
  }

  // const handelBlurEvent = (e) => {
  //   if (e.target.name === "vpcCidr") {
  //     // console
  //     if (e.target.value.match(/\b(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\/([0-9]|[1-2][0-9]|3[0-2])\b/)) {
  //       console.log('True');
  //       setError({
  //         status: false,
  //         msg: ""
  //       });
  //     }
  //     else {
  //       console.log('False');
  //       setError({
  //         status: true,
  //         msg: "Please provide correct vpc cidr(example: 10.0.0.0/16)"
  //       });
  //     }
  //     myClusterData['non-prod'][e.target.name] = e.target.value;
  //   }
  //   // else if(e.target.name === "naz"){
  //   //   if(myClusterData['non-prod'].naz.length < 2){
  //   //     console.log('naz length error');
  //   //   }
  //   //   else{
  //   //     console.log('naz length ok');
  //   //   }
  //   // }
  // }

  const handleSubmit = () => {
    let selectedCluster = isUpdate ? updateCluster : isOneClick ? oneClickCluster : currentCluster;
      openAddOns(selectedCluster, clusterTabSelected);
  }

  const filterClusterLogOption = (input, option) => (option?.value.toLowerCase() ?? '').includes(input.toLowerCase());

  const filterVPCLogOption = (input, option) => (option?.value.toLowerCase() ?? '').includes(input.toLowerCase());


  return (
    <ProdContentHold>
      <CreateTaskHeader
        buttonContent="Cluster"
        data={myClusterData}
        // values={{editing: true}}
        onCancel={() => {
          setOpenRightDrawer({
            drawerType: false,
            drawerProps: {
              myClusterData: myClusterData,
              tabSelected: tabSelected,
            },
          });
        }}
        onDelete={onDelete}
        onEditCancel={() => {
          setOpenRightDrawer({
            drawerType: false,
            drawerProps: {
              myClusterData: myClusterData,
              tabSelected: tabSelected,
            },
          });
        }}
        openBasicView={() => {
          setOpenRightDrawer({
            drawerType: "prod",
            drawerProps: {
              data: myClusterData,
              setData: setMyClusterData,
              tabSelected: tabSelected,
            },
          });
        }}
        openCodeView={() => {
          setOpenRightDrawer({
            drawerType: "prodCodeView",
            drawerProps: {
              data: myClusterData,
              setData: setMyClusterData,
              tabSelected: tabSelected,
            },
          });
        }}
      />

      <Box
        title={myClusterData.integration === 1 ? <div style={{ display: "flex" }}><IntlMessages id="cluster.noofavailablityzones.title" />* {/*isUpdate && <Tooltip placement='bottom' title="You have the option to add an availability zone, but the existing zone selections cannot be modified."><InfoCircleFilled style={{ marginLeft: "10px" }} /></Tooltip>*/}</div> : <p> <IntlMessages id="cluster.availabilityzones.title" />*</p>}
        subtitle={"Please select 1 or more"}
        titleStyle={{ ...innerTitleStyle }}
        subTitleStyle={innerSubtitleStyle}
        style={{ marginTop: "80px" }}
      >
        {console.log("Mynew naz: ", myClusterData["non-prod"].naz)}
        <ContentHolder>
          <Select
            mode="multiple"
            name="naz"
            id="naz"
            style={{ width: '100%' }}
            placeholder={myClusterData.integration === 1 ? "Select availablity zones" : "Select zones"}
            defaultValue={myClusterData['non-prod'].naz}
            onChange={handleMultiSelectChange}
            getPopupContainer={(triggerNode) => triggerNode.parentNode}
            disabled={isUpdate}
            notFoundContent={fetchingClusterPrerequisites ? <Spin size="small" /> : null}
          >
            {
              // fetchingClusterPrerequisites ?
              //   <Option disabled>
              //     <div style={{ width: "inherit", display: "flex", justifyContent: "center", alignItems: "center" }}>
              //       <Spin size="small" />
              //     </div>
              //   </Option>
              //   :
              optionAzs
            }
          </Select>
        </ContentHolder>
        {error.status && <p className="isoHelperText">
          <span>{error.msg}</span>
        </p>}
      </Box>

      <Box
        title={<div style={{ display: "flex", justifyContent: "space-between" }}>
          <p><IntlMessages id="cluster.eksversion.title" />*</p>
          <CustomInfoTooltip title={clusterHelpChecks.cluster.kubernetesVersion} placement={"bottomLeft"} />
        </div>}
        subtitle={<IntlMessages id="cluster.eksversion.subTitle" />}
        titleStyle={innerTitleStyle}
        subTitleStyle={innerSubtitleStyle}
      >
        <ContentHolder>
          <Select
            style={{ width: "100%" }}
            id="version"
            className="customVersions"
            defaultValue={myClusterData['non-prod'].eks}
            onChange={handleSelectChange}
            getPopupContainer={(triggerNode) => triggerNode.parentNode}
          >
            {myVersions}
          </Select>
        </ContentHolder>
      </Box>

      <Box
        title={<div style={{ display: "flex", justifyContent: "space-between" }}>
          <p><IntlMessages id="cluster.vpccidr.title" />*</p>
          <CustomInfoTooltip title={clusterHelpChecks.cluster.vpcCidr} placement={"bottomLeft"} />
        </div>}
        subtitle={<IntlMessages id="cluster.vpccidr.subTitle"
        />}
        titleStyle={{ ...innerTitleStyle }}
        subTitleStyle={innerSubtitleStyle}
      >
        <ContentHolder>
          <Input
            name="vpcCidr"
            value={myClusterData['non-prod'].vpcCidr}
            onChange={handelInputChange}
            disabled={isUpdate}
            //onBlur={handelBlurEvent}
            placeholder="10.0.0.0/16"
            maxLength="19"
            style={{ ...inputStyle, width: "100%" }}
          />
          {/* {
    isUpdate && !addSecondaryRange && <div style={{ display: "flex", marginTop: "20px", cursor: "pointer", width: "fit-content" }} onClick={() => {
      setAddSecondaryRange(true);
    }}>
      <img src={plusIcon} style={{ width: "15px", height: "15px", marginRight: "10px" }} />
      <p>Add secondary range</p>
    </div>
  } */}
          {
            addSecondaryRange && <Input
              name="vpcCidr"
              value={myClusterData['non-prod'].vpcCidr}
              onChange={handelInputChange}
              //onBlur={handelBlurEvent}
              placeholder="10.0.0.0/16"
              maxLength="19"
              style={{ ...inputStyle, marginTop: "20px" }}
            />
          }
        </ContentHolder >
      </Box >

      <Box
        title={<p>{myClusterData.integration === 1 ? <IntlMessages id="cluster.tags.title" /> : <IntlMessages id="cluster.labels.title" />}</p>}
      >
        {myClusterData['non-prod'].tags.map((tag, ind) => (
          <ContentHolder key={"tag-" + ind} style={{ display: "flex", justifyContent: 'space-between', alignItems: 'center' }}>
            <Input
              name="name"
              id={ind}
              value={myClusterData['non-prod'].tags[ind] && myClusterData['non-prod'].tags[ind]['name']}
              onChange={handelInputChange}
              placeholder={myClusterData.integration === 1 ? "Tag Name" : "Name"}
              style={inputStyle}
            />
            <Input
              name="value"
              id={ind}
              value={myClusterData['non-prod'].tags[ind] && myClusterData['non-prod'].tags[ind]['value']}
              onChange={handelInputChange}
              placeholder={myClusterData.integration === 1 ? "Tag Value" : "Value"}
              style={{ ...inputStyle, margin: "0 10px" }}
            />
            {ind === 0 && <Link onClick={(e) => {
              console.log('updated myClusterData :', myClusterData);
              myClusterData["non-prod"].tags.push({
                name: "",
                value: ""
              });
              setData({ ...myClusterData, "non-prod": myClusterData["non-prod"] });
              setMyClusterData({ ...myClusterData, "non-pord": myClusterData["non-prod"] });
            }}
              style={{ marginRight: "10px" }}
            > <img src={plusIcon} /> </Link>}
            {ind !== 0 && <Link id={ind} onClick={(e) => {
              console.log('Id to be deleted :', e.currentTarget.id);
              myClusterData['non-prod'].tags.splice(e.currentTarget.id, 1)
              setData({ ...myClusterData, 'non-prod': myClusterData['non-prod'] });
              setMyClusterData({ ...myClusterData, 'non-prod': myClusterData['non-prod'] });
            }}>
              <img src={deleteIcon} style={{ marginRight: "10px" }} />
            </Link>}
          </ContentHolder>
        ))}
      </Box>

      <Box
        title={<p><IntlMessages id="cluster.vpcflowlogs.title" /></p>}
        subtitle={<IntlMessages id="cluster.vpcflowlogs.subTitle" />}
        titleStyle={{ ...innerTitleStyle }}
        subTitleStyle={innerSubtitleStyle}
          style={{ display: "flex", justifyContent: "space-between", width: "60%", /*opacity: isUpdate && "0.7", pointerEvents: isUpdate && "none"*/ }}
      >
        {/* <ContentHolder> */}
        <Switch style={myClusterData["non-prod"].vpcFlowLogs ? { backgroundColor: "#132f44" } : {}} checked={myClusterData['non-prod'].vpcFlowLogs} onChange={(checked) => {
          setData(data => ({
            ...data,
            "non-prod": {
              ...data["non-prod"],
              vpcFlowLogs: checked
            }
          }))
          setMyClusterData(myClusterData => ({
            ...myClusterData,
            "non-prod": {
              ...myClusterData["non-prod"],
              vpcFlowLogs: checked
            }
          }));
          setError({
            status: false,
            msg: ""
          });
        }} />
        {/* </ContentHolder> */}
      </Box>

      {/* <hr style={{width: "92%", borderTop: "1px solid #88898b24", marginTop: "15px", marginBottom: "15px"}} /> */}
      {
        myClusterData.integration === 1 && myClusterData["non-prod"].vpcFlowLogs && <>
          <p style={{ ...innerTitleStyle, fontWeight: "600", margin: "40px 20px 24px 20px", cursor: "pointer" }} onClick={() => {
            setIsAdvanced(isAdvanced => !isAdvanced);
          }}> Advanced Settings <img src={(isAdvanced || isUpdate) ? upIcon : downIcon} /> </p>

          {(isAdvanced || isUpdate) && <div>
            <Alert message="ATTENTION! Opting for Observability could elevate your cloud expense. Please ensure to assess the cost implications carefully." type="error" style={{ margin: "0 20px", color: "#d20a0a", fontSize: "14px", borderRadius: "10px", backgroundColor: "#f1dddd", borderColor: "transparent" }} />
            <Box
              title={<div style={{ display: "flex", justifyContent: "space-between" }}>
                <p><IntlMessages id="cluster.kubernetiveslogretentionperiod.title" />*</p>
                <CustomInfoTooltip title={clusterHelpChecks.cluster.logRetention} placement={"bottomLeft"} />
              </div>}
              subtitle={<IntlMessages id="cluster.kubernetiveslogretentionperiod.subTitle" />}
              titleStyle={innerTitleStyle}
              subTitleStyle={innerSubtitleStyle}
            >
              <ContentHolder>
                {/* <Input
                name="clusterLogRetention"
                value={myClusterData['non-prod'].clusterLogRetention}
                onChange={handelInputChange}
                placeholder="Cluster log retention period"
                maxLength="3"
                style={inputStyle}
              /> */}
                <Select
                  showSearch
                  defaultValue={myClusterData["non-prod"].clusterLogRetention}
                  onChange={handleAdvanceSelectChange}
                  style={{ width: "100%" }}
                  // filterSort={(optionA, optionB) => customSortOptions(optionA, optionB, searchValue)} // Custom sorting function
                  filterOption={(inputValue, option) => customFilterOption(inputValue, option)} // Custom filtering function
                  onSearch={(value) => {
                    console.log("Enters search", value);
                    setSearchValue(value);
                }}
                  //filterOption={filterClusterLogOption}
                  //disabled={isUpdate}
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                >
                  {clusterLogRetentionOptions}
                </Select>
              </ContentHolder>
            </Box>
            <Box
              title={<div style={{ display: "flex", justifyContent: "space-between" }}>
                <p><IntlMessages id="cluster.logretentionperiod.title" />*</p>
                <CustomInfoTooltip title={clusterHelpChecks.cluster.vpcLogRetention} placement={"bottomLeft"} />
              </div>}
              subtitle={<IntlMessages id="cluster.logretentionperiod.subTitle" />}
              titleStyle={innerTitleStyle}
              subTitleStyle={innerSubtitleStyle}
            >
              <ContentHolder>
                {/* <Input
                name="logRetention"
                value={myClusterData['non-prod'].logRetention}
                onChange={handelInputChange}
                placeholder="VPC log retention period"
                maxLength="4"
                style={inputStyle}
              /> */}
                <Select
                  showSearch
                  defaultValue={myClusterData["non-prod"].logRetention}
                  onChange={handleAdvanceSelectChange}
                  style={{ width: "100%" }}
                  //filterOption={filterVPCLogOption}
                  // filterSort={(optionA, optionB) => customSortOptions(optionA, optionB, searchValue)} // Custom sorting function
                  filterOption={(inputValue, option) => customFilterOption(inputValue, option)} // Custom filtering function
                  onSearch={(value) => {
                    console.log("Enters search", value);
                    setSearchValue(value);
                }}
                  //disabled={isUpdate}
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                >
                  {vpcLogRetentionOptions}
                </Select>
              </ContentHolder>
            </Box>
            <Box
              title={<div style={{ display: "flex", justifyContent: "space-between" }}>
                <p><IntlMessages id="cluster.flowlogmaxaggregationinterval.title" />* {/*<Tooltip title="Can be increased but not decreased"><InfoCircleFilled style={{ marginLeft: "10px" }} /></Tooltip>*/}</p>
                <CustomInfoTooltip title={clusterHelpChecks.cluster.vpcMaxAggregation} placement={"bottomLeft"} />
              </div>}
              subtitle={<IntlMessages id="cluster.flowlogmaxaggregationinterval.subTitle" />}
              titleStyle={innerTitleStyle}
              subTitleStyle={innerSubtitleStyle}
            >
              <ContentHolder>
                <Select
                  value={myClusterData["non-prod"].flowLogMax}
                  onChange={handleAdvanceSelectChange}
                  style={{ width: "100%" }}
                  disabled={isUpdate}
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                >
                  {flowLogMaxOptions}
                </Select>
              </ContentHolder>
            </Box>
            <Box
              title={<p><IntlMessages id="cluster.controlPlaneLogging.title" />*</p>}
              subtitle={<IntlMessages id="cluster.controlPlaneLogging.subTitle" />}
              titleStyle={{ ...innerTitleStyle, fontSize: "16px" }}
              subTitleStyle={{ ...innerSubtitleStyle, fontSize: "14px" }}
              style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <Box
                  title={<p><IntlMessages id="cluster.apiServer.title" /></p>}
                  subtitle={<IntlMessages id="cluster.apiServer.subTitle" />}
                  titleStyle={innerTitleStyle}
                  subTitleStyle={innerSubtitleStyle}
                  style={{ display: "flex", justifyContent: "space-between", padding: "40px 0 20px 0" }}
                />
                <Switch style={myClusterData["non-prod"].apiServer ? { backgroundColor: "#132f44", opacity: isUpdate && "0.7", pointerEvents: isUpdate && "none" } : { opacity: isUpdate && "0.7", pointerEvents: isUpdate && "none" }} checked={myClusterData["non-prod"].apiServer} onChange={(checked) => {
                  setData(data => ({
                    ...data,
                    "non-prod": {
                      ...data["non-prod"],
                      apiServer: checked
                    }
                  }))
                  setMyClusterData(myClusterData => ({
                    ...myClusterData,
                    "non-prod": {
                      ...myClusterData["non-prod"],
                      apiServer: checked
                    }
                  }));
                  setError({
                    status: false,
                    msg: ""
                  });
                }} />
              </div>

              <div style={{ display: "flex", alignItems: "center" }}>
                <Box
                  title={<p><IntlMessages id="cluster.audit.title" /></p>}
                  subtitle={<IntlMessages id="cluster.audit.subTitle" />}
                  titleStyle={innerTitleStyle}
                  subTitleStyle={innerSubtitleStyle}
                  style={{ display: "flex", justifyContent: "space-between", padding: "20px 0" }}
                />
                <Switch style={myClusterData["non-prod"].audit ? { backgroundColor: "#132f44", opacity: isUpdate && "0.7", pointerEvents: isUpdate && "none" } : { opacity: isUpdate && "0.7", pointerEvents: isUpdate && "none" }} checked={myClusterData["non-prod"].audit} onChange={(checked) => {
                  setData(data => ({
                    ...data,
                    "non-prod": {
                      ...data["non-prod"],
                      audit: checked
                    }
                  }))
                  setMyClusterData(myClusterData => ({
                    ...myClusterData,
                    "non-prod": {
                      ...myClusterData["non-prod"],
                      audit: checked
                    }
                  }));
                  setError({
                    status: false,
                    msg: ""
                  });
                }} />
              </div>

              <div style={{ display: "flex", alignItems: "center" }}>
                <Box
                  title={<p><IntlMessages id="cluster.authenticator.title" /></p>}
                  subtitle={<IntlMessages id="cluster.authenticator.subTitle" />}
                  titleStyle={innerTitleStyle}
                  subTitleStyle={innerSubtitleStyle}
                  style={{ display: "flex", justifyContent: "space-between", padding: "20px 0" }}
                />
                <Switch style={myClusterData["non-prod"].authenticator ? { backgroundColor: "#132f44", opacity: isUpdate && "0.7", pointerEvents: isUpdate && "none" } : { opacity: isUpdate && "0.7", pointerEvents: isUpdate && "none" }} checked={myClusterData["non-prod"].authenticator} onChange={(checked) => {
                  setData(data => ({
                    ...data,
                    "non-prod": {
                      ...data["non-prod"],
                      authenticator: checked
                    }
                  }))
                  setMyClusterData(myClusterData => ({
                    ...myClusterData,
                    "non-prod": {
                      ...myClusterData["non-prod"],
                      authenticator: checked
                    }
                  }));
                  setError({
                    status: false,
                    msg: ""
                  });
                }} />
              </div>

              <div style={{ display: "flex", alignItems: "center" }}>
                <Box
                  title={<p><IntlMessages id="cluster.controllerManager.title" /></p>}
                  subtitle={<IntlMessages id="cluster.controllerManager.subTitle" />}
                  titleStyle={innerTitleStyle}
                  subTitleStyle={innerSubtitleStyle}
                  style={{ display: "flex", justifyContent: "space-between", padding: "20px 0" }}
                />
                <Switch style={myClusterData["non-prod"].controllerManager ? { backgroundColor: "#132f44", opacity: isUpdate && "0.7", pointerEvents: isUpdate && "none" } : { opacity: isUpdate && "0.7", pointerEvents: isUpdate && "none" }} checked={myClusterData["non-prod"].controllerManager} onChange={(checked) => {
                  setData(data => ({
                    ...data,
                    "non-prod": {
                      ...data["non-prod"],
                      controllerManager: checked
                    }
                  }))
                  setMyClusterData(myClusterData => ({
                    ...myClusterData,
                    "non-prod": {
                      ...myClusterData["non-prod"],
                      controllerManager: checked
                    }
                  }));
                  setError({
                    status: false,
                    msg: ""
                  });
                }} />
              </div>

              <div style={{ display: "flex", alignItems: "center" }}>
                <Box
                  title={<p><IntlMessages id="cluster.scheduler.title" /></p>}
                  subtitle={<IntlMessages id="cluster.scheduler.subTitle" />}
                  titleStyle={innerTitleStyle}
                  subTitleStyle={innerSubtitleStyle}
                  style={{ display: "flex", justifyContent: "space-between", padding: "20px 0" }}
                />
                <Switch style={myClusterData["non-prod"].scheduler ? { backgroundColor: "#132f44", opacity: isUpdate && "0.7", pointerEvents: isUpdate && "none" } : { opacity: isUpdate && "0.7", pointerEvents: isUpdate && "none" }} checked={myClusterData["non-prod"].scheduler} onChange={(checked) => {
                  setData(data => ({
                    ...data,
                    "non-prod": {
                      ...data["non-prod"],
                      scheduler: checked
                    }
                  }))
                  setMyClusterData(myClusterData => ({
                    ...myClusterData,
                    "non-prod": {
                      ...myClusterData["non-prod"],
                      scheduler: checked
                    }
                  }));
                  setError({
                    status: false,
                    msg: ""
                  });
                }} />
              </div>
            </Box>
          </div>}
        </>
      }

      {/* {isAdvanced && myClusterData.integration === 3 && <div>
        <Box title={<p>Instance Type</p>} titleStyle={innerTitleStyle} />
        <div style={{ display: "flex", padding: "20px", justifyContent: "space-between" }}>
          <Input placeholder='Min' name='instanceTypeMin' value={myClusterData["non-prod"].instanceTypeMin} style={{ ...inputStyle, width: "48%" }} onChange={handelInputChange} />
          <Input placeholder='Max' name="instanceTypeMax" value={myClusterData["non-prod"].instanceTypeMax} style={{ ...inputStyle, width: "48%" }} onChange={handelInputChange} />
        </div>
      </div>} */}

      {/* <hr style={{width: "92%", borderTop: "1px solid #88898b24", marginTop: "15px", marginBottom: "15px"}} /> */}

      {/* <hr style={{width: "92%", borderTop: "1px solid #88898b24", marginTop: "15px", marginBottom: "15px"}} />

      <Box
        title={<IntlMessages id="cluster.vpnserver.title" />}
        subtitle={<IntlMessages id="cluster.vpnserver.subTitle" />}
      >
        <ContentHolder>
          <Checkbox name="vpns" checked={myClusterData['non-prod'].vpns} onChange={handleChange}>Vpn Server</Checkbox>
        </ContentHolder>
      </Box>
      
      { myClusterData['non-prod'].vpns &&
        <>
          <hr style={{width: "92%", borderTop: "1px solid #88898b24", marginTop: "15px", marginBottom: "15px"}} />

          <Box
            title={<IntlMessages id="cluster.serverinstancetype.title" />}
            subtitle={<IntlMessages id="cluster.serverinstancetype.subTitle" />}
          >
            <ContentHolder>
              <Select
                style={{ width: '400px' }}
                defaultValue={myClusterData['non-prod'].serverInsType}
                onChange={handleSelectChange}
              >
                {serverInsTypes}
              </Select>
            </ContentHolder>
          </Box>
        </>
      } */}

      {/* <hr style={{width: "92%", borderTop: "1px solid #88898b24", marginTop: "15px", marginBottom: "15px"}} />

      <Box
        title={<IntlMessages id="cluster.backups.title" />}
        subtitle={<IntlMessages id="cluster.backups.subTitle" />}
      >
        <ContentHolder>
          <Checkbox name="backups" checked={myClusterData['non-prod'].backups} onChange={handleChange}>Backups</Checkbox>
        </ContentHolder>
      </Box> */}

      {/* <hr style={{width: "92%", borderTop: "1px solid #88898b24", marginTop: "15px", marginBottom: "15px"}} /> */}

      {/* <Box
        title={<IntlMessages id="cluster.natgatewayperAZ.title" />}
        subtitle={<IntlMessages id="cluster.natgatewayperAZ.subTitle" />}
      >
        <ContentHolder>
          <Checkbox name="natGatway" checked={myClusterData['non-prod'].natGatway}  onChange={handleChange}>Nat Gateway per AZ</Checkbox>
        </ContentHolder>
      </Box> */}

      {/* <hr style={{width: "92%", borderTop: "1px solid #88898b24", marginTop: "15px", marginBottom: "15px"}} /> */}

      <Box style={{ height: "150px" }}>
        <Box>
          {/* <ContentHolder style={{
          position: "relative"
        }}>
          <Button type="primary" onClick={() => {
            openAddOns(currentCluster, clusterTabSelected);
            openDrawer();
            }} style={{position: "absolute", right: "0", marginTop: "-20px"}}>
              Save & Next
          </Button>
          {error.status && <p className="isoHelperText">
              <span>{error.msg}</span>
          </p>}
        </ContentHolder> */}
        </Box>
      </Box>

      <CreateTaskFooter buttonContent={"Cluster"} givenButtonFunction1={handleSubmit} openDrawer={openDrawer} />

      {/* <Tooltip title="Please Implements Your Own Attachment Methods">
      <AttachmentWrapper>
        <HeadingWithIcon heading="Attachments" iconSrc={AttachmentIcon} />
        <HeadingWithIcon
          heading="Add an Attachment...."
          iconSrc={AttachmentIcon}
        />
      </AttachmentWrapper>
    </Tooltip> */}
    </ProdContentHold >
  );
};
