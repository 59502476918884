import { Button, InputNumber, Switch, message, Tooltip, Alert, Modal, Spin, Dropdown, Space } from "antd";
import Input from './ApplicationBasicDetails.styles';
import { Label } from "../UsersCRUD/UsersCRUD.styles";
import Select from './Deployment.styles';
import {
    SelectOption as Option,
} from '@iso/components/uielements/select';
import { Link, Navigate, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Checkbox } from 'antd';
import { useContext, useEffect, useState, useRef, lazy, Suspense, memo } from "react";
import { DeploymentContext } from "../../context/deploymentContext";
import { Scrollbars } from 'react-custom-scrollbars-2';
import { customFilterOption, customSortOptions, formatErrors, handleYAMLError, validateYaml } from "../../context/helper";
import api from "../../api";
import Box from "@iso/components/utility/box";
import downIcon from '../../assets/images/downArrow.svg';
// import EnvironmentVariablesDep from './EnvironmentVariablesDep';
import deleteIcon from '../../assets/images/deleteIcon.svg';
import ContentHolder from '@iso/components/utility/contentHolder';
import upIcon from '../../assets/images/upArrow.svg';
import { PlusOutlined, RightOutlined } from '@ant-design/icons';
import { setNestedObjectValues } from "formik";
import { AlertContext } from "../../context/alertContext";
import { validateName } from "../../context/helper";
import { InfoCircleFilled } from '@ant-design/icons';
import { FormWrapper as Form } from './ApplicationBasicDetails.styles.js';
import { BreadcrumbContext } from "../../context/breadcrumbContext";
import { RefreshLoader } from "../Dashboard/CustomComponents/RefreshLoader.style";
import refreshIcon from '../../assets/images/retriggerIcon.svg';
import { AppContext } from "../../context/ContextProvider";
import CustomInfoTooltip from "../Dashboard/CustomComponents/CustomInfoTooltip";
import { deploymentHelpChecks } from "./deploymentHelpChecks";
import { LoadingOutlined } from '@ant-design/icons';
import Card from "../UIElements/Card/Card.styles.js";
import { CustomModal } from "../Dashboard/CustomComponents/PageLoader.style.js";
import { useMemo } from "react";
import fileIcon from '@iso/assets/images/yamlDropdown.svg';
import fileIconSelected from '@iso/assets/images/yamlDropdownSelected.svg';
import { yamlContent } from "../../context/yamlContent.js";
const CodeViewGeneral = lazy(() => import("../Clusters/Task/CodeViewGeneral.js"));
const EnvironmentVariablesDep = lazy(() => import("./EnvironmentVariablesDep.js"));



const { confirm } = Modal;

const { TextArea } = Input;

let hzLineStyle = {
    width: "180px",
    borderInline: "0",
    borderBottom: "0",
    borderTop: "2px #DAEEFF dotted",
};

let plusImgStyle = {
    width: "15px",
    height: "15px",
    position: "absolute",
    left: "50%",
    zIndex: "1",
    background: "white",
};

let pipelineImgStyle = {
    width: "80px",
    height: "80px",
};

const marginsBot = {
    position: 'fixed',
    opacity: '100%',
    transition: 'all 1s ease',
    top: "7vw",
    right: "0",
    zIndex: '1000',
    width: "25%",
    padding: '15px'
};

const fadeMarginBot = {
    position: 'fixed',
    opacity: '0',
    transition: 'all 1s ease',
    top: "7vw",
    right: "0",
    zIndex: '-1',
    width: "25%",
    padding: '15px'
};


let innerTitleStyle = {
    color: "#132f44",
    fontFamily: "Poppins",
    fontWeight: "600",
    fontSize: "16px"
};

const headLabelStyle = {
    fontFamily: "Poppins",
    fontSize: "16px",
    color: "#1f1f1f",
    fontWeight: "500",
};
const labelStyle = {
    fontFamily: "Poppins",
    fontSize: "14px",
    color: "#1f1f1f",
    fontWeight: "500",
};

const subLabelStyle = {
    fontFamily: "Poppins",
    fontSize: "14px",
    color: "#292929",
};

const subDivStyle = {
    paddingLeft: "10px",
    display: "flex",
    flexDirection: "column"
}

const inputStyle = {
    height: "44px"
}

const fieldStyles = {
    width: "47%",
    display: "flex",
    flexDirection: "column",
}

const detailSubTextStyle = {
    fontFamily: "Nunito Sans",
    fontSize: "14px",
    fontWeight: "400",
    marginLeft: "25px"
}

export default memo(({ generateImpField, setGenerateImpField, valueFromDropdown, setIsForm, services, serviceHighlighted, updateFetched, handleSaveAndNextImp, cloneFetched, nodeGroupList, lastEnabledService, handleSaveAndNext, serviceSelected, setServiceSelected, percentCompleted, setPercentCompleted, setApplicationKey, keySelected, redirectToNext, refreshNodegroups, setRefreshNodegroups, updateEnabled, handlePrevBtn, containerRegs, setContainerRegs }) => {
    console.log("Service selected: ", serviceSelected);

    const { addDep, addUpdatedData, setAddUpdatedData, updateDep, setUpdateDep, updatedData, setUpdatedData, handleImportantFields, impFieldConvert, setNextBtn, importantFields, importantEnabled, formErrors, setFormErrors, submitAll, setSubmitAll, deploymentData, applicationData, setApplicationData, selectedProject, containerRegistries, cloneDeploymentData, cloneApplicationData, setCloneApplicationData, cloneContainerRegistries, cloneDep, initialApplicationData, dataSourcesData, mongoDataSourcesData, postgresDataSourcesData, redisData, cloneDataSourcesData, cloneMongoDataSourcesData, clonePostgresDataSourcesData, cloneRedisData, handleSubmit, selectedEnv, serviceCategories, cloneServiceCategories, deploymentForms, setDeploymentForms, nextBtn, prevBtn, setPrevBtn, prerequisite, setPrerequisite, setIsSubmitted, setPrevImportantField, setCurrentImportantField, setNextImportantField, warnings } = useContext(DeploymentContext);
    const [appData, setAppData] = useState({ ...initialApplicationData });
    const { pageHeading } = useContext(BreadcrumbContext);
    console.log("Initial Application Data", applicationData, initialApplicationData, cloneApplicationData, addUpdatedData);
    const [repoList, setRepoList] = useState([]);
    const [agentList, setAgentList] = useState([]);

    const [containerRegistryList, setContainerRegistryList] = useState([]);
    const [depData, setDepData] = useState(updateDep ? { ...updatedData.deploymentData } : addDep ? { ...addUpdatedData.deploymentData } : (!cloneDep ? { ...deploymentData } : { ...cloneDeploymentData }));

    const [switchState, setSwitchState] = useState({
        customDomain: "false",
        configure: "false",
        createStorage: "false",
        secretsEnabled: "false",
        autoScaling: "false"
    });
    const [volumeClasses, setVolumeClasses] = useState([]);
    const [removeIcon, setRemoveIcon] = useState(true);
    const [removeVolumeIcon, setRemoveVolumeIcon] = useState(true);
    const [depColor, setDepColor] = useState({
        services: true,
        resources: false,
        taskSchedulers: false,
        tags: false,
        volumes: false,
    });
    const buttonRef1 = useRef(null);
    // const [optionsSelected, setOptionsSelected] = useState({
    //     basicDetails: false,
    //     envVars: false,
    //     volumes: false,
    //     deployments: false
    // });

    const [deploymentTypes, setDeploymentTypes] = useState([]);
    const [serviceTypes, setServiceTypes] = useState([]);
    const [servicePreferences, setServicePreferences] = useState([]);
    const [volumeTypes, setVolumeTypes] = useState([]);
    const [error, setError] = useState("");
    const [serviceData, setServiceData] = useState([]);
    const [defaultValues, setDefaultValues] = useState(false);
    const [form] = Form.useForm();
    const { alertOptions, set_alertOptions } = useContext(AlertContext);
    const { setOpenModel } = useContext(AppContext);
    const [nodeGroups, setNodeGroups] = useState([]);
    const targetRef = useRef(null);
    const cloneRef = useRef(false);
    const [canFetch, setCanFetch] = useState(false);
    const [executeEnvs, setExecuteEnvs] = useState(false);
    const [afterRefresh, setAfterRefresh] = useState(false);
    //const [prefixCheck, setPrefixCheck] = useState(false);
    const prefixCheck = useRef();
    let location = useLocation();
    let navigate = useNavigate();
    const [allOptions, setAllOptions] = useState({
        basicDetails: true,
        envVars: true,
        deployments: true,
        advancedSettings: false
    });
    const prerequisiteRef = useRef(false);
    const [errorVars, setErrorVars] = useState(0);
    const [branchLoad, setBranchLoad] = useState(true);
    const [dnsMsg, setDnsMsg] = useState({
        error: false,
        msg: ""
    });
    const [validateDomainStatus, setValidateDomainStatus] = useState("");
    const [imageTagList, setImageTagList] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const [yamlData, setYamlData] = useState("");
    const warningsRef = useRef([]);
    // const [isFormSet, setIsFormSet] = useState(false);

    //let totalFieldsFilled = 0;


    const setAppFromEnv = (envs) => {
        console.log("This sets envs: ", envs, appData);
        setAppData(appData => ({
            ...appData,
            environments: envs
        }));
    }


    const calculateBasicFieldsFilled = (appData) => {
        return Object.values(appData.basicDetails).filter((val) => {
            if ((typeof val === 'string' && val !== "") || (typeof val === 'object' && Object.keys(val).length > 0)) {
                return 1;
            }
        }).length;
    }

    //console.log("Filled values basic", calculateBasicFieldsFilled(appData));

    useEffect(() => {
        console.log("Basic details percent: ", (calculateBasicFieldsFilled(appData) / (Object.keys(appData.basicDetails).length)) * 100);
        setPercentCompleted({
            ...percentCompleted,
            application: {
                ...percentCompleted.application,
                basicDetails: (calculateBasicFieldsFilled(appData) / (Object.keys(appData.basicDetails).length)) * 100
            }
        });
    }, [appData]);

    //Progress bar
    // useEffect(() => {
    //     let ctr1, ctr2, ctr3, ctr4 = 0;
    //     Object.values(appData.basicDetails).map((val) => {
    //         if(val){
    //             console.log("Inner basic", ctr1);
    //             ctr1++;
    //         }
    //     });
    //     console.log("Filled values basic", ctr1);
    // }, [appData]);

    //Option selected(Basic Details, Env Vars,...)
    // useEffect(() => {
    //     console.log("My option selected", allOptions.application);
    //     setOptionsSelected({ ...allOptions.application });
    // }, [allOptions]);


    const loadImages = () => {
        if (appData.basicDetails?.imageRepo && appData.basicDetails?.imageRepo !== "" && appData.basicDetails?.appSource !== "PUBLIC") {
            api.get(`/api/integration/images/${appData.basicDetails?.appSource.id}/${appData.basicDetails?.imageRepo}`).then((res) => {
                let data = res.data;
                if (data.status) {
                    let imageTags = [];
                    data.data.forEach((val) => {
                        imageTags.push(
                            <Option key={val} value={val} name="imageTag">{val}</Option>
                        );
                    });
                    setImageTagList(imageTags);
                }
                else {
                    console.log("Error");
                }
            }).catch((err) => {
                console.log("Error");
            });
        }
    }

    useEffect(() => {
        if ((!cloneDep && !updateDep && !addDep ? serviceHighlighted.id === serviceSelected.id : serviceHighlighted.service_id === serviceSelected.service_id) && imageTagList.length === 0) {
            loadImages();
        }
    }, [appData?.basicDetails?.imageRepo, serviceHighlighted.id, serviceHighlighted.service_id]);

    useEffect(() => {
        if (appData?.basicDetails?.imageRepo !== "" && imageTagList.length > 0) {
            loadImages();
        }
    }, [appData?.basicDetails?.imageRepo]);

    useEffect(() => {
        console.log("Branch api: ", repoList, serviceHighlighted, appData.basicDetails.sourceType, !cloneDep && !updateDep && !addDep ? serviceHighlighted.id === serviceSelected.id : serviceHighlighted.service_id === serviceSelected.service_id && repoList.length === 0 && repoList.length);
        if ((!cloneDep && !updateDep && !addDep ? serviceHighlighted.id === serviceSelected.id : serviceHighlighted.service_id === serviceSelected.service_id)
            && repoList.length === 0) {
            if (appData.basicDetails.sourceType === "Git Repositories") {
                console.log("scrolled: ", serviceSelected);
                if (Object.keys(serviceSelected).length > 0) {
                    if (serviceSelected.type === "application" || serviceSelected.service_type === "application") {
                        if (serviceSelected.configuration.account_id === null || serviceSelected.configuration.account_id === "") {
                            console.log("Public service: ", serviceSelected);
                            let rawData = serviceSelected.configuration.repo_url.split("/");
                            let owner = rawData[3];
                            let repoName = rawData[4];
                            if (repoName) {
                                if (repoName.includes('.git')) {
                                    repoName = repoName.split(".git")[0];
                                }
                            }
                            if (serviceSelected.configuration.repo_url.includes("github")) {
                                fetch(`https://api.github.com/repos/${owner}/${repoName}/branches`)
                                    .then(async (res) => await res.json())
                                    .then((data) => {
                                        console.log('branches data :', data);
                                        if (data.message) {
                                            console.log("Please provide correct url");
                                        }
                                        else {
                                            let branches = [];
                                            data.map((temp, ind) => {
                                                branches.push(
                                                    <Option key={ind} name="branch" value={temp.name}>
                                                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "Center" }}>
                                                            <span>{temp.name}</span>
                                                        </div>
                                                    </Option>
                                                );
                                            });
                                            setRepoList(branches);
                                        }
                                        setBranchLoad(false);
                                    })
                                    .catch((error) => {
                                        console.error('Error fetching branches:', error);
                                        setBranchLoad(false);
                                    });
                            }
                            else if (serviceSelected.configuration.repo_id) {
                                // GitLab instance details
                                let gitlabInstance = `https://${rawData[2]}`;
                                let projectPath = `${owner}%2F${repoName}`;

                                fetch(`${gitlabInstance}/api/v4/projects/${projectPath}/repository/branches`)
                                    .then(async (res) => await res.json())
                                    .then((data) => {
                                        console.log('branches data :', data);
                                        if (data.message) {
                                            console.log("Please provide correct url");
                                        }
                                        else {
                                            let branches = [];
                                            data.map((temp, ind) => {
                                                branches.push(
                                                    <Option key={ind} name="branch" value={temp.name}>
                                                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "Center" }}>
                                                            <span>{temp.name}</span>
                                                        </div>
                                                    </Option>
                                                );
                                            });
                                            setRepoList(branches);
                                        }
                                        setBranchLoad(false);
                                    })
                                    .catch((error) => {
                                        console.error('Error fetching branches:', error);
                                        setBranchLoad(false);
                                    });
                            }
                            else if (serviceSelected.configuration.repo_url.includes("bitbucket")) {
                                fetch(`https://api.bitbucket.org/2.0/repositories/${owner}/${repoName}/refs/branches`)
                                    .then(async (res) => await res.json())
                                    .then((data) => {
                                        console.log('branches data :', data);
                                        if (data.message) {
                                            console.log("Please provide correct url.");
                                        }
                                        else {
                                            let branches = [];
                                            data.map((temp, ind) => {
                                                branches.push(
                                                    <Option key={ind} name="branch" value={temp.name}>
                                                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "Center" }}>
                                                            <span>{temp.name}</span>
                                                        </div>
                                                    </Option>
                                                );
                                            });
                                            setRepoList(branches);
                                        }
                                    })
                                    .catch((error) => {
                                        console.error('Error fetching branches:', error);
                                    });
                            }
                            else {
                                // GitLab instance details
                                let gitlabInstance = `https://${rawData[2]}`;
                                let projectPath = `${owner}%2F${repoName}`;

                                fetch(`${gitlabInstance}/api/v4/projects/${projectPath}/repository/branches`)
                                    .then(async (res) => {
                                        let data = await res.json();
                                        if (data.message) {
                                            console.log("Please provide correct url");
                                        }
                                        else {
                                            let branches = [];
                                            data.map((temp, ind) => {
                                                branches.push(
                                                    <Option key={ind} name="branch" value={temp.name}>
                                                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "Center" }}>
                                                            <span>{temp.name}</span>
                                                        </div>
                                                    </Option>
                                                );
                                            });
                                            setRepoList(branches);
                                        }
                                        setBranchLoad(false);
                                    }).catch((error) => {
                                        console.error('Error fetching branches:', error);
                                        setBranchLoad(false);
                                    });
                            }
                        }
                        else {
                            api.post(process.env.REACT_APP_API_URI + '/api/integration/vcs/branch/', {
                                account_id: serviceSelected.configuration.account_id,
                                repo_name: serviceSelected.configuration.repo_name
                            }).then((res) => {
                                let data = res.data;
                                if (!data.status) {
                                    let err = formatErrors(data.error_details);
                                    // alert(`Error: ${err}`);
                                }
                                else if (data.code !== 200) {
                                    // alert(`Error: Please provide correct repo url!`);
                                }
                                else {
                                    let branches = [];
                                    data.data.map((temp, ind) => {
                                        branches.push(
                                            <Option key={ind} name="branch" value={temp}>
                                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "Center" }}>
                                                    <span>{temp}</span>
                                                </div>
                                            </Option>
                                        );
                                    });
                                    setRepoList(branches);
                                }
                                setBranchLoad(false);
                            });
                        }
                    }
                    setError({
                        status: false,
                        msg: ""
                    })
                }
            }
        }
    }, [serviceHighlighted.id, serviceHighlighted.service_id, appData.basicDetails.sourceType, repoList.length]);
    // useEffect(() => {
    //     form.validateFields().then((values) => {
    //         console.log("Validated");
    //     }).catch((errorInfo) => {
    //         // Handle form validation errors
    //         console.log(errorInfo);
    //     });    }, []);

    useEffect(() => {
        let containers = [];
        containerRegs.map((child, ind) => {
            containers.push(
                <Option key={child.alias + ind + '!'} name={"containerRegistryId"} data-region={child.region} value={!child.account_id ? `${child.alias} - ${child.integration_type}` : `${child.account_id}${child.alias && `(${child.alias})`} / ${child.region}`}>
                    <div style={{ width: "100%", display: "flex" }}>
                        <p> {!child.account_id ? `${child.alias} - ${child.integration_type}` : `${child.account_id}${child.alias && `(${child.alias})`} / ${child.region}`} </p>
                    </div>
                </Option>
            );
        });
        setContainerRegistryList(containers);
    }, [containerRegs]);

    useEffect(() => {
        let types = ["Cluster IP", "Public Load Balancer", /*"Private Load Balancer", "Public Ingress", "Private Ingress"*/];
        let typeOptions = [];
        types.map((child) => {
            typeOptions.push(
                <Option key={child} name={"endpointType"} value={child}>
                    <p>{child}</p>
                </Option>);
        });
        setDeploymentTypes(typeOptions);
    }, []);

    useEffect(() => {
        //let types = [deploymentData.ingressAlbEnabled && "Existing Load Balancer(ALB Ingress)", deploymentData.ingressNginxEnabled && "Existing Load Balancer(Nginx Ingress)", "Dedicated Load Balancer"];
        if (addDep) {
            let types = [];
            if (addUpdatedData.deploymentData.ingressAlbEnabled) {
                types.push("Existing Load Balancer(ALB Ingress)");
            }
            if (addUpdatedData.deploymentData.ingressNginxEnabled) {
                types.push("Existing Load Balancer(Nginx Ingress)");
            }
            types.push("Dedicated Load Balancer");

            let serviceTypeOptions = [];
            types.map((child) => {
                serviceTypeOptions.push(
                    <Option key={child} name={"serviceType"} value={child}>
                        <p>{child}</p>
                    </Option>);
            });
            setServiceTypes(serviceTypeOptions);
        }
        else if (updateDep) {
            let types = [];
            if (updatedData.deploymentData.ingressAlbEnabled) {
                types.push("Existing Load Balancer(ALB Ingress)");
            }
            if (updatedData.deploymentData.ingressNginxEnabled) {
                types.push("Existing Load Balancer(Nginx Ingress)");
            }
            types.push("Dedicated Load Balancer");

            let serviceTypeOptions = [];
            types.map((child) => {
                serviceTypeOptions.push(
                    <Option key={child} name={"serviceType"} value={child}>
                        <p>{child}</p>
                    </Option>);
            });
            setServiceTypes(serviceTypeOptions);
        }
        else if (!cloneDep) {
            let types = [];
            if (deploymentData.ingressAlbEnabled) {
                types.push("Existing Load Balancer(ALB Ingress)");
            }
            if (deploymentData.ingressNginxEnabled) {
                types.push("Existing Load Balancer(Nginx Ingress)");
            }
            types.push("Dedicated Load Balancer");

            console.log("MY TYPES: ", types, deploymentData.ingressAlbEnabled, deploymentData.ingressNginxEnabled);
            let serviceTypeOptions = [];
            types.map((child) => {
                serviceTypeOptions.push(
                    <Option key={child} name={"serviceType"} value={child}>
                        <p>{child}</p>
                    </Option>);
            });
            setServiceTypes(serviceTypeOptions);
        }
        else {
            let types = [];
            if (cloneDeploymentData.ingressAlbEnabled) {
                types.push("Existing Load Balancer(ALB Ingress)");
            }
            if (cloneDeploymentData.ingressNginxEnabled) {
                types.push("Existing Load Balancer(Nginx Ingress)");
            }
            types.push("Dedicated Load Balancer");

            console.log("MY TYPES: ", types, deploymentData.ingressAlbEnabled, deploymentData.ingressNginxEnabled);
            let serviceTypeOptions = [];
            types.map((child) => {
                serviceTypeOptions.push(
                    <Option key={child} name={"serviceType"} value={child}>
                        <p>{child}</p>
                    </Option>);
            });
            setServiceTypes(serviceTypeOptions);
        }
    }, []);

    useEffect(() => {
        let preferences = ["Compute", "Memory", "GPU"];
        let preferencesOptions = [];
        preferences.map((child) => {
            preferencesOptions.push(
                <Option key={child} name={"serverPreference"} value={child}>
                    <p> {child} </p>
                </Option>
            );
        });

        let pipelinesAgents = ["Alpine"];
        let pipelineAgentOptions = [];
        pipelinesAgents.map((child) => {
            pipelineAgentOptions.push(
                <Option key={child} name={"agentName"} value={child}>
                    <p> {child} </p>
                </Option>
            );
        });

        setServicePreferences(preferencesOptions);
        setAgentList(pipelineAgentOptions);
    }, []);

    useEffect(() => {
        let types = ["Configmap", "Storage", "Secrets"];
        let volumeOptions = [];
        types.map((child, ind) => {
            volumeOptions.push(
                <Option key={child} name="volumeType" value={child}>
                    <p> {child} </p>
                </Option>
            );
        });
        setVolumeTypes(volumeOptions);
    }, []);

    useEffect(() => {
        let arr = [];

        let arrSort = nodeGroupList.sort((a, b) => (a.name.localeCompare(b.name)));
        console.log("nodeGroupList in ApplicationBasicDetails :", arrSort)
        if (arrSort.length > 0) {
            arrSort.map((val) => {
                if (val.status === "Active" && val.managed_by > 0) {
                    arr.push(
                        <Option key={val.nodegroup_unique_id} name="nodeGroupName" value={val.name} data-alias={val.nodegroup_type} data-arch={val.arch_type}>
                            <p> {val.name} </p>
                        </Option>
                    );
                }

                // make default check
                if (val.status === "Active" && val.managed_by === 1) {
                    if ((!afterRefresh && !cloneDep && !updateDep) || (cloneDep && cloneDeploymentData.clusterId !== selectedEnv.cluster_unique_id) || (updateDep && updatedData.deploymentData.clusterId !== updatedData.selectedEnv.cluster_unique_id)) {
                        setAppData(appData => ({
                            ...appData,
                            deployments: {
                                ...appData.deployments,
                                nodeGroupName: val.name,
                                nodeGroupType: val.nodegroup_type,
                                multiselectNodeGroupList: [val.name],
                                listOfNodegroups: [{
                                    nodegroup_name: val.name,
                                    nodegroup_type: val.nodegroup_type,
                                    nodegroup_unique_id: val.nodegroup_unique_id,
                                    arch_type: val.arch_type
                                }],
                            }
                        }));
                        form.setFieldValue('nodeGroupName', val.name);
                    }
                }
            });
            //arr = arr.sort((a, b) => a.props.children.localeCompare(b.props.children));
            setNodeGroups(arr);
        }
    }, [nodeGroupList]);

    useEffect(() => {
        let classes = [];
        if (depData.clusterIntegrationId === 1) {
            classes = ["EBS", "EFS"];
        }
        else if (depData.clusterIntegrationId === 3) {
            classes = ["PD", "NFS", "Cloud File Store"];
        }
        let classesOptions = [];
        classes.map((child) => {
            classesOptions.push(
                <Option key={child} name="type" value={child}>
                    <p> {child} </p>
                </Option>
            );
        });
        setVolumeClasses(classesOptions);
    }, []);

    // useEffect(() => {
    //     console.log("changed nodegroups :", appData.deployments.nodeGroupName);

    // }, [appData.deployments.nodeGroupName]);

    //this useEffect helps us to set the values of the selected application in appData state. Initially the data in context is empty. So, we will assign the value of initialApplication data to appData.
    useEffect(() => {
        //if (executeSubForms) {
        //console.log("context app inner: ", applicationData[serviceSelected.name].basicDetails);
        if (addDep) {
            console.log("Addupdated data: ", addUpdatedData.applicationData[serviceSelected.name]);
            if (addUpdatedData.applicationData[serviceSelected.name]) {
                let vols = [];
                let tags = [];
                addUpdatedData.applicationData[serviceSelected.name].volumes.map((val) => {
                    if (val.volumeType === "Configmap") {
                        vols.push({
                            'configName': val.config.configName,
                            'createMountPath': val.config.createMountPath,
                            'createConfig': val.config.createConfig,
                        });
                    }
                    else if (val.volumeType === "Secrets") {
                        vols.push({
                            'secretsName': val.secrets.secretsName,
                            'createSecretsMountPath': val.secrets.createSecretsMountPath,
                            'createSecret': val.secrets.createSecretConfig,
                        });
                    }
                    else if (val.volumeType === "Storage") {
                        vols.push({
                            'storageName': val.storage.storageDes.storageName,
                            'type': "EBS",//val.storage.storageDes.type, //Fixed to EBS for now
                            'path': val.storage.storageDes.path,
                            'size': val.storage.storageDes.size,

                        });
                    }
                });
                addUpdatedData.applicationData[serviceSelected.name].deployments.tags.map((val, idx) => {
                    tags.push({
                        'name': val.name,
                        'value': val.value
                    });
                });
                form.setFieldsValue(
                    {
                        'appName': addUpdatedData.applicationData[serviceSelected.name].basicDetails.appName,
                        'url': addUpdatedData.applicationData[serviceSelected.name].basicDetails.url,
                        'sourceType': addUpdatedData.applicationData[serviceSelected.name].basicDetails.sourceType,
                        'appSource': addUpdatedData.applicationData[serviceSelected.name].basicDetails.sourceType === "Git Repositories" ? "" : !addUpdatedData.applicationData[serviceSelected.name].basicDetails.appSource.integration_type ? "PUBLIC" : (!addUpdatedData.applicationData[serviceSelected.name].basicDetails.appSource.account_id ? `${addUpdatedData.applicationData[serviceSelected.name].basicDetails.appSource.alias} - ${addUpdatedData.applicationData[serviceSelected.name].basicDetails.appSource.integration_type}` : `${addUpdatedData.applicationData[serviceSelected.name].basicDetails.appSource.account_id}${addUpdatedData.applicationData[serviceSelected.name].basicDetails.appSource.alias ? `(${addUpdatedData.applicationData[serviceSelected.name].basicDetails.appSource.alias})` : ``} / ${addUpdatedData.applicationData[serviceSelected.name].basicDetails.appSource.region}`),
                        'imageRepo': addUpdatedData.applicationData[serviceSelected.name].basicDetails.imageRepo,
                        'imageTag': addUpdatedData.applicationData[serviceSelected.name].basicDetails.imageTag === "" ? null : addUpdatedData.applicationData[serviceSelected.name].basicDetails.imageTag,
                        'branch': addUpdatedData.applicationData[serviceSelected.name].basicDetails.branch === "" ? null : addUpdatedData.applicationData[serviceSelected.name].basicDetails.branch,
                        'requestCpu': addUpdatedData.applicationData[serviceSelected.name].deployments.resources.cpu.requestCpu,
                        'requestMem': addUpdatedData.applicationData[serviceSelected.name].deployments.resources.memory.requestMem,
                        'dockerFilePath': addUpdatedData.applicationData[serviceSelected.name].basicDetails.dockerFilePath,
                        'buildContext': addUpdatedData.applicationData[serviceSelected.name].basicDetails.buildContext,
                        // 'containerRegistryId': applicationData[serviceSelected.name].basicDetails.containerRegistryId.alias === "" ? null : applicationData[serviceSelected.name].basicDetails.containerRegistryId.alias,
                        'containerRegistryId': !addUpdatedData.applicationData[serviceSelected.name].basicDetails.containerRegistryId.integration_type ? null : (!addUpdatedData.applicationData[serviceSelected.name].basicDetails.containerRegistryId.account_id ? `${addUpdatedData.applicationData[serviceSelected.name].basicDetails.containerRegistryId.alias} - ${addUpdatedData.applicationData[serviceSelected.name].basicDetails.containerRegistryId.integration_type}` : `${addUpdatedData.applicationData[serviceSelected.name].basicDetails.containerRegistryId.account_id}${addUpdatedData.applicationData[serviceSelected.name].basicDetails.containerRegistryId.alias ? `(${addUpdatedData.applicationData[serviceSelected.name].basicDetails.containerRegistryId.alias})` : ``} / ${addUpdatedData.applicationData[serviceSelected.name].basicDetails.containerRegistryId.region}`),
                        'port': addUpdatedData.applicationData[serviceSelected.name].deployments.port,
                        'healthCheckPath': addUpdatedData.applicationData[serviceSelected.name].deployments.healthCheckPath,
                        'endpointType': addUpdatedData.applicationData[serviceSelected.name].deployments.endpointType === "" ? null : addUpdatedData.applicationData[serviceSelected.name].deployments.endpointType,
                        'requestCpu': addUpdatedData.applicationData[serviceSelected.name].deployments.resources.cpu.requestCpu,
                        'limitCpu': addUpdatedData.applicationData[serviceSelected.name].deployments.resources.cpu.limitCpu,
                        'requestMem': addUpdatedData.applicationData[serviceSelected.name].deployments.resources.memory.requestMem,
                        'limitMem': addUpdatedData.applicationData[serviceSelected.name].deployments.resources.memory.limitMem,
                        'minimumReplica': addUpdatedData.applicationData[serviceSelected.name].deployments.autoScaling.autoScalingData.minimumReplica,
                        'maximumReplica': addUpdatedData.applicationData[serviceSelected.name].deployments.autoScaling.autoScalingData.maximumReplica,
                        'cpuAuto': addUpdatedData.applicationData[serviceSelected.name].deployments.autoScaling.autoScalingData.autoScalingParameter.cpuAuto,
                        'memoryAuto': addUpdatedData.applicationData[serviceSelected.name].deployments.autoScaling.autoScalingData.autoScalingParameter.cpuAuto,
                        'nodeGroupName': !addUpdatedData.applicationData[serviceSelected.name].deployments.nodeGroupName ? null : addUpdatedData.applicationData[serviceSelected.name].deployments.nodeGroupName,
                        'volumeList': vols.length > 0 ? vols : [{}],
                        'tags': tags.length > 0 ? tags : [{}],
                        'serviceType': addUpdatedData.applicationData[serviceSelected.name].deployments.serviceType === "" ? null : addUpdatedData.applicationData[serviceSelected.name].deployments.serviceType,
                        'domainHostUrl': addUpdatedData.applicationData[serviceSelected.name].deployments.domainHostUrl,
                        'gPath': addUpdatedData.applicationData[serviceSelected.name].deployments.gatewayHttps.gPath,
                        'entryPointCommandQueues': addUpdatedData.applicationData[serviceSelected.name].deployments.taskSchedulers.entryPointCommandQueues,
                        'entryPointCommandCrons': addUpdatedData.applicationData[serviceSelected.name].deployments.taskSchedulers.entryPointCommandCrons,
                        'yamlData': addUpdatedData.applicationData[serviceSelected.name].deployments[addUpdatedData.applicationData[serviceSelected.name].deployments.yamlType].yamlString,
                        //'': applicationData[serviceSelected.name].volumes
                    }
                );
                console.log("====Setting add updated data====: ", addUpdatedData);
                setAppData(JSON.parse(JSON.stringify({ ...addUpdatedData.applicationData[serviceSelected.name] })));
                setYamlData(addUpdatedData.applicationData[serviceSelected.name].deployments[addUpdatedData.applicationData[serviceSelected.name].deployments.yamlType].yamlString);
            }
            else {
                form.setFieldsValue({
                    'tags': [{}],
                    'volumeList': [{}],
                    'yamlData': initialApplicationData.deployments[initialApplicationData.deployments.yamlType].yamlString,
                });
                setAppData(JSON.parse(JSON.stringify({ ...initialApplicationData })));
                setYamlData(initialApplicationData.deployments[initialApplicationData.deployments.yamlType].yamlString);
            }
            console.log("===== Entering here ======", executeEnvs);
            setExecuteEnvs(executeEnvs => !executeEnvs);
            setDefaultValues(true);
        }
        else if (updateDep) {
            console.log("Update called: ", updatedData, updateFetched);
            if (updateFetched) {
                if (updatedData.applicationData[serviceSelected.name]) {
                    let vols = [];
                    let tags = [];
                    console.log("Update data: ", updatedData);
                    updatedData.applicationData[serviceSelected.name].volumes.map((val) => {
                        if (val.volumeType === "Configmap") {
                            vols.push({
                                'configName': val.config.configName,
                                'createMountPath': val.config.createMountPath,
                                'createConfig': val.config.createConfig
                            });
                        }
                        else if (val.volumeType === "Secrets") {
                            vols.push({
                                'secretsName': val.secrets.secretsName,
                                'createSecretsMountPath': val.secrets.createSecretsMountPath,
                                'createSecrets': val.secrets.createSecrets
                            });
                        }
                        else if (val.volumeType === "Storage") {
                            vols.push({
                                'storageName': val.storage.storageDes.storageName,
                                'type': "EBS",//val.storage.storageDes.type, //Fixed to EBS for now
                                'path': val.storage.storageDes.path,
                                'size': val.storage.storageDes.size,
                            });
                        }
                    });
                    updatedData.applicationData[serviceSelected.name].deployments.tags.map((val, idx) => {
                        tags.push({
                            'name': val.name,
                            'value': val.value
                        });
                    });
                    form.setFieldsValue(
                        {
                            'appName': updatedData.applicationData[serviceSelected.name].basicDetails.appName,
                            'url': updatedData.applicationData[serviceSelected.name].basicDetails.url,
                            'sourceType': updatedData.applicationData[serviceSelected.name].basicDetails.sourceType,
                            'appSource': updatedData.applicationData[serviceSelected.name].basicDetails.sourceType === "Git Repositories" ? "" : !updatedData.applicationData[serviceSelected.name].basicDetails.appSource.integration_type ? "PUBLIC" : (!updatedData.applicationData[serviceSelected.name].basicDetails.appSource.account_id ? `${updatedData.applicationData[serviceSelected.name].basicDetails.appSource.alias} - ${updatedData.applicationData[serviceSelected.name].basicDetails.appSource.integration_type}` : `${updatedData.applicationData[serviceSelected.name].basicDetails.appSource.account_id}${updatedData.applicationData[serviceSelected.name].basicDetails.appSource.alias ? `(${updatedData.applicationData[serviceSelected.name].basicDetails.appSource.alias})` : ``} / ${updatedData.applicationData[serviceSelected.name].basicDetails.appSource.region}`),
                            'imageRepo': updatedData.applicationData[serviceSelected.name].basicDetails.imageRepo,
                            'imageTag': updatedData.applicationData[serviceSelected.name].basicDetails.imageTag === "" ? null : updatedData.applicationData[serviceSelected.name].basicDetails.imageTag,
                            'branch': updatedData.applicationData[serviceSelected.name].basicDetails.branch === "" ? null : updatedData.applicationData[serviceSelected.name].basicDetails.branch,
                            'requestCpu': updatedData.applicationData[serviceSelected.name].deployments.resources.cpu.requestCpu,
                            'requestMem': updatedData.applicationData[serviceSelected.name].deployments.resources.memory.requestMem,
                            'dockerFilePath': updatedData.applicationData[serviceSelected.name].basicDetails.dockerFilePath,
                            'buildContext': updatedData.applicationData[serviceSelected.name].basicDetails.buildContext,
                            // 'containerRegistryId': applicationData[serviceSelected.name].basicDetails.containerRegistryId.alias === "" ? null : applicationData[serviceSelected.name].basicDetails.containerRegistryId.alias,
                            'containerRegistryId': !updatedData.applicationData[serviceSelected.name].basicDetails.containerRegistryId.integration_type ? null : (!updatedData.applicationData[serviceSelected.name].basicDetails.containerRegistryId.account_id ? `${updatedData.applicationData[serviceSelected.name].basicDetails.containerRegistryId.alias} - ${updatedData.applicationData[serviceSelected.name].basicDetails.containerRegistryId.integration_type}` : `${updatedData.applicationData[serviceSelected.name].basicDetails.containerRegistryId.account_id}${updatedData.applicationData[serviceSelected.name].basicDetails.containerRegistryId.alias ? `(${updatedData.applicationData[serviceSelected.name].basicDetails.containerRegistryId.alias})` : ``} / ${updatedData.applicationData[serviceSelected.name].basicDetails.containerRegistryId.region}`),
                            'port': updatedData.applicationData[serviceSelected.name].deployments.port,
                            'healthCheckPath': updatedData.applicationData[serviceSelected.name].deployments.healthCheckPath,
                            'endpointType': updatedData.applicationData[serviceSelected.name].deployments.endpointType === "" ? null : updatedData.applicationData[serviceSelected.name].deployments.endpointType,
                            'requestCpu': updatedData.applicationData[serviceSelected.name].deployments.resources.cpu.requestCpu,
                            'limitCpu': updatedData.applicationData[serviceSelected.name].deployments.resources.cpu.limitCpu,
                            'requestMem': updatedData.applicationData[serviceSelected.name].deployments.resources.memory.requestMem,
                            'limitMem': updatedData.applicationData[serviceSelected.name].deployments.resources.memory.limitMem,
                            'minimumReplica': updatedData.applicationData[serviceSelected.name].deployments.autoScaling.autoScalingData.minimumReplica,
                            'maximumReplica': updatedData.applicationData[serviceSelected.name].deployments.autoScaling.autoScalingData.maximumReplica,
                            'cpuAuto': updatedData.applicationData[serviceSelected.name].deployments.autoScaling.autoScalingData.autoScalingParameter.cpuAuto,
                            'memoryAuto': updatedData.applicationData[serviceSelected.name].deployments.autoScaling.autoScalingData.autoScalingParameter.cpuAuto,
                            'nodeGroupName': !updatedData.applicationData[serviceSelected.name].deployments.nodeGroupName ? null : updatedData.applicationData[serviceSelected.name].deployments.nodeGroupName,
                            'volumeList': vols.length > 0 ? vols : [{}],
                            'tags': tags.length > 0 ? tags : [{}],
                            'serviceType': updatedData.applicationData[serviceSelected.name].deployments.serviceType === "" ? null : updatedData.applicationData[serviceSelected.name].deployments.serviceType,
                            'domainHostUrl': updatedData.applicationData[serviceSelected.name].deployments.domainHostUrl,
                            'gPath': updatedData.applicationData[serviceSelected.name].deployments.gatewayHttps.gPath,
                            'entryPointCommandQueues': updatedData.applicationData[serviceSelected.name].deployments.taskSchedulers.entryPointCommandQueues,
                            'entryPointCommandCrons': updatedData.applicationData[serviceSelected.name].deployments.taskSchedulers.entryPointCommandCrons,
                            'yamlData': updatedData.applicationData[serviceSelected.name].deployments[updatedData.applicationData[serviceSelected.name].deployments.yamlType].yamlString,
                            //'': applicationData[serviceSelected.name].volumes
                        }
                    );
                    setAppData(JSON.parse(JSON.stringify({ ...updatedData.applicationData[serviceSelected.name] })));
                    console.log("Updated data inside useEffect: ", updatedData);
                    setYamlData(updatedData.applicationData[serviceSelected.name].deployments[updatedData.applicationData[serviceSelected.name].deployments.yamlType].yamlString);
                }
                else {
                    form.setFieldsValue({
                        'tags': [{}],
                        'volumeList': [{}]
                    });
                    setAppData(JSON.parse(JSON.stringify({ ...initialApplicationData, })));
                    setYamlData(initialApplicationData.deployments[initialApplicationData.deployments.yamlType].yamlString);
                }
                setExecuteEnvs(executeEnvs => !executeEnvs);
                setDefaultValues(true);
            }
        }
        else if (!cloneDep) {
            console.log("Triggers", applicationData, serviceSelected);
            if (applicationData[serviceSelected.name]) {
                let vols = [];
                let tags = [];
                applicationData[serviceSelected.name].volumes.map((val) => {
                    if (val.volumeType === "Configmap") {
                        vols.push({
                            'configName': val.config.configName,
                            'createMountPath': val.config.createMountPath,
                            'createConfig': val.config.createConfig,
                        });
                    }
                    else if (val.volumeType === "Secrets") {
                        vols.push({
                            'secretsName': val.secrets.secretsName,
                            'createSecretsMountPath': val.secrets.createSecretsMountPath,
                            'createSecret': val.secrets.createSecretConfig,
                        });
                    }
                    else if (val.volumeType === "Storage") {
                        vols.push({
                            'storageName': val.storage.storageDes.storageName,
                            'type': "EBS",//val.storage.storageDes.type, //Fixed to EBS for now
                            'path': val.storage.storageDes.path,
                            'size': val.storage.storageDes.size,
                        });
                    }
                });
                applicationData[serviceSelected.name].deployments.tags?.map((val, idx) => {
                    tags.push({
                        'name': val.name,
                        'value': val.value
                    });
                });
                form.setFieldsValue(
                    {
                        'appName': applicationData[serviceSelected.name].basicDetails.appName,
                        'url': applicationData[serviceSelected.name].basicDetails.url,
                        'sourceType': applicationData[serviceSelected.name].basicDetails.sourceType,
                        'appSource': applicationData[serviceSelected.name].basicDetails.sourceType === "Git Repositories" ? "" : !applicationData[serviceSelected.name].basicDetails.appSource?.integration_type ? "PUBLIC" : (!applicationData[serviceSelected.name].basicDetails.appSource?.account_id ? `${applicationData[serviceSelected.name].basicDetails.appSource?.alias} - ${applicationData[serviceSelected.name].basicDetails.appSource?.integration_type}` : `${applicationData[serviceSelected.name].basicDetails.appSource?.account_id}${applicationData[serviceSelected.name].basicDetails.appSource?.alias ? `(${applicationData[serviceSelected.name].basicDetails.appSource?.alias})` : ``} / ${applicationData[serviceSelected.name].basicDetails.appSource?.region}`),
                        'imageRepo': applicationData[serviceSelected.name].basicDetails.imageRepo,
                        'imageTag': applicationData[serviceSelected.name].basicDetails.imageTag === "" ? null : applicationData[serviceSelected.name].basicDetails.imageTag,
                        'branch': applicationData[serviceSelected.name].basicDetails.branch === "" ? null : applicationData[serviceSelected.name].basicDetails.branch,
                        'requestCpu': applicationData[serviceSelected.name].deployments.resources.cpu.requestCpu,
                        'requestMem': applicationData[serviceSelected.name].deployments.resources.memory.requestMem,
                        'dockerFilePath': applicationData[serviceSelected.name].basicDetails.dockerFilePath,
                        'buildContext': applicationData[serviceSelected.name].basicDetails.buildContext,
                        // 'containerRegistryId': applicationData[serviceSelected.name].basicDetails.containerRegistryId.alias === "" ? null : applicationData[serviceSelected.name].basicDetails.containerRegistryId.alias,
                        'containerRegistryId': !applicationData[serviceSelected.name].basicDetails.containerRegistryId.integration_type ? null : (!applicationData[serviceSelected.name].basicDetails.containerRegistryId.account_id ? `${applicationData[serviceSelected.name].basicDetails.containerRegistryId.alias} - ${applicationData[serviceSelected.name].basicDetails.containerRegistryId.integration_type}` : `${applicationData[serviceSelected.name].basicDetails.containerRegistryId.account_id}${applicationData[serviceSelected.name].basicDetails.containerRegistryId.alias ? `(${applicationData[serviceSelected.name].basicDetails.containerRegistryId.alias})` : ``} / ${applicationData[serviceSelected.name].basicDetails.containerRegistryId.region}`),
                        'port': applicationData[serviceSelected.name].deployments.port,
                        'healthCheckPath': applicationData[serviceSelected.name].deployments.healthCheckPath,
                        'endpointType': applicationData[serviceSelected.name].deployments.endpointType === "" ? null : applicationData[serviceSelected.name].deployments.endpointType,
                        'requestCpu': applicationData[serviceSelected.name].deployments.resources.cpu.requestCpu,
                        'limitCpu': applicationData[serviceSelected.name].deployments.resources.cpu.limitCpu,
                        'requestMem': applicationData[serviceSelected.name].deployments.resources.memory.requestMem,
                        'limitMem': applicationData[serviceSelected.name].deployments.resources.memory.limitMem,
                        'minimumReplica': applicationData[serviceSelected.name].deployments.autoScaling.autoScalingData.minimumReplica,
                        'maximumReplica': applicationData[serviceSelected.name].deployments.autoScaling.autoScalingData.maximumReplica,
                        'cpuAuto': applicationData[serviceSelected.name].deployments.autoScaling.autoScalingData.autoScalingParameter.cpuAuto,
                        'memoryAuto': applicationData[serviceSelected.name].deployments.autoScaling.autoScalingData.autoScalingParameter.cpuAuto,
                        'nodeGroupName': !applicationData[serviceSelected.name].deployments.nodeGroupName ? null : applicationData[serviceSelected.name].deployments.nodeGroupName,
                        'volumeList': vols.length > 0 ? vols : [{}],
                        'tags': tags.length > 0 ? tags : [{}],
                        'serviceType': applicationData[serviceSelected.name].deployments.serviceType === "" ? null : applicationData[serviceSelected.name].deployments.serviceType,
                        'domainHostUrl': applicationData[serviceSelected.name].deployments.domainHostUrl,
                        'gPath': applicationData[serviceSelected.name].deployments.gatewayHttps.gPath,
                        'entryPointCommandQueues': applicationData[serviceSelected.name].deployments.taskSchedulers.entryPointCommandQueues,
                        'entryPointCommandCrons': applicationData[serviceSelected.name].deployments.taskSchedulers.entryPointCommandCrons,
                        'yamlData': applicationData[serviceSelected.name].deployments[applicationData[serviceSelected.name].deployments.yamlType].yamlString,
                        //'': applicationData[serviceSelected.name].volumes
                    }
                );
                console.log("Setting app data: ", form.getFieldValue(["yamlData"]), applicationData[serviceSelected.name].deployments[applicationData[serviceSelected.name].deployments.yamlType].yamlString);
                setAppData(JSON.parse(JSON.stringify({ ...applicationData[serviceSelected.name] })));
                setYamlData(applicationData[serviceSelected.name].deployments[applicationData[serviceSelected.name].deployments.yamlType].yamlString);
            }
            else {
                form.setFieldsValue({
                    'tags': [{}],
                    'volumeList': [{}],
                    'yamlData': initialApplicationData.deployments[initialApplicationData.deployments.yamlType].yamlString,
                });
                setAppData(JSON.parse(JSON.stringify({ ...initialApplicationData, })));
                setYamlData(initialApplicationData.deployments[initialApplicationData.deployments.yamlType].yamlString);
            }
            setExecuteEnvs(executeEnvs => !executeEnvs);
            setDefaultValues(true);
        }
        else {
            // if (cloneApplicationData[serviceSelected.name]) {
            //     setAppData(JSON.parse(JSON.stringify({ ...cloneApplicationData[serviceSelected.name], })));
            // }
            // else {
            //     setAppData(JSON.parse(JSON.stringify({ ...initialApplicationData, })));
            // }
            if (cloneFetched) {
                console.log("Clone app data: ", cloneApplicationData);
                if (cloneApplicationData[serviceSelected.name]) {
                    let vols = [];
                    let tags = [];
                    cloneApplicationData[serviceSelected.name].volumes.map((val) => {
                        if (val.volumeType === "Configmap") {
                            vols.push({
                                'configName': val.config.configName,
                                'createMountPath': val.config.createMountPath,
                                'createConfig': val.config.createConfig,
                            });
                        }
                        else if (val.volumeType === "Secrets") {
                            vols.push({
                                'secretsName': val.secrets.secretsName,
                                'createSecretsMountPath': val.secrets.createSecretsMountPath,
                                'createSecret': val.secrets.createSecretConfig,
                            });
                        }
                        else if (val.volumeType === "Storage") {
                            vols.push({
                                'storageName': val.storage.storageDes.storageName,
                                'type': "EBS",//val.storage.storageDes.type, //Fixed to EBS for now
                                'path': val.storage.storageDes.path,
                                'size': val.storage.storageDes.size,

                            });
                        }
                    });
                    console.log("Clone vols: ", vols);
                    cloneApplicationData[serviceSelected.name].deployments.tags.map((val, idx) => {
                        tags.push({
                            'name': val.name,
                            'value': val.value
                        });
                    });
                    form.setFieldsValue(
                        {
                            'appName': cloneApplicationData[serviceSelected.name].basicDetails.appName,
                            'url': cloneApplicationData[serviceSelected.name].basicDetails.url,
                            'sourceType': cloneApplicationData[serviceSelected.name].basicDetails.sourceType,
                            'appSource': cloneApplicationData[serviceSelected.name].basicDetails.sourceType === "Git Repositories" ? "" : !cloneApplicationData[serviceSelected.name].basicDetails.appSource.integration_type ? "PUBLIC" : (!cloneApplicationData[serviceSelected.name].basicDetails.appSource.account_id ? `${cloneApplicationData[serviceSelected.name].basicDetails.appSource.alias} - ${cloneApplicationData[serviceSelected.name].basicDetails.appSource.integration_type}` : `${cloneApplicationData[serviceSelected.name].basicDetails.appSource.account_id}${cloneApplicationData[serviceSelected.name].basicDetails.appSource.alias ? `(${cloneApplicationData[serviceSelected.name].basicDetails.appSource.alias})` : ``} / ${cloneApplicationData[serviceSelected.name].basicDetails.appSource.region}`),
                            'imageRepo': cloneApplicationData[serviceSelected.name].basicDetails.imageRepo,
                            'imageTag': cloneApplicationData[serviceSelected.name].basicDetails.imageTag === "" ? null : cloneApplicationData[serviceSelected.name].basicDetails.imageTag,
                            'branch': cloneApplicationData[serviceSelected.name].basicDetails.branch === "" ? null : cloneApplicationData[serviceSelected.name].basicDetails.branch,
                            'requestCpu': cloneApplicationData[serviceSelected.name].deployments.resources.cpu.requestCpu,
                            'requestMem': cloneApplicationData[serviceSelected.name].deployments.resources.memory.requestMem,
                            'dockerFilePath': cloneApplicationData[serviceSelected.name].basicDetails.dockerFilePath,
                            'buildContext': cloneApplicationData[serviceSelected.name].basicDetails.buildContext,
                            // 'containerRegistryId': applicationData[serviceSelected.name].basicDetails.containerRegistryId.alias === "" ? null : applicationData[serviceSelected.name].basicDetails.containerRegistryId.alias,
                            'containerRegistryId': !cloneApplicationData[serviceSelected.name].basicDetails.containerRegistryId.integration_type ? null : (!cloneApplicationData[serviceSelected.name].basicDetails.containerRegistryId.account_id ? `${cloneApplicationData[serviceSelected.name].basicDetails.containerRegistryId.alias} - ${cloneApplicationData[serviceSelected.name].basicDetails.containerRegistryId.integration_type}` : `${cloneApplicationData[serviceSelected.name].basicDetails.containerRegistryId.account_id}${cloneApplicationData[serviceSelected.name].basicDetails.containerRegistryId.alias ? `(${cloneApplicationData[serviceSelected.name].basicDetails.containerRegistryId.alias})` : ``} / ${cloneApplicationData[serviceSelected.name].basicDetails.containerRegistryId.region}`),
                            'port': cloneApplicationData[serviceSelected.name].deployments.port,
                            'healthCheckPath': cloneApplicationData[serviceSelected.name].deployments.healthCheckPath,
                            'endpointType': cloneApplicationData[serviceSelected.name].deployments.endpointType === "" ? null : cloneApplicationData[serviceSelected.name].deployments.endpointType,
                            'requestCpu': cloneApplicationData[serviceSelected.name].deployments.resources.cpu.requestCpu,
                            'limitCpu': cloneApplicationData[serviceSelected.name].deployments.resources.cpu.limitCpu,
                            'requestMem': cloneApplicationData[serviceSelected.name].deployments.resources.memory.requestMem,
                            'limitMem': cloneApplicationData[serviceSelected.name].deployments.resources.memory.limitMem,
                            'minimumReplica': cloneApplicationData[serviceSelected.name].deployments.autoScaling.autoScalingData.minimumReplica,
                            'maximumReplica': cloneApplicationData[serviceSelected.name].deployments.autoScaling.autoScalingData.maximumReplica,
                            'cpuAuto': cloneApplicationData[serviceSelected.name].deployments.autoScaling.autoScalingData.autoScalingParameter.cpuAuto,
                            'memoryAuto': cloneApplicationData[serviceSelected.name].deployments.autoScaling.autoScalingData.autoScalingParameter.cpuAuto,
                            'nodeGroupName': !cloneApplicationData[serviceSelected.name].deployments.nodeGroupName ? null : cloneApplicationData[serviceSelected.name].deployments.nodeGroupName,
                            'volumeList': vols.length > 0 ? vols : [{}],
                            'tags': tags.length > 0 ? tags : [{}],
                            'serviceType': cloneApplicationData[serviceSelected.name].deployments.serviceType === "" ? null : cloneApplicationData[serviceSelected.name].deployments.serviceType,
                            'domainHostUrl': cloneApplicationData[serviceSelected.name].deployments.domainHostUrl,
                            'gPath': cloneApplicationData[serviceSelected.name].deployments.gatewayHttps.gPath,
                            'entryPointCommandQueues': cloneApplicationData[serviceSelected.name].deployments.taskSchedulers.entryPointCommandQueues,
                            'entryPointCommandCrons': cloneApplicationData[serviceSelected.name].deployments.taskSchedulers.entryPointCommandCrons,
                            'yamlData': cloneApplicationData[serviceSelected.name].deployments[cloneApplicationData[serviceSelected.name].deployments.yamlType].yamlString,                            //'': applicationData[serviceSelected.name].volumes
                        }
                    );
                    console.log("Else entered", cloneApplicationData);
                    // setIsFormSet(true);
                    setAppData(JSON.parse(JSON.stringify({ ...cloneApplicationData[serviceSelected.name] })));
                    setYamlData(cloneApplicationData[serviceSelected.name].deployments[cloneApplicationData[serviceSelected.name].deployments.yamlType].yamlString);
                }
                else {
                    form.setFieldsValue({
                        'tags': [{}],
                        'volumeList': [{}]
                    });
                    setAppData(JSON.parse(JSON.stringify({ ...initialApplicationData, })));
                    setYamlData(initialApplicationData.deployments[initialApplicationData.deployments.yamlType].yamlString);
                }
                setExecuteEnvs(executeEnvs => !executeEnvs);
                setDefaultValues(true);
            }
        }
        //}
        return (() => {
            setAppData({});
        });
    }, [serviceSelected.name, cloneFetched, updateFetched]);

    // useEffect(() => {
    //     console.log("Forms: ", form);
    //     setAppData({ ...appData, form: form });
    // }, [form]);


    useEffect(() => {
        if (addDep) {
            console.log("App data in add dep: ", JSON.parse(JSON.stringify(addUpdatedData)), appData);
            setAddUpdatedData(addUpdatedData => ({
                ...addUpdatedData,
                applicationData: {
                    ...addUpdatedData.applicationData,
                    [serviceSelected.name]: JSON.parse(JSON.stringify({
                        ...appData,
                        basicDetails: {
                            ...appData.basicDetails,
                            serviceName: serviceSelected.name
                        }
                    }))
                }
            }));
            localStorage.setItem('addUpdatedData', JSON.stringify({
                ...addUpdatedData,
                applicationData: {
                    ...addUpdatedData.applicationData,
                    [serviceSelected.name]: JSON.parse(JSON.stringify({
                        ...appData,
                        basicDetails: {
                            ...appData.basicDetails,
                            serviceName: serviceSelected.name
                        }
                    }))
                }
            }));
            console.log("Local storage val: ", JSON.parse(localStorage.getItem('addUpdatedData')).applicationData);
            setCanFetch(true);
            deploymentForms[serviceSelected.name] = form;
            setDeploymentForms({ ...deploymentForms });
        }
        else if (updateDep) {
            if (updateFetched) {
                console.log("Setting the data: ", appData, updatedData.applicationData);
                localStorage.setItem('updatedData', JSON.stringify({
                    ...JSON.parse(localStorage.getItem('updatedData')),
                    applicationData: {
                        ...JSON.parse(localStorage.getItem('updatedData')).applicationData,
                        [serviceSelected.name]: JSON.parse(JSON.stringify({
                            ...appData,
                            basicDetails: {
                                ...appData.basicDetails,
                                serviceName: serviceSelected.name
                            }
                        }))
                    }
                }));
                setUpdatedData(updatedData => ({
                    ...updatedData,
                    applicationData: {
                        ...updatedData.applicationData,
                        [serviceSelected.name]: JSON.parse(JSON.stringify({
                            ...appData,
                            basicDetails: {
                                ...appData.basicDetails,
                                serviceName: serviceSelected.name
                            }
                        }))
                    }
                }));
                setCanFetch(true);
                deploymentForms[serviceSelected.name] = form;
                setDeploymentForms({ ...deploymentForms });
            }
        }
        else if (!cloneDep) {
            console.log("Appdata from envs: ", appData, applicationData, serviceSelected);
            localStorage.setItem('app-data', JSON.stringify({
                ...JSON.parse(localStorage.getItem('app-data')),
                [serviceSelected.name]: JSON.parse(JSON.stringify({ ...appData }))
            }));
            setApplicationData(applicationData => ({
                ...applicationData,
                [serviceSelected.name]: JSON.parse(JSON.stringify({ ...appData }))
            }));
            setCanFetch(true);
            deploymentForms[serviceSelected.name] = form;
            setDeploymentForms({ ...deploymentForms });
        }
        //setIsForm(true);
    }, [appData, updateFetched]);

    useEffect(() => {
        if (cloneDep) {
            if (cloneFetched) {
                localStorage.setItem('cloneAppData', JSON.stringify({
                    ...cloneApplicationData,
                    [serviceSelected.name]: JSON.parse(JSON.stringify({
                        ...appData, basicDetails: {
                            ...appData.basicDetails,
                            serviceName: serviceSelected.name
                        }
                    }))
                }));
                setCloneApplicationData(cloneApplicationData => ({
                    ...cloneApplicationData,
                    [serviceSelected.name]: JSON.parse(JSON.stringify({
                        ...appData, basicDetails: {
                            ...appData.basicDetails,
                            serviceName: serviceSelected.name
                        }
                    }))
                }));
                setCanFetch(true);
                console.log("Deployment form: ", deploymentForms[serviceSelected.name] === form);
                deploymentForms[serviceSelected.name] = form;
                setDeploymentForms({ ...deploymentForms });
                setIsForm(true);
            }
        }
    }, [appData, cloneFetched]);

    useEffect(() => {
        console.log("AppData being set: ", appData, serviceSelected, defaultValues, cloneDep, updateDep);
        if (!cloneDep && !updateDep) {
            if (Object.keys(serviceSelected).length > 0 && defaultValues) {
                console.log("Setting.....");
                let myCpu = 0, myRam = 0;
                let project = !addDep ? selectedProject : addUpdatedData.selectedProject;
                project.services.map((val) => {
                    if (val.name === serviceSelected.name) {
                        myCpu = val.configuration.cpu;
                        myRam = val.configuration.ram;
                    }
                });
                console.log("Appdata.basicDetails: ", appData)
                let appSourceVal = serviceSelected.configuration.app_source_type === 1 ? "" : serviceSelected.configuration.container_registry_type === 0 ? "PUBLIC" : serviceSelected.configuration.container_registry_id ? containerRegs.find((val) => {
                    return ((val.id === serviceSelected.configuration.container_registry_id))
                }) : "";
                console.log("App source: ", appSourceVal);
                form.setFieldsValue(
                    {
                        'appName': appData.basicDetails.appName === "" ? serviceSelected.name : appData.basicDetails.appName,
                        'url': serviceSelected.configuration.repo_url,
                        'sourceType': serviceSelected.configuration.app_source_type === 0 ? "Container Registry" : "Git Repositories",
                        'imageRepo': serviceSelected.configuration.repository_name,
                        'appSource': appSourceVal,
                        'requestCpu': appData.deployments.resources.cpu.requestCpu === "" ? myCpu : appData.deployments.resources.cpu.requestCpu,
                        'requestMem': appData.deployments.resources.memory.requestMem === "" ? myRam : appData.deployments.resources.memory.requestMem,
                        'dockerFilePath': appData.basicDetails.dockerFilePath === "" ? "Dockerfile" : appData.basicDetails.dockerFilePath,
                        'buildContext': appData.basicDetails.buildContext,
                        'containerRegistryType': appData.basicDetails.containerRegistryType
                    }
                );
                console.log("Form data: ", form.getFieldsValue());
                setAppData(appData => ({
                    ...appData,

                    serviceSelectedId: serviceSelected.id ? serviceSelected.id : serviceSelected.service_id,
                    serviceEnabled: serviceSelected.isActive,//serviceEnabled[serviceSelected.name] === true ? true : false,
                    //source: serviceSelected.configuration.source,
                    //description: serviceSelected.description,

                    //dockerFilePath: serviceSelected.configuration.dockerFilePath,
                    basicDetails: {
                        ...appData.basicDetails,
                        appName: appData.basicDetails.appName === "" ? serviceSelected.name : appData.basicDetails.appName,
                        serviceName: serviceSelected.name,
                        url: serviceSelected.configuration.repo_url,
                        sourceType: serviceSelected.configuration.app_source_type === 0 ? "Container Registry" : "Git Repositories",
                        imageRepo: serviceSelected.configuration.repository_name,
                        containerRegistryType: serviceSelected.configuration.container_registry_type,
                        appSource: appSourceVal
                    },
                    deployments: {
                        ...appData.deployments,
                        domainHostName: '.' + depData.dns,
                        resources: {
                            ...appData.deployments.resources,
                            cpu: {
                                ...appData.deployments.resources.cpu,
                                requestCpu: appData.deployments.resources.cpu.requestCpu === "" ? myCpu : appData.deployments.resources.cpu.requestCpu,
                            },
                            memory: {
                                ...appData.deployments.resources.memory,
                                requestMem: appData.deployments.resources.memory.requestMem === "" ? myRam : appData.deployments.resources.memory.requestMem,
                            }
                        }
                    }
                }));
                console.log("form Appdata: ", appData);
            }
        }
        // else {
        //     setDefaultValues(false);
        // }
    }, [serviceSelected.name, serviceSelected.isActive, defaultValues]);


    const handlePrevious = (importantFields, currentIdx) => {
        // setImportantIdx(importantIdx => importantIdx - 1);
        if (currentIdx !== 0) {
            form.setFields([
                {
                    name: importantFields[currentIdx],
                    warnings: []
                },
                {
                    name: importantFields[currentIdx - 1],
                    warnings: warnings(false, false, importantFields, handlePrevious, handleNext, currentIdx - 1)
                }
            ]);

            warningsRef.current = [
                {
                    name: importantFields[currentIdx],
                    warnings: []
                },
                {
                    name: importantFields[currentIdx - 1],
                    warnings: warnings(false, false, importantFields, handlePrevious, handleNext, currentIdx - 1)
                }
            ];

            form.scrollToField(importantFields[currentIdx - 1], {
                behavior: "smooth",
                block: 'center',
                inline: 'center',
            });
        }
        else {
            form.setFields([{
                name: importantFields[currentIdx],
                warnings: []
            }]);
            warningsRef.current = [{
                name: importantFields[currentIdx],
                warnings: []
            }];
            setGenerateImpField(true);
            handlePrevBtn();
        }
        setGenerateImpField(true);
        setPrevBtn(true);
        setNextBtn(false);
    }

    const handleNext = (importantFields, currentIdx) => {
        // setImportantIdx(importantIdx => importantIdx + 1);
        if (currentIdx !== importantFields.length - 1) {
            form.setFields([
                {
                    name: importantFields[currentIdx],
                    warnings: []
                },
                {
                    name: importantFields[currentIdx + 1],
                    warnings: warnings(false, false, importantFields, handlePrevious, handleNext, currentIdx + 1)
                }
            ]);

            warningsRef.current = [
                {
                    name: importantFields[currentIdx],
                    warnings: []
                },
                {
                    name: importantFields[currentIdx + 1],
                    warnings: warnings(false, false, importantFields, handlePrevious, handleNext, currentIdx + 1)
                }
            ];

            form.scrollToField(importantFields[currentIdx + 1], {
                behavior: "smooth",
                block: 'center',
                inline: 'center',
            });
        }
        else {
            // handleSaveAndNextImp();
            if (keySelected === lastEnabledService) {
                form.setFields([{
                    name: importantFields[currentIdx],
                    warnings: []
                }]);
                warningsRef.current = [{
                    name: importantFields[currentIdx],
                    warnings: []
                }];
                buttonRef1.current.scrollIntoView({ behavior: 'smooth' });
            }
            else {
                handleSave();
            }
        }
        setPrevBtn(false);
        setNextBtn(true);
    }

    const generateImportantFields = () => {
        const importantFields = [];
        if (selectedEnv.cluster_unique_id !== cloneDeploymentData.clusterId && appData.basicDetails.sourceType === "Container Registry") {
            importantFields.push("containerRegistryId");
        }
        else if ("domainHostUrl" in form.getFieldsValue()) {
            importantFields.push("domainHostUrl");
        }

        if (importantFields.length > 0) {
            form.setFields([{
                name: importantFields[0],
                warnings: warnings(false, false, importantFields, handlePrevious, handleNext, 0)
            }]);

            warningsRef.current = [{
                name: importantFields[0],
                warnings: warnings(false, false, importantFields, handlePrevious, handleNext, 0)
            }];

            form.scrollToField(importantFields[0], {
                behavior: "smooth",
                block: 'center',
                inline: 'center',
            });
            setNextBtn(false);
            setPrevBtn(false);
        }
        else {
            if (nextBtn || !prevBtn) {
                if (keySelected !== lastEnabledService) {
                    handleSave();
                }
                else {
                    buttonRef1.current.scrollIntoView({ behavior: 'smooth' });
                }
            }
            else if (prevBtn) {
                setGenerateImpField(true);
                handlePrevBtn();
            }
        }
    }

    useEffect(() => {
        console.log("Generate imp field: ", generateImpField, keySelected);
        if (importantEnabled && serviceHighlighted.service_id === serviceSelected.service_id && cloneDep && generateImpField && defaultValues) {
            generateImportantFields();
        }
        else if (importantEnabled && serviceHighlighted.service_id === serviceSelected.service_id && cloneDep && generateImpField && defaultValues && !serviceSelected.isActive) {
            handleSaveAndNext();
        }
        else if (!importantEnabled) {
            const fields = form.getFieldsError().map(({ name, errors, warnings }) => ({
                name,
                errors,
                warnings: [], // Clear warnings only
            }));
            form.setFields(fields);
            warningsRef.current = fields;
        }
    }, [defaultValues, importantEnabled, serviceHighlighted.service_id, cloneDep, generateImpField]);

    useEffect(() => {
        let ctr = 0;
        appData.environments.forEach((val) => {
            if (val.error) {
                ctr++;
            }
        });
        setErrorVars(ctr);
    }, [appData.environments]);

    useEffect(() => {
        console.log("my yaml data: ", yamlData, appData.deployments.yamlType);
        setAppData(appData => ({
            ...appData,
            deployments: {
                ...appData.deployments,
                [appData.deployments.yamlType]: {
                    yamlString: yamlData
                }
            }
        }));
    }, [yamlData]);

    // useEffect(() => {
    //     let myTimeout = setTimeout(() => {
    //         if (appData?.deployments?.domainHostUrl && appData?.deployments?.domainHostUrl !== "") {
    //             if(!updateDep){
    //                 dnsCheck(appData?.deployments?.domainHostUrl, depData.dns, depData.hostedAccount, depData.recordType);
    //             }
    //         }
    //     }, 2000);

    //     return () => clearTimeout(myTimeout);

    // }, [appData?.deployments?.domainHostUrl]);

    const dnsCheck = (recordPrefix, zoneName, zoneId, dnsRecordType) => {
        setValidateDomainStatus("validating");
        api.post('/api/cluster/check-dns/', {
            "record_name": recordPrefix,
            "zone_id": zoneId,
            "dns_record_type": dnsRecordType,
            "zone_name": zoneName
        }).then((res) => {
            let data = res.data;
            if (data.status) {
                setDnsMsg({
                    error: false,
                    msg: data.data
                })
                setValidateDomainStatus("success");
            }
            else {
                setDnsMsg({
                    error: true,
                    msg: data.error_details
                });
                setValidateDomainStatus("error");
            }
        }).catch((err) => {
            console.log("Error");
            setValidateDomainStatus("error");
        });
    }

    const validateNameUnique = (rule, value, callback) => {
        let myVal = value.replace(/\s/g, '');
        if (myVal === "") {
            callback();
        }
        else {
            let myObj = {};
            if (addDep) {
                myObj = {
                    ...addUpdatedData.applicationData,
                    ...addUpdatedData.dataSourcesData,
                    ...addUpdatedData.mongoDataSourcesData,
                    ...addUpdatedData.postgresDataSourcesData,
                    ...addUpdatedData.redisData
                };
            }
            else if (updateDep) {
                myObj = {
                    ...updatedData.applicationData,
                    ...updatedData.dataSourcesData,
                    ...updatedData.mongoDataSourcesData,
                    ...updatedData.postgresDataSourcesData,
                    ...updatedData.redisData
                };
            }
            else if (!cloneDep) {
                myObj = {
                    ...applicationData,
                    ...dataSourcesData,
                    ...mongoDataSourcesData,
                    ...postgresDataSourcesData,
                    ...redisData
                };
            }
            else {
                myObj = {
                    ...cloneApplicationData,
                    ...cloneDataSourcesData,
                    ...cloneMongoDataSourcesData,
                    ...clonePostgresDataSourcesData,
                    ...cloneRedisData
                };
            }
            console.log("myObj: ", myObj, Object.keys(myObj));
            let resArr = Object.keys(myObj).filter((val) => {
                console.log("MyOBJ: ", myObj[val]);
                if (val !== serviceSelected.name && myObj[val].serviceEnabled) {
                    console.log("Value: ", myObj[val].displayName, myVal);
                    if ((myObj[val].basicDetails && myObj[val].basicDetails.appName && myObj[val].basicDetails.appName === myVal) || (myObj[val].displayName === myVal)) {
                        return val;
                    }
                    else {
                        console.log("Else entered", myObj[val]);
                        return false;
                    }
                }
                else {
                    console.log("same", myObj[val]);
                    return false;
                }
            });

            console.log("Res arr: ", resArr);
            if (resArr.length > 0) {
                console.log("Callback Called");
                callback("Service name should be unique.");
            }
            else {
                callback();
            }
        }
    }
    const handleSave = async () => {
        console.log("Check entered", form.getFieldsValue());
        let myError = {}, envError = false;
        // if (prevBtn) {
        //     handlePrevBtn();
        // }
        // else {
        appData.environments.forEach((val) => {
            if (val.error) {
                envError = true;
            }
        });
        if (envError) {
            toggleAlert("error", "Failed", "Please fill the missing fields in the env variables section for the enabled services.");
        }
        else {
            try {
                console.log("Getting validations: ", form.getFieldsError());
                await form.validateFields();
            }
            catch (error) {
                console.log("Error caught...", error);
                myError = error;
            }

            if (Object.keys(myError).length > 0) {
                setFormErrors({
                    ...formErrors,
                    [serviceSelected.name]: {
                        error: myError
                    }
                });
                if (!updateDep) {
                set_alertOptions(alertOptions => ({
                    status: true,
                    type: "error",
                    message: "Service validation failed.",
                    description: "Please fill all the fields for the selected service in order to proceed to the next service.",
                }));

                // opacity=0 after 10sec
                setTimeout(() => {
                    set_alertOptions(alertOptions => ({
                        tatus: false,
                        type: "error",
                        message: "Service validation failed",
                        description: "Please fill all the fields for the selected service in order to proceed to the next service.",
                    }));
                }, 5 * 1000);
            }
            else {
                set_alertOptions(alertOptions => ({
                    status: true,
                    type: "error",
                    message: "Service validation failed.",
                    description: "Please enter correct information for the service to proceed.",
                }));

                // opacity=0 after 10sec
                setTimeout(() => {
                    set_alertOptions(alertOptions => ({
                        tatus: false,
                        type: "error",
                        message: "Service validation failed",
                        description: "Please enter correct information for the service to proceed.",
                    }));
                }, 5 * 1000);
            }
            }
            else {
                delete formErrors[serviceSelected.name];
                setFormErrors({ ...formErrors });
                if (!generateImpField) {
                    targetRef.current.scrollIntoView({ behavior: 'smooth' });
                }
                console.log("Checks: ",);
                if (updateDep) {
                    setPrerequisite(true);
                    setIsSubmitted(true);
                    //handleServiceRedeploy();
                }
                // else if (nextBtn) {
                //     console.log("Next clicked: ", nextBtn);
                //     handleSaveAndNextImp();
                // }
                // else if(prevBtn) {
                //     handlePrevBtn();
                // }
                else {
                    setGenerateImpField(true);
                    handleSaveAndNext();
                }
            }
            //handleSaveAndNextImp();
        }
        // }
    }

    const showConfirm = () => {
        // confirm({
        //   title: 'Environment Variables Warning',
        //   //icon: <ExclamationCircleFilled />,
        //   content: 'This environment has some variables dependent on the services that are not getting deployed. Do you want to procced creating environment?',
        //   onOk() {
        //     console.log('OK');
        //     handleSubmit();
        //   },
        //   onCancel() {
        //     console.log('Cancel');
        //   },
        // });
        setOpenModel({
            modalType: 'CONFIRM_ACTION',
            modalProps: {
                data: "",
                title: "WARNING!",
                subTitle: `Some environment variables are associated with services that are not being deployed. Do you want to continue with the environment setup?`,
                cancelButton: "Cancel",
                saveButton: "OK",
                onConfirm: handleSubmit,
                onCancel: () => { console.log("onCancel") },
                columnId: null,
                className: "large-Box"
            },
        });
    };

    const handleNumberChange = name => value => {
        form.setFieldValue(name, value);
        if (name === "minimumReplica" || name === "maximumReplica") {
            setAppData(appData => ({
                ...appData,

                deployments: {
                    ...appData.deployments,
                    autoScaling: {
                        ...appData.deployments.autoScaling,
                        autoScalingData: {
                            ...appData.deployments.autoScaling.autoScalingData,
                            [name]: value
                        }
                    }
                }
            }));
        }
        else if (name === "cpuAuto" || name === "memoryAuto") {
            setAppData(appData => ({
                ...appData,

                deployments: {
                    ...appData.deployments,
                    autoScaling: {
                        ...appData.deployments.autoScaling,
                        autoScalingData: {
                            ...appData.deployments.autoScaling.autoScalingData,
                            autoScalingParameter: {
                                ...appData.deployments.autoScaling.autoScalingData.autoScalingParameter,
                                [name]: value
                            }
                        }
                    }
                }
            }));
        }
    }

    const handleChange = (e, idx) => {
        let val = (e.target.name === "entryPointCommandCrons" || e.target.name === "entryPointCommandQueues") ? e.target.value : e.target.value.replace(/\s/g, '');
        form.setFieldValue(e.target.name, val);
        if (e.target.name === "size" || e.target.name === "path" || e.target.name === "type" || e.target.name === "storageName") {
            appData.volumes[idx].storage.storageDes[e.target.name] = val;
            appData.volumes[idx].config = {
                configEnabled: false,
                configName: "",
                createConfig: "",
                createMountPath: "",
                configFileName: "",
            };
            appData.volumes[idx].secrets = {
                secretsEnabled: false,
                secretsName: "",
                createSecrets: "",
                createSecretsMountPath: "",
                secretsFileName: "",
            };
            setAppData({ ...appData, });
        }
        else if (e.target.name === "createConfig" || e.target.name === "createMountPath" || e.target.name === "configFileName" || e.target.name === "configName") {
            appData.volumes[idx].config[e.target.name] = val;
            appData.volumes[idx].storage = {
                createStorage: false,
                storageDes: {
                    storageName: "",
                    size: "",
                    type: "",
                    path: ""
                }
            };
            appData.volumes[idx].secrets = {
                secretsEnabled: false,
                secretsName: "",
                createSecrets: "",
                createSecretsMountPath: "",
                secretsFileName: "",
            };
            setAppData({ ...appData, });
        }
        else if (e.target.name === "createSecrets" || e.target.name === "createSecretsMountPath" || e.target.name === "secretsFileName" || e.target.name === "secretsName") {
            appData.volumes[idx].secrets[e.target.name] = val;
            appData.volumes[idx].storage = {
                createStorage: false,
                storageDes: {
                    storageName: "",
                    size: "",
                    type: "",
                    path: ""
                }
            };
            appData.volumes[idx].config = {
                configEnabled: false,
                configName: "",
                createConfig: "",
                createMountPath: "",
                configFileName: "",
            };

            setAppData({ ...appData, });
        }
        else if (e.target.name === "port" || e.target.name === "customDomain" || e.target.name === "domainHostUrl" || e.target.name === "domainHostName" || e.target.name === "healthCheckPath") {
            if (e.target.name === "domainHostUrl") {
                setAppData(appData => ({
                    ...appData,

                    deployments: {
                        ...appData.deployments,
                        [e.target.name]: val
                    }
                }));
                form.validateFields(['serviceType']);
                if (form.getFieldValue('domainHostUrl') !== "" && form.getFieldValue('gPath') !== "") {
                    form.validateFields(['gPath']);
                }
                setDnsMsg({
                    error: false,
                    msg: ""
                });
            }
            else {
                setAppData(appData => ({
                    ...appData,

                    deployments: {
                        ...appData.deployments,
                        [e.target.name]: val
                    }
                }));
            }
        }
        else if (e.target.name === "gPath" || e.target.name === "gPort" || e.target.name === "https") {
            setAppData(appData => ({
                ...appData,

                deployments: {
                    ...appData.deployments,
                    loadBalancer: {
                        ...appData.deployments.loadBalancer,
                        tcp: "",
                        udp: "",
                        lHttps: ""
                    },
                    gatewayHttps: {
                        ...appData.deployments.gatewayHttps,
                        [e.target.name]: val
                    }
                }
            }));
        }
        else if (e.target.name === "tcp" || e.target.name === "udp" || e.target.name === "lHttps") {
            setAppData(appData => ({
                ...appData,

                deployments: {
                    ...appData.deployments,
                    gatewayHttps: {
                        ...appData.deployments.gatewayHttps,
                        gPath: "",
                        gPort: "",
                        https: false
                    },
                    loadBalancer: {
                        ...appData.deployments.loadBalancer,
                        [e.target.name]: val
                    }
                }
            }));
        }
        else if (e.target.name === "spot" || e.target.name === "onDemand") {
            setAppData(appData => ({
                ...appData,

                deployments: {
                    ...appData.deployments,
                    instanceCapacityType: {
                        ...appData.deployments.instanceCapacityType,
                        [e.target.name]: val
                    }
                }
            }));
        }
        else if (e.target.name === "requestCpu" || e.target.name === "limitCpu" || e.target.name === "requestMem" || e.target.name === "limitMem") {
            if (e.target.name === "requestCpu" || e.target.name === "limitCpu") {
                if (e.target.name === "requestCpu") {
                    setAppData(appData => ({
                        ...appData,

                        deployments: {
                            ...appData.deployments,
                            resources: {
                                ...appData.deployments.resources,
                                cpu: {
                                    ...appData.deployments.resources.cpu,
                                    [e.target.name]: val,
                                    limitCpu: ""
                                }
                            }
                        }
                    }));
                    form.setFieldValue('limitCpu', "");
                }
                else {
                    setAppData(appData => ({
                        ...appData,

                        deployments: {
                            ...appData.deployments,
                            resources: {
                                ...appData.deployments.resources,
                                cpu: {
                                    ...appData.deployments.resources.cpu,
                                    [e.target.name]: val
                                }
                            }
                        }
                    }));
                }
            }
            else {
                if (e.target.name === "requestMem") {
                    setAppData(appData => ({
                        ...appData,

                        deployments: {
                            ...appData.deployments,
                            resources: {
                                ...appData.deployments.resources,
                                memory: {
                                    ...appData.deployments.resources.memory,
                                    [e.target.name]: val,
                                    limitMem: ""
                                }
                            }
                        }
                    }));
                    form.setFieldValue('limitMem', "");
                }
                else {
                    setAppData(appData => ({
                        ...appData,

                        deployments: {
                            ...appData.deployments,
                            resources: {
                                ...appData.deployments.resources,
                                memory: {
                                    ...appData.deployments.resources.memory,
                                    [e.target.name]: val
                                }
                            }
                        }
                    }));
                }
            }
        }

        else if ((e.target.name === "entryPointCommandCrons") || (e.target.name === "entryPointCommandQueues")) {
            setAppData(appData => ({
                ...appData,

                deployments: {
                    ...appData.deployments,
                    taskSchedulers: {
                        ...appData.deployments.taskSchedulers,
                        [e.target.name]: val
                    }
                }
            }));
        }

        else if ((e.target.name === "name" || e.target.name === "value") && allOptions.deployments) {
            console.log("Tags triggered: ", form.getFieldsValue().tags);
            appData.deployments.tags[idx][e.target.name] = val;
            const updatedItems = form.getFieldsValue().tags.map((item, ind) =>
                ind === idx ? { ...item, [e.target.name]: val } : item
            );
            form.setFieldValue('tags', updatedItems);
            if (e.target.name === "value") {
                console.log("Validating...",);
                form.validateFields([["tags", idx, "name"]]);
            }
            else {
                console.log("Validating value: ",);
                form.validateFields([["tags", idx, "value"]]);
            }
            setAppData(appData => ({
                ...appData,
                deployments: {
                    ...appData.deployments
                }
            }));
            setRemoveIcon(false);
            //form.validateFields();
        }
        else {
            setAppData(appData => ({
                ...appData,

                basicDetails: {
                    ...appData.basicDetails,
                    [e.target.name]: val
                }
            }));
        }
    }

    const handleSelectChange = (value, event, id) => {
        console.log("Out application data: ", applicationData, value);
        form.setFieldValue(event.name, value);
        //console.log("field value: ", form.getFieldValue(event.name));
        if (event.name === "branch" || event.name === "containerRegistryId" || event.name === "imageTag") {
            setAppData(appData => ({
                ...appData,
                basicDetails: {
                    ...appData.basicDetails,
                    [event.name]: value
                }
            }));
        }
        else if (event.name === "volumeType") {
            console.log("In application data: ", applicationData);
            appData.volumes[id] = {
                volumeType: value,
                config: {
                    configEnabled: false,
                    createConfig: "",
                    createMountPath: "",
                    configFileName: "",
                    configName: ""
                },
                storage: {
                    createStorage: false,
                    storageDes: {
                        storageName: "",
                        size: "",
                        type: "EBS", //Fixed to EBS for now
                        path: ""
                    }
                },
                secrets: {
                    secretsEnabled: false,
                    createSecrets: "",
                    createSecretsMountPath: "",
                    secretsFileName: "",
                    secretsName: ""
                }
            }

            setAppData({
                ...appData,
                volumes: appData.volumes
            });
            console.log("Updated items: ", form.getFieldsValue());
            const updatedItems = form.getFieldsValue().volumeList.map((item, idx) =>
                idx === id ? { ...item, type: "EBS" } : item
            );
            form.setFieldsValue({ volumeList: updatedItems });
            setRemoveVolumeIcon(false);
            //setAppData({ ...appData });
        }
        else if (event.name === "type") {
            console.log("type entered");
            appData.volumes[id].storage.storageDes.type = value;
            setAppData({ ...appData });
        }
        else {
            console.log("Inside else");
            if (event.name === "endpointType") {
                setAppData(appData => ({
                    ...appData,

                    deployments: {
                        ...appData.deployments,
                        [event.name]: value,
                        serviceType: "",
                        customDomain: false,
                        domainHostUrl: "",
                        domainHostName: "",
                    }
                }));
                form.setFieldsValue({
                    'serviceType': null,
                    'domainHostUrl': "",
                    'domainHostName': ""
                });
            }
            else if (event.name === "serviceType") {
                setAppData(appData => ({
                    ...appData,

                    deployments: {
                        ...appData.deployments,
                        [event.name]: value,
                        customDomain: false,
                        domainHostUrl: "",
                        domainHostName: "",
                        gatewayHttps: {
                            ...appData.deployments.gatewayHttps,
                            gPath: ""
                        }
                    }
                }));
                form.setFieldsValue({
                    'domainHostUrl': "",
                    'domainHostName': "",
                    'gPath': ""
                });
            }
            // else if (event.name === "nodeGroupName") {
            //     setAppData(appData => ({
            //         ...appData,

            //         deployments: {
            //             ...appData.deployments,
            //             nodeGroupName: value,
            //             nodeGroupType: event['data-alias'],
            //         }
            //     }));
            // }
            else {
                setAppData(appData => ({
                    ...appData,

                    deployments: {
                        ...appData.deployments,
                        [event.name]: value
                    }
                }));
            }
        }
    };

    const handleMultiSelectChange = (value, event, id) => {
        console.log("Out application data: ", applicationData);
        console.log("value, event :", value, event);
        form.setFieldValue("nodegroupName", value);

        let listOfNods = [];
        event.map((temp, ind) => {
            listOfNods.push({
                nodegroup_name: temp.value,
                nodegroup_type: temp["data-alias"],
                nodegroup_unique_id: temp["key"],
                arch_type: temp["data-arch"]
            })
        })

        console.log("listOfNods :", listOfNods);
        setAppData(appData => ({
            ...appData,

            deployments: {
                ...appData.deployments,
                nodeGroupName: value,
                // nodeGroupType: event['data-alias'],
                listOfNodegroups: listOfNods,
            }
        }));
    }

    const filterBranches = (input, option) => (option?.value.toLowerCase() ?? '').includes(input.toLowerCase());
    const switchChange = (checked, event) => {
        console.log(event);
    }

    const onFinish = () => {
        setSubmitAll({
            ...submitAll,
            [serviceSelected.name]: true,
        });
        message.success('Submit success!');
    };
    const onFinishFailed = () => {
        message.error('Submit failed!');
    };

    // const validateName = (rule, value, callback) => {
    //     if (/\s/.test(value)) {
    //         callback('App Name should not contain spaces');
    //     } else {
    //         callback();
    //     }
    // }

    const validateMaxReplica = (rule, value, callback) => {
        if (appData.deployments.autoScaling.enabled && form.getFieldsValue(["minimumReplica"]).minimumReplica > value) {
            callback('Maximum replica should be greater than minimum replica.');
        }
        else {
            callback();
        }
    }

    const validateResources = (rule, value, callback) => {
        console.log("Rule: ", rule, appData.deployments.resources.cpu.requestCpu !== "");
        let val = value;
        if (value) {
            val = value.toString().replace(/\s/g, '');
        }
        if (isNaN(val)) {
            callback("This field should contain only numbers.");
        }
        else if (parseInt(val) < parseInt("100")) {
            if (rule.field === "requestCpu") {
                callback("Minimum CPU should be at least 100m.");
            }
            else if (rule.field === "limitCpu") {
                callback("Maximum CPU limit should be at least 100m.");
            }
            else if (rule.field === "requestMem") {
                callback("Minimum Memory should be at least 100m.");
            }
            else {
                callback("Maximum Memory should be at least 100mi.");
            }
        }
        else {
            if (rule.field === "limitCpu" && parseInt(appData.deployments.resources.cpu.requestCpu) > parseInt(val)) {
                console.log("Enters", rule, appData.deployments.resources.cpu.requestCpu, value);
                callback("Maximum CPU should be greater than Minimum CPU.");
            }
            else if (rule.field === "limitMem" && parseInt(appData.deployments.resources.memory.requestMem) > parseInt(val)) {
                callback("Maximum Memory should be greater than Minimum Memory.");
            }
            else {
                callback();
            }
        }
    }

    const validatePercentage = (rule, value, callback) => {
        let val = value;
        // if(value && value !== ""){
        //     val = value.replace(/\s/g, '');
        // }
        if (appData.deployments.autoScaling.enabled) {
            if (rule.field === "cpuAuto") {
                if (isNaN(val)) {
                    callback("Please enter a valid percentage.");
                }
                else if (parseInt(val) <= 0) {
                    callback("CPU Percentage should be greater than 0.");
                }
                else {
                    callback();
                }
            }
            else {
                if (isNaN(val)) {
                    callback("Please enter a valid percentage.");
                }
                else if (parseInt(val) <= 0) {
                    callback("Memory Percentage should be greater than 0.");
                }
                else {
                    callback();
                }
            }
        }
        else {
            callback();
        }
    }

    const validatePort = (rule, value, callback) => {
        let val = value.toString().replace(/\s/g, '');
        if (!val || val === "") {
            callback("Please enter service port!");
        }
        if (isNaN(val)) {
            callback("Please enter a valid port.");
        }
        else {
            if (parseInt(value) < 0 || parseInt(val) > 65535) {
                callback("Port should have a value between 0 to 65535");
            }
            else {
                callback();
            }
        }
    }

    const validatePrefix = (rule, value, callback) => {
        // if(prefixCheck){
        //     callback("Combination of Domain host url and prefix should be unique");
        // }
        // else{
        //     callback();
        // }
        let myVal = value.replace(/\s/g, '');
        if (appData.deployments.domainHostUrl !== "" && myVal) {
            // let test = false;
            // Object.keys(applicationData).some((val) => {
            //     if (val !== serviceSelected.name) {
            //         if (applicationData[val].deployments.domainHostUrl + applicationData[val].deployments.gatewayHttps.gPath ===
            //             appData.deployments.domainHostUrl + value) {
            //                 callback("Combination of Domain host url and prefix should be unique");
            //                 test = true;
            //                 return true;
            //         }
            //         // else{
            //         //     callback();
            //         // }
            //     }
            //     // else{
            //     //     callback();
            //     // }
            // });

            // if(!test){
            //     callback();
            // }
            console.log("Application inside path: ", applicationData);
            console.log("appappData inside path: ", appData);
            let resArr = [];
            if (addDep) {
                resArr = Object.keys(addUpdatedData.applicationData).filter((val) => {
                    if (val !== serviceSelected.name) {
                        return addUpdatedData.applicationData[val].serviceEnabled && addUpdatedData.applicationData[val].deployments.domainHostUrl + addUpdatedData.applicationData[val].deployments.gatewayHttps.gPath === appData.deployments.domainHostUrl + myVal;
                    }
                    else {
                        return false;
                    }
                });
            }
            else if (updateDep) {
                resArr = Object.keys(updatedData.applicationData).filter((val) => {
                    if (val !== serviceSelected.name) {
                        return updatedData.applicationData[val].serviceEnabled && updatedData.applicationData[val].deployments.domainHostUrl + updatedData.applicationData[val].deployments.gatewayHttps.gPath === appData.deployments.domainHostUrl + myVal;
                    }
                    else {
                        return false;
                    }
                });
            }
            else if (!cloneDep) {
                resArr = Object.keys(applicationData).filter((val) => {
                    if (val !== serviceSelected.name) {
                        return applicationData[val].serviceEnabled && applicationData[val].deployments.domainHostUrl + applicationData[val].deployments.gatewayHttps.gPath === appData.deployments.domainHostUrl + myVal;
                    }
                    else {
                        return false;
                    }
                });
            }
            else {
                resArr = Object.keys(cloneApplicationData).filter((val) => {
                    if (val !== serviceSelected.name) {
                        return cloneApplicationData[val].serviceEnabled && cloneApplicationData[val].deployments.domainHostUrl + cloneApplicationData[val].deployments.gatewayHttps.gPath === appData.deployments.domainHostUrl + myVal;
                    }
                    else {
                        return false;
                    }
                });
            }
            console.log("Resarr: ", resArr);
            if (resArr.length >= 1) {
                callback("In an environment, combination of domain and path for all the enabled applications should be unique.");
            }
            else {
                callback();
            }
        }
        else {
            callback();
        }
    }

    const validateType = (rule, value, callback) => {
        let myVal = value;//value.replace(/\s/g, '');
        if (appData.deployments.domainHostUrl !== "") {
            // let resArr = Object.keys(applicationData).filter((val) => {
            //     return applicationData[val].serviceEnabled === true;
            // });
            let resArr = [];
            if (addDep) {
                resArr = Object.entries(addUpdatedData.applicationData);
            }
            else if (updateDep) {
                resArr = Object.entries(updatedData.applicationData);
            }
            else if (!cloneDep) {
                resArr = Object.entries(applicationData);
            }
            else {
                resArr = Object.entries(cloneApplicationData);
            }
            // if (resArr.includes(appData.deployments.domainHostUrl)){
            //     if(!Object.keys(applicationData).map((val) => {
            //         return applicationData[val].serviceEnabled && applicationData[val].deployments.domainHostUrl;
            //     }).includes(appData.deployments.domainHostUrl)){

            //     }
            // }
            console.log("Res arr: ", resArr);
            let finalArr = resArr.filter((val) => {
                if (val[0] !== serviceSelected.name) {
                    console.log("My vals diff: ", val[1].deployments.serviceType, myVal);
                    if (val[1].deployments.serviceType !== myVal &&
                        val[1].deployments.domainHostUrl === appData.deployments.domainHostUrl && val[1].serviceEnabled) {
                        return val;
                    }
                    else {
                        return false;
                    }
                }
                else {
                    return false;
                }
            });
            console.log("Final arr: ", finalArr);
            if (finalArr.length > 0) {
                callback("In an environment, service type for all the enabled applications should be the same if those applications have same domain host.");
            }
            else {
                callback();
            }
        }
        else {
            callback();
        }
    }

    const validateSize = (rule, value, callback) => {
        if (!value || value === "") {
            callback();
        }
        else if (isNaN(value)) {
            callback("Please enter a valid storage size.");
        }
        if (parseInt(value) < 5) {
            callback("Storage size should be at least 5GB.");
        }
        else {
            callback();
        }
    }

    function toggleAlert(type, message, description) {
        console.log("Toggle alert called: ");
        set_alertOptions(alertOptions => ({
            status: true,
            type: type,
            message: message,
            description: description,
        }));

        // opacity=0 after 10sec
        setTimeout(() => {
            set_alertOptions(alertOptions => ({
                status: false,
                type: type,
                message: message,
                description: description,
            }));
        }, 5 * 1000);
    }

    const handleCancelButton = () => {
        let envId = location.pathname.split('/').slice(-1)[0];
        setAppData({});
        navigate(`/environments/environment/${envId}`);
    }

    const validateNodegroups = (rule, value, callback) => {
        console.log("enters node validate: ", appData.deployments.listOfNodegroups);
        let checkAmd = false, checkArm = false;
        appData.deployments.listOfNodegroups.forEach((val) => {
            if (val.arch_type === "amazon_linux_2_amd") {
                checkAmd = true;
            }
            else if (val.arch_type === "amazon_linux_2_arm") {
                checkArm = true;
            }
        });
        if (checkAmd && checkArm) {
            callback("The selected nodegroups should not be a combination of AMD and ARM types");
        }
        else {
            callback();
        }
    }

    let items = [
        {
            name: 'Deployment',
            info: "Deployment manages stateless application workloads by overseeing a set of Pods."
        },
        {
            name: 'StatefulSet',
            info: "StatefulSet runs a group of Pods with persistent identities, ideal for applications requiring stable storage or unique network identities."
        },
        {
            name: 'Job & CronJob',
            info: "Manages one-time or scheduled tasks, with Job running tasks to completion and CronJob running them on a schedule."
        },
    ];

    items = useMemo(() => {
        return items.map((val, idx) => {
            return {
                label: <div style={{ width: "343px", display: "flex", flexDirection: "column", ...detailSubTextStyle, color: (val.name === appData.deployments.yamlType) ? "#4D99EF" : "#5E6366", marginLeft: "0", borderBottom: idx !== items.length - 1 ? "1px solid #eeeef2" : "", padding: "10px 15px" }} onClick={() => {
                    setAppData(appData => ({
                        ...appData,
                        deployments: {
                            ...appData.deployments,
                            yamlType: val.name,
                        }
                    }));
                    console.log("Setting yaml data: ", appData.deployments);
                    setYamlData(appData.deployments[val.name].yamlString);
                    console.log("My val: ", val, appData.deployments[val.name].yamlString);
                    form.setFieldValue("yamlData", appData.deployments[val.name].yamlString);
                    console.log("Setting form: ", form.getFieldsValue(["yamlData"]));
                }}>
                    <div>
                        <img src={val.name === appData.deployments.yamlType ? fileIconSelected : fileIcon} style={{ height: "15px", width: "15pxpx" }} alt="icon" />
                        {/* {val.service_name === "Add new service" && <img src={plusIcon} style={{ height: "14px", width: "14px" }} alt="icon" />} */}
                        <span style={{ marginLeft: "10px", transform: "translateY(3px)", fontSize: "12px", height: "20px" }}>{val.name}</span>
                    </div>
                    <p style={{ marginTop: "10px", fontSize: "10px", fontWeight: "300", fontFamily: "Nunito Sans" }}>{val.info}</p>
                </div>,
                key: val.name
            };
        })
    }, [appData.deployments]);

    const handleFieldsChange = (changedFields) => {
        console.log("Fields changed: ", changedFields);
        const fieldsToUpdate = changedFields.map((val) => {
            const fieldName = val.name[0]; // 'name' is an array; we use the first item
            // const warningMessage = warnings[fieldName]; // Access stored warning for the field
            console.log("Getting val: ", val, warningsRef);
      
            return {
              name: fieldName,
              warnings: warningsRef.current.find(x => x.name === fieldName)?.warnings, // Apply warning if exists
              errors: val.errors, // Retain any validation errors
            };
          });
        console.log("Fields to update: ", fieldsToUpdate);
        form.setFields(fieldsToUpdate);
    }

    return (
        // <Scrollbars style={{ width: '100%', height: 'calc(100vh - 350px' }}>
        <div ref={targetRef} style={serviceSelected.isActive ? { width: "100%", padding: "10px 20px 0 10px" } : { width: "100%", padding: "10px 20px 0 10px", opacity: "0.5", pointerEvents: "none" }}>
            {console.log("Current form data: ", form.getFieldsValue())}
            <Alert
                message={alertOptions.message}
                description={alertOptions.description}
                type={alertOptions.type}
                showIcon
                style={alertOptions.status ? marginsBot : fadeMarginBot}
            />
            <Form form={form} name={serviceSelected.name} onFinish={handleSubmit} onFieldsChange={handleFieldsChange} onFinishFailed={onFinishFailed}>
                <div style={{ marginBottom: "40px", cursor: "pointer" }} onClick={() => {
                    let options = {
                        ...allOptions,
                        basicDetails: !allOptions.basicDetails,
                    };
                    setAllOptions({
                        ...options,
                    });
                    setApplicationKey(0);
                }}>
                    <Box title={<div style={{ display: "flex", justifyContent: "space-between", width: "100%" }} >
                        <p style={innerTitleStyle}> Basic Details </p>
                        <img src={!allOptions.basicDetails ? downIcon : upIcon} style={{ width: "12px", height: "16px" }} />
                    </div>} titleStyle={innerTitleStyle} style={{ borderRadius: "20px" }}>
                        {allOptions.basicDetails && <div style={{ marginTop: "20px", cursor: "default" }} onClick={(e) => {
                            e.stopPropagation();
                        }}>
                            <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "20px" }}>
                                <div style={fieldStyles}>
                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        <Label style={{ ...labelStyle }}> App Name* </Label>
                                        <CustomInfoTooltip title={"App name is 63 characters or fewer, uses lowercase alphanumeric characters, starts and ends with an alphanumeric character, and contains no spaces."} placement="bottomLeft" />
                                        {/* <Tooltip title={"App name is 63 characters or fewer, uses lowercase alphanumeric characters, starts and ends with an alphanumeric character, and contains no spaces."} placement="bottomLeft">
                                            <InfoCircleFilled style={{ transform: "translateY(-4px)", color: "#8896A1" }} />
                                        </Tooltip> */}
                                    </div>
                                    <Form.Item
                                        name="appName"
                                        rules={[
                                            {
                                                validator: validateName
                                            },
                                            {
                                                validator: validateNameUnique
                                            }
                                        ]}
                                    >
                                        <Input placeholder="Enter name" disabled={updateDep} style={inputStyle} value={appData.basicDetails?.name} name="appName" onChange={handleChange} />
                                    </Form.Item>
                                </div>

                                <div style={fieldStyles}>
                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        <Label style={{ ...labelStyle }}> Application Source Type </Label>
                                        {/* {!serviceSelected.configuration.is_owner && <CustomInfoTooltip title={"You are not the repo owner"} placement="bottomLeft" />} */}
                                    </div>
                                    <Form.Item
                                        name="sourceType"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please enter a url!',
                                            },
                                        ]}
                                    >

                                        <Input placeholder="Select Application source type" disabled style={inputStyle} value={appData.basicDetails.sourceType} name="sourceType" />
                                    </Form.Item>
                                </div>

                                {/* {appData.basicDetails.sourceType === "Container Registry" && <div style={fieldStyles}>
                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        <Label style={{ ...labelStyle }}> Image Repo* </Label>
                                    </div>
                                    <Form.Item
                                        name="imageRepo"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please enter a url!',
                                            },
                                        ]}
                                    >

                                        <Input placeholder="Select Image Repo" style={inputStyle} disabled value={appData.basicDetails.imageRepo} name="imageRepo" />
                                    </Form.Item>
                                </div>} */}
                            </div>
                            <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "20px" }}>
                                {appData.basicDetails.sourceType === "Git Repositories" && <>
                                    <div style={fieldStyles}>
                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                            <Label style={{ ...labelStyle }}> Git Source* </Label>
                                            {!serviceSelected.configuration.is_owner && <CustomInfoTooltip title={"You are not the repo owner"} placement="bottomLeft" />}
                                        </div>
                                        <Form.Item
                                            name="url"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please enter a url!',
                                                },
                                            ]}
                                        >

                                            <Input placeholder="Enter URL" style={inputStyle} disabled value={appData.basicDetails.url} name="url" onChange={handleChange} />
                                        </Form.Item>
                                    </div>
                                    <div style={fieldStyles}>
                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                            <Label style={{ ...labelStyle }}> Branch* </Label>
                                            <CustomInfoTooltip title={deploymentHelpChecks.application.branch} placement="bottomLeft" />
                                        </div>
                                        <Form.Item
                                            name="branch"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please select a branch!',
                                                },
                                            ]}
                                        >
                                            <Select style={{ width: '100%' }}
                                                disabled={updateDep}
                                                showSearch
                                                //filterOption={filterBranches}
                                                filterSort={(optionA, optionB) => customSortOptions(optionA, optionB, searchValue)} // Custom sorting function
                                                filterOption={(inputValue, option) => customFilterOption(inputValue, option)} // Custom filtering function
                                                //getPopupContainer={(triggerNode) => triggerNode.parentNode}
                                                placeholder="Select branch"
                                                value={appData.basicDetails.branch === "" ? null : appData.basicDetails.branch}
                                                onSearch={(value) => {
                                                    setSearchValue(value);
                                                }}
                                                onChange={handleSelectChange}
                                                notFoundContent={branchLoad ? <Spin size="small" /> : null}
                                            >
                                                {repoList}
                                            </Select>
                                        </Form.Item>
                                    </div>
                                </>}
                                {appData.basicDetails.sourceType === "Container Registry" && <>
                                    <div style={fieldStyles}>
                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                            <Label style={{ ...labelStyle }}> App Source* </Label>
                                            {/* <CustomInfoTooltip title={"Build context for this build is the root directory of the git repo."} placement="bottomLeft" /> */}
                                        </div>
                                        <Form.Item
                                            name="appSource"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please enter app source!',
                                                },
                                            ]}
                                        >
                                            <div style={{ height: "44px" }}>
                                                <Input placeholder="Enter app source" disabled style={inputStyle} value={appData.basicDetails.appSource === "PUBLIC" ? appData.basicDetails.appSource : !appData.basicDetails.appSource?.account_id ? `${appData.basicDetails.appSource?.alias} - ${appData.basicDetails.appSource?.integration_type}` : `${appData.basicDetails.appSource?.account_id}${appData.basicDetails.appSource?.alias ? `(${appData.basicDetails.appSource?.alias})` : ``} / ${appData.basicDetails.appSource?.region}`} name="appSource" />
                                            </div>
                                        </Form.Item>
                                    </div>
                                    <div style={fieldStyles}>
                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                            <Label style={{ ...labelStyle }}> Image Repo* </Label>
                                            {/* <CustomInfoTooltip title={deploymentHelpChecks.application.containerRegistryId} placement="bottomLeft" /> */}
                                        </div>
                                        <Form.Item
                                            name="imageRepo"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please select an image repo!',
                                                },
                                            ]}
                                        >
                                            <Input disabled placeholder="Select Image repo" style={inputStyle} value={appData.basicDetails.imageRepo} />
                                        </Form.Item>
                                    </div>
                                </>}
                                {/* <div style={fieldStyles}>
                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        <Label style={{ ...labelStyle }}> Docker File Path* </Label>
                                        <CustomInfoTooltip title={"Build context for this build is the root directory of the git repo."} placement="bottomLeft" />
                                    </div>
                                    <Form.Item
                                        name="dockerFilePath"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please enter docker file path!',
                                            },
                                        ]}
                                    >
                                        <div style={{ height: "44px" }}>
                                            <Input placeholder="Enter path" addonBefore='./' value={appData.basicDetails.dockerFilePath} name="dockerFilePath" onChange={handleChange} />
                                        </div>
                                    </Form.Item>
                                </div> */}
                            </div>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                {appData.basicDetails.sourceType === "Git Repositories" && <>

                                    {/* <div style={fieldStyles}>
                            <Label style={labelStyle}> Build Context </Label>
                            <Input placeholder="Enter Context" style={inputStyle} value={appData.basicDetails.context} name="context" onChange={handleChange} />
                        </div> */}
                                    <div style={fieldStyles}>
                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                            <Label style={{ ...labelStyle }}> Docker File Path* </Label>
                                            <CustomInfoTooltip title={"Specify the path to the Dockerfile within your repository. This file contains the instructions to build your application's Docker image."} placement="bottomLeft" />
                                        </div>
                                        <Form.Item
                                            name="dockerFilePath"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please enter docker file path!',
                                                },
                                            ]}
                                        >
                                            <div style={{ height: "44px" }}>
                                                <Input placeholder="Enter path" addonBefore='./' value={appData.basicDetails.dockerFilePath} name="dockerFilePath" onChange={handleChange} disabled={updateDep} />
                                            </div>
                                        </Form.Item>
                                    </div>
                                    {/* commented for build context */}
                                    <div style={fieldStyles}>
                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                            <Label style={{ ...labelStyle }}> Build Context </Label>
                                            <CustomInfoTooltip title={"Please specify the path where the docker build command will be executed."} placement="bottomLeft" />
                                        </div>
                                        <Form.Item
                                            name="buildContext"
                                        // rules={[
                                        //     {
                                        //         required: true,
                                        //         message: 'Please enter build context!',
                                        //     },
                                        // ]}
                                        >
                                            <div style={{ height: "44px" }}>
                                                <Input placeholder="Enter path" addonBefore='./' value={appData.basicDetails.buildContext} name="buildContext" onChange={handleChange} disabled={updateDep} />
                                            </div>
                                        </Form.Item>
                                    </div>
                                    {/* Added for removal of build context */}
                                    {/* <div style={fieldStyles}>
                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                            <Label style={{ ...labelStyle }}> Container Registry* </Label>
                                            <CustomInfoTooltip title={deploymentHelpChecks.application.containerRegistryId} placement="bottomLeft" />
                                        </div>
                                        <Form.Item
                                            name="containerRegistryId"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please select a container registry!',
                                                },
                                            ]}
                                        >
                                            <Select style={{ width: '100%' }} getPopupContainer={(triggerNode) => triggerNode.parentNode} placeholder="Select container registry" value={!appData.basicDetails.containerRegistryId ? null : !appData.basicDetails.containerRegistryId.account_id ? `${appData.basicDetails.containerRegistryId.alias} - ${appData.basicDetails.containerRegistryId.integration_type}` : `${appData.basicDetails.containerRegistryId.account_id}${appData.basicDetails.containerRegistryId.alias ? `(${appData.basicDetails.containerRegistryId.alias})` : ``} / ${appData.basicDetails.containerRegistryId.region}`} onChange={(value, event) => {
                                                // console.log('event["data-region"] :', event["data-region"]);
                                                // console.log('value :', value);
                                                containerRegs.map((val) => {
                                                    if ((val.alias === value) || (event["data-region"] === val.region)) {
                                                        // console.log('val :', val);
                                                        setAppData(appData => ({
                                                            ...appData,
                                                            basicDetails: {
                                                                ...appData.basicDetails,
                                                                containerRegistryId: val
                                                            }
                                                        }));
                                                    }
                                                })
                                            }}>
                                                {containerRegistryList}
                                            </Select>
                                        </Form.Item>
                                    </div> */}
                                </>}
                                {appData.basicDetails.sourceType === "Container Registry" && <>
                                    <div style={fieldStyles}>
                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                            <Label style={{ ...labelStyle }}> Image Tag* </Label>
                                            {/* <CustomInfoTooltip title={"Build context for this build is the root directory of the git repo."} placement="bottomLeft" /> */}
                                        </div>
                                        <Form.Item
                                            name="imageTag"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please enter image tag!',
                                                },
                                            ]}
                                        >
                                            <div style={{ height: "44px" }}>
                                                {appData.basicDetails.appSource !== "PUBLIC" ?
                                                    <Select style={{ width: '100%' }}
                                                        showSearch
                                                        filterSort={(optionA, optionB) => customSortOptions(optionA, optionB, searchValue)} // Custom sorting function
                                                        filterOption={(inputValue, option) => customFilterOption(inputValue, option)} // Custom filtering function

                                                        optionLabelProp={appData.basicDetails.imageTag}
                                                        onSearch={(value) => {
                                                            console.log("Enters search", value);
                                                            setSearchValue(value);
                                                        }}
                                                        getPopupContainer={(triggerNode) => triggerNode.parentNode}
                                                        placeholder="Select image tag" value={(!appData.basicDetails.imageTag || appData.basicDetails.imageTag === "") ? null : appData.basicDetails.imageTag}
                                                        onChange={handleSelectChange}
                                                        disabled={updateDep}
                                                    >
                                                        {imageTagList}
                                                    </Select> :
                                                    <Input placeholder="Enter image tag" name="imageTag" value={appData.basicDetails.imageTag} onChange={handleChange} style={{ ...inputStyle }} />
                                                }
                                            </div>
                                        </Form.Item>
                                    </div>
                                </>}
                            </div>
                            {appData.basicDetails.sourceType === "Git Repositories" && <div style={{ display: "flex", justifyContent: "space-between", marginTop: "20px" }}>
                                {/* Commented for build context */}
                                <div style={fieldStyles}>
                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        <Label style={{ ...labelStyle }}> Container Registry* </Label>
                                        <CustomInfoTooltip title={deploymentHelpChecks.application.containerRegistryId} placement="bottomLeft" />
                                    </div>
                                    <Form.Item
                                        name="containerRegistryId"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please select a container registry!',
                                            },
                                        ]}
                                    >
                                        <Select style={{ width: '100%' }} getPopupContainer={(triggerNode) => triggerNode.parentNode} placeholder="Select container registry" value={!appData.basicDetails.containerRegistryId ? null : !appData.basicDetails.containerRegistryId.account_id ? `${appData.basicDetails.containerRegistryId.alias} - ${appData.basicDetails.containerRegistryId.integration_type}` : `${appData.basicDetails.containerRegistryId.account_id}${appData.basicDetails.containerRegistryId.alias ? `(${appData.basicDetails.containerRegistryId.alias})` : ``} / ${appData.basicDetails.containerRegistryId.region}`} onChange={(value, event) => {
                                            // console.log('event["data-region"] :', event["data-region"]);
                                            // console.log('value :', value);
                                            containerRegs.map((val) => {
                                                if ((val.alias === value) || (event["data-region"] === val.region)) {
                                                    // console.log('val :', val);
                                                    setAppData(appData => ({
                                                        ...appData,
                                                        basicDetails: {
                                                            ...appData.basicDetails,
                                                            containerRegistryId: val
                                                        }
                                                    }));
                                                }
                                            })
                                        }} disabled={updateDep}>
                                            {containerRegistryList}
                                        </Select>
                                    </Form.Item>
                                </div>
                                <div style={{ display: "flex", justifyContent: "space-between", width: "47%", height: "fit-content" }}>
                                    <div style={{ display: "flex" }}>
                                        <Label style={{ ...labelStyle }}> Enable CI/CD </Label>
                                        <CustomInfoTooltip title={deploymentHelpChecks.application.enableAutoTrigger} placement="bottomLeft" style={{ transform: "translateY(-2px)", marginLeft: "10px" }} />
                                    </div>
                                    {/* {<Input placeholder="Enter Variable here" style={inputStyle} value={appData.containerRegistryId} name="dockerFilePath" onChange={handleChange} />} */}
                                    <Switch style={{ backgroundColor: appData.basicDetails.enableAutoTrigger && "#132f44" }} disabled={updateDep} checked={appData.basicDetails.enableAutoTrigger} onChange={(checked) => {
                                        setAppData(appData => ({
                                            ...appData,
                                            basicDetails: {
                                                ...appData.basicDetails,
                                                enableAutoTrigger: checked
                                            }
                                        }));
                                    }} />
                                </div>
                            </div>}
                            {/* <div style={{ display: "flex", justifyContent: "flex-end" }}>
                        <Button style={{ marginRight: "10px", border: "1px solid #132f44", color: "#132f44", height: "20px", fontSize: "16px", fontFamily: "Nunito Sans", fontWeight: "600" }}> Cancel </Button>
                        <Button style={{ background: "#132f44", color: "white", height: "20px", border: "1px solid #132f44", fontSize: "16px", fontFamily: "Nunito Sans", fontWeight: "600" }} onClick={() => {
                            handleSaveAndNext(1);
                        }}> Save & Next</Button>
                    </div> */}
                        </div>}
                        {error && error.status && <p className="isoHelperText">  {error.msg} </p>}
                    </Box>
                </div>
                <div style={{ marginBottom: "40px", cursor: "pointer" }} onClick={() => {
                    let options = {
                        ...allOptions,
                        envVars: !allOptions.envVars,
                    };
                    setAllOptions({
                        ...options,
                    });
                    setApplicationKey(1);
                }}>
                    <Box title={
                        <div style={{ display: "flex", justifyContent: "space-between" }} >
                            <div style={{ display: "flex" }}>
                                <p style={{ ...innerTitleStyle, marginRight: "20px" }}> Environment Variables </p>
                                {errorVars !== 0 && <div style={{ borderRadius: "100%", border: "1px solid red", backgroundColor: "red", color: "white", fontFamily: "Poppins", padding: "0 8px" }}>{errorVars}</div>}
                            </div>
                            <img src={!allOptions.envVars ? downIcon : upIcon} style={{ width: "12px", height: "16px" }} />
                        </div>
                    } titleStyle={innerTitleStyle} style={{ borderRadius: "20px" }}>
                        {executeEnvs && <EnvironmentVariablesDep valueFromDropdown={valueFromDropdown} services={services} executeEnvs={executeEnvs} setExecuteEnvs={setExecuteEnvs} serviceSelected={serviceSelected} appData={appData} setAppData={setAppData} setAppFromEnv={setAppFromEnv} allOptions={allOptions} />}
                    </Box>
                </div>
                <div style={{ marginBottom: "40px", cursor: "pointer" }} onClick={() => {
                    // setOptionsSelected(optionsSelected => ({
                    //     ...optionsSelected,
                    //     deployments: !optionsSelected.deployments
                    // }));

                    //commented for now to implement new design

                    // let options = {
                    //     // basicDetails: false,
                    //     // envVars: false,
                    //     ...allOptions,
                    //     deployments: !allOptions.deployments,
                    //     //volumes: false
                    // };

                    // setAllOptions({
                    //     ...options,
                    // });
                    // setApplicationKey(3);
                }}>
                    {/* <Box title={
                        <div style={{ display: "flex", justifyContent: "space-between", cursor: "pointer" }}>
                            <p> Deployment </p>
                            <img src={!allOptions.deployments ? downIcon : upIcon} style={{ width: "12px", height: "16px" }} />
                        </div>
                    } titleStyle={innerTitleStyle} style={{ borderRadius: "20px" }}> */}
                    {console.log("Getting app data and deployments option: ", form.getFieldsError(["yamlData"]), appData.deployments.deploymentOption)}
                    <Card
                        title={
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                {/* <p>Deployment</p> */}
                                {appData.deployments.deploymentOption === "yaml" ? <Dropdown menu={{ items, selectedKeys: [appData.deployments.yamlType], className: "yaml-dropdown", getPopupContainer: (triggerNode) => triggerNode.parentNode }} trigger={['click']}>
                                    <Button style={{ color: "#4d99ef", border: "1px solid #4d99ef", fontWeight: "600", fontSize: "14px", height: "27px", textAlign: "center", padding: "0 15px", borderRadius: "5px" }}>
                                        <div style={{ transform: "translateY(-1px)" }}>
                                            <img src={fileIconSelected} style={{ height: "20px", width: "20px", marginRight: "7px", transform: "translateY(-1px)" }} alt="icon" />
                                            <span>{appData.deployments.yamlType}</span>
                                            <RightOutlined style={{ fontSize: "9px", marginLeft: "5px", fontWeight: "600", transform: "translateY(-1px)" }} />
                                        </div>
                                    </Button>
                                </Dropdown> : <p>Deployment</p>}
                                <div>
                                    <Button name="visual" disabled={(updateDep && appData.deployments.deploymentOption === "yaml")} style={{ height: "25px", textAlign: "center", marginRight: "10px", color: appData.deployments.deploymentOption === "visual" ? "#6486EE" : "#5E6366", border: appData.deployments.deploymentOption === "visual" ? "1px solid #6484EE" : "1px solid #5E6366", padding: "0 15px" }} onClick={(e) => {
                                        setAppData(appData => ({
                                            ...appData,
                                            deployments: {
                                                ...appData.deployments,
                                                deploymentOption: "visual"
                                            }
                                        }));
                                    }}>Visual</Button>
                                    <Button name="yaml" disabled={appData.basicDetails.sourceType === "Container Registry" || (updateDep && appData.deployments.deploymentOption === "visual")} style={{ height: "25px", textAlign: "center", color: appData.deployments.deploymentOption === "yaml" ? "#6486EE" : "#5E6366", border: appData.deployments.deploymentOption === "yaml" ? "1px solid #6484EE" : "1px solid #5E6366", padding: "0 15px" }} onClick={(e) => {
                                        setAppData(appData => ({
                                            ...appData,
                                            deployments: {
                                                ...appData.deployments,
                                                deploymentOption: "yaml"
                                            }
                                        }));
                                    }}>YAML</Button>
                                </div>
                            </div>
                        }
                        className="custom-card"
                        style={{
                            border: form.getFieldsError(["yamlData"])[0]?.errors.length > 0 && "1px solid #ff4d4f !important"
                        }}
                    >
                        {appData.deployments.deploymentOption === "visual" && allOptions.deployments && <div style={{ marginTop: "20px", cursor: "default" }} onClick={(e) => {
                            e.stopPropagation();
                        }}>
                            <div style={{ display: "flex" }}>
                                <div style={{ borderRadius: "100%", backgroundColor: depColor.services ? "#132f44" : "lightgray", display: "flex", justifyContent: "center", alignItems: "center", padding: "0 12px", marginRight: "10px" }}><p style={{ fontFamily: "Poppins", fontSize: "22px", fontWeight: "bold", color: "white" }}>1</p></div>
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}><p style={{ ...headLabelStyle }}> Services </p></div>
                            </div>
                            <Box onClick={() => {
                                setDepColor({
                                    services: true,
                                    resources: false,
                                    taskSchedulers: false,
                                    tags: false
                                });
                            }} style={{ border: "1px solid #f3f3f3", borderRadius: "10px", padding: "20px 20px 0 20px", marginTop: "20px", backgroundColor: "#fbfbfb" }}>
                                <div style={{ display: 'flex', justifyContent: "space-between", marginBottom: "20px" }}>
                                    <div style={fieldStyles}>
                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                            <Label style={{ ...labelStyle }}> Service Port* </Label>
                                            <CustomInfoTooltip title={deploymentHelpChecks.application.port} placement="bottomLeft" />
                                        </div>
                                        <Form.Item
                                            name="port"
                                            rules={[
                                                {
                                                    validator: validatePort
                                                }
                                            ]}
                                        >
                                            <Input placeholder="Enter port" style={inputStyle} value={appData.deployments.port} name="port" onChange={handleChange} />
                                        </Form.Item>
                                    </div>
                                    <div style={fieldStyles}>
                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                            <Label style={{ ...labelStyle }}> Health Check Path </Label>
                                            <CustomInfoTooltip title={deploymentHelpChecks.application.healthCheckPath} placement="bottomLeft" />
                                        </div>
                                        {/* <Form.Item
                                        name="healthCheckPath"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please enter a health check path!',
                                            },
                                        ]}
                                    > */}
                                        <Input placeholder="Enter health check path" style={{ ...inputStyle, paddingTop: "5px", width: "100%" }} name="healthCheckPath" value={appData.deployments.healthCheckPath} onChange={handleChange} />
                                        {/* </Form.Item> */}
                                    </div>
                                </div>
                                <div style={{ display: 'flex', justifyContent: "space-between", marginBottom: "20px" }}>
                                    <div style={fieldStyles}>
                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                            <Label style={{ ...labelStyle }}> Endpoint Type* </Label>
                                            <CustomInfoTooltip title={deploymentHelpChecks.application.endpointType} placement="bottomLeft" />
                                        </div>
                                        {/* {<Input placeholder="Enter Type here" style={inputStyle} value={appData.deployments.port} name="type" onChange={handleChange} />} */}
                                        <Form.Item
                                            name="endpointType"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please select an endpoint type!',
                                                },
                                            ]}
                                        >
                                            <Select style={{ width: '100%' }} getPopupContainer={(triggerNode) => triggerNode.parentNode} placeholder="Select endpoint type" value={appData.deployments.endpointType === "" ? null : appData.deployments.endpointType} onChange={handleSelectChange}>
                                                {deploymentTypes}
                                            </Select>
                                        </Form.Item>
                                    </div>
                                    {console.log("Endpoint type: ", appData.deployments.endpointType)}
                                    {appData.deployments.endpointType && appData.deployments.endpointType !== "Cluster IP" && <div style={fieldStyles}>
                                        <Label style={labelStyle}> Service Type* </Label>
                                        {/* {<Input placeholder="Enter Type here" style={inputStyle} value={appData.deployments.port} name="type" onChange={handleChange} />} */}
                                        <Form.Item
                                            name="serviceType"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please select a service type!',
                                                },
                                                {
                                                    validator: validateType
                                                }
                                            ]}
                                        >
                                            <Select style={{ width: '100%' }} getPopupContainer={(triggerNode) => triggerNode.parentNode} placeholder="Select service type" value={appData.deployments.serviceType === "" ? null : appData.deployments.serviceType} onChange={handleSelectChange}>
                                                {serviceTypes}
                                            </Select>
                                        </Form.Item>
                                    </div>}
                                </div>
                                {appData.deployments.endpointType && appData.deployments.endpointType !== "Cluster IP" && appData.deployments.serviceType.includes("Existing Load Balancer") && <div style={{ display: "flex", justifyContent: "space-between" }}>
                                    <div style={fieldStyles}>
                                        <Label style={labelStyle}> Domain{appData.deployments.endpointType && appData.deployments.endpointType !== "Cluster IP" && appData.deployments.serviceType.includes("Existing Load Balancer") ? '*' : ''} </Label>
                                        <Form.Item
                                            name="domainHostUrl"
                                            rules={[
                                                {
                                                    required: appData.deployments.endpointType && appData.deployments.endpointType !== "Cluster IP" && appData.deployments.serviceType.includes("Existing Load Balancer"),
                                                    message: 'Please enter a Domain!',
                                                },
                                            ]}
                                        >
                                            <Input placeholder="Enter domain" style={inputStyle} value={appData.deployments.domainHostUrl} name="domainHostUrl" suffix={<div>
                                                {validateDomainStatus === "validating" && <span style={{ marginRight: "15px" }}><LoadingOutlined /></span>}
                                                <span>{"." + depData.dns} </span>
                                            </div>} onChange={handleChange} />
                                        </Form.Item>
                                        <p style={{ color: dnsMsg.error ? "red" : "green" }}> {dnsMsg.msg} </p>
                                    </div>
                                    <div style={fieldStyles}>
                                        <Label style={labelStyle}> Path{appData.deployments.endpointType && appData.deployments.endpointType !== "Cluster IP" && appData.deployments.serviceType.includes("Existing Load Balancer") ? '*' : ''} </Label>
                                        <Form.Item
                                            name="gPath"
                                            rules={[
                                                {
                                                    required: appData.deployments.endpointType && appData.deployments.endpointType !== "Cluster IP" && appData.deployments.serviceType.includes("Existing Load Balancer"),
                                                    message: 'Please select a path!',
                                                },
                                                {
                                                    validator: validatePrefix
                                                }
                                            ]}
                                        >
                                            <Input placeholder="Enter path" style={inputStyle} value={appData.deployments.gatewayHttps.gPath} name="gPath" onChange={handleChange} />
                                        </Form.Item>
                                    </div>
                                </div>}
                            </Box>

                            {appData.deployments.serviceType === "Gateway HTTPS" && (
                                <div style={{ padding: "0 0 0 20px" }}>
                                    {/* <Label style={labelStyle}> Custom Domain </Label>
                        <Switch style={{ backgroundColor: appData.deployments.customDomain && "#132f44" }} checked={appData.deployments.customDomain} onChange={(checked) => {
                            setSwitchState(switchState => ({
                                ...switchState,
                                customDomain: checked ? "true" : "false"
                            }));
                            setAppData(appData => ({
                                ...appData,
                                deployments: {
                                    ...appData.deployments,
                                    customDomain: checked
                                }
                            }));
                        }} /> */}
                                    {/* <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "20px" }}>
                                <div style={fieldStyles}>
                                    <Label style={subLabelStyle}> Domain </Label>
                                    <Input placeholder="Enter name here" style={inputStyle} value={appData.deployments.domainHostUrl} name="domainHostUrl" suffix={appData.deployments.domainHostName} onChange={handleChange} />
                                </div>
                                <div style={fieldStyles}>
                                    <Label style={subLabelStyle}> Path </Label>
                                    <Input placeholder="Enter here" style={inputStyle} value={appData.deployments.gatewayHttps.gPath} name="gPath" onChange={handleChange} />
                                </div>
                            </div>

                            <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "20px" }}>
                                <div style={fieldStyles}>
                                    <Label style={subLabelStyle}> Port </Label>
                                    <Input placeholder="Enter" style={inputStyle} value={appData.deployments.gatewayHttps.gPort} name="gPort" onChange={handleChange} />
                                </div>
                                <div style={fieldStyles}>
                                    <div style={{ width: "50%", display: "flex", justifyContent: "space-between" }}>
                                        <Label style={subLabelStyle}> Https </Label>
                                        <Switch style={{ backgroundColor: appData.deployments.gatewayHttps.https && "#132f44" }} checked={appData.deployments.gatewayHttps.https} onChange={(checked) => {
                                            setAppData(appData => ({
                                                ...appData,
                                                deployments: {
                                                    ...appData.deployments,
                                                    gatewayHttps: {
                                                        ...appData.deployments.gatewayHttps,
                                                        https: checked
                                                    }
                                                }
                                            }));
                                        }} />
                                    </div>
                                </div>
                            </div> */}
                                </div>)}
                            {/* {appData.deployments.serviceType === "Load Balancer TCP" && (
                        <div style={{ padding: "0 0 0 20px" }}>
                            <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "20px" }}>
                                <div style={fieldStyles}>
                                    <Label style={subLabelStyle}> Load Balancer TCP </Label>
                                    <Input placeholder="Enter name here" style={inputStyle} value={appData.deployments.loadBalancer.tcp} name="tcp" onChange={handleChange} />
                                </div>
                                <div style={fieldStyles}>
                                    <Label style={subLabelStyle}> Load Balancer UDP </Label>
                                    <Input placeholder="Enter here" style={inputStyle} value={appData.deployments.loadBalancer.udp} name="udp" onChange={handleChange} />
                                </div>
                            </div>
                            <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "20px" }}>
                                <div style={fieldStyles}>
                                    <Label style={subLabelStyle}> Gateway HTTPS </Label>
                                    <Input placeholder="Enter" style={inputStyle} value={appData.deployments.loadBalancer.lHttps} name="lHttps" onChange={handleChange} />
                                </div>
                            </div>
                        </div>
                    )} */}
                            {/* <div style={switchState.customDomain === "false" ? { ...subDivStyle, pointerEvents: "none", opacity: "0.5", marginBottom: "20px" } : { ...subDivStyle, marginBottom: "20px" }} aria-disabled={switchState.customDomain === "false" ? true : false}>
                        <Label style={subLabelStyle}> Domain Host URL </Label>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <Input placeholder="Enter Prefix" style={{ ...inputStyle, width: "48%" }} value={appData.deployments.domainHostUrl} name="domainHostUrl" onChange={handleChange} />
                            <Input placeholder="Host Url" style={{ ...inputStyle, width: "48%" }} value={appData.deployments.domainHostName} name="domainHostName" onChange={handleChange} />
                        </div>
                    </div> */}
                            <div style={{ marginBottom: "20px" }}>
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <div style={{ display: "flex", marginTop: "15px" }}>
                                        <div style={{ borderRadius: "100%", backgroundColor: depColor.resources ? "#132f44" : "lightgray", display: "flex", justifyContent: "center", alignItems: "center", padding: "0 10px", marginRight: "10px" }}><p style={{ fontFamily: "Poppins", fontSize: "22px", fontWeight: "bold", color: "white" }}>2</p></div>
                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}><p style={{ ...headLabelStyle }}> Resources </p></div>
                                    </div>
                                    <Box onClick={() => {
                                        setDepColor({
                                            services: false,
                                            resources: true,
                                            taskSchedulers: false,
                                            tags: false
                                        });
                                    }} style={{ ...subDivStyle, marginTop: "20px", marginBottom: "20px", padding: "20px 20px 0 20px", border: "1px solid #f3f3f3", borderRadius: "10px", marginTop: "20px", backgroundColor: "#fbfbfb" }}>
                                        {/* <Label style={headLabelStyle}> CPU </Label> */}
                                        <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "20px" }}>
                                            <div style={fieldStyles}>
                                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                    <Label style={{ ...subLabelStyle }}> Minimum CPU* </Label>
                                                    <CustomInfoTooltip title={deploymentHelpChecks.application.requestCpu} placement="bottomLeft" />
                                                </div>
                                                <Form.Item
                                                    name="requestCpu"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please enter minimum cpu!',
                                                        },
                                                        {
                                                            validator: validateResources
                                                        }
                                                    ]}
                                                >
                                                    <Input placeholder="Enter minimum" style={inputStyle} value={appData.deployments.resources && appData.deployments.resources.cpu.requestCpu} name="requestCpu" suffix="m" onChange={handleChange} />
                                                </Form.Item>
                                            </div>
                                            <div style={fieldStyles}>
                                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                    <Label style={{ ...subLabelStyle }}> Maximum CPU* </Label>
                                                    <CustomInfoTooltip title={deploymentHelpChecks.application.limitCpu} placement="bottomLeft" />
                                                </div>
                                                <Form.Item
                                                    name="limitCpu"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please enter maximum cpu!',
                                                        },
                                                        {
                                                            validator: validateResources
                                                        }
                                                    ]}
                                                >
                                                    <Input placeholder="Enter maximum" style={inputStyle} value={appData.deployments.resources && appData.deployments.resources.cpu.limitCpu} name="limitCpu" suffix="m" onChange={handleChange} />
                                                </Form.Item>
                                            </div>
                                        </div>
                                        {/* <Label style={headLabelStyle}> Memory </Label> */}
                                        <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "20px" }}>
                                            <div style={fieldStyles}>
                                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                    <Label style={{ ...subLabelStyle }}> Minimum Memory* </Label>
                                                    <CustomInfoTooltip title={deploymentHelpChecks.application.requestMem} placement="bottomLeft" />
                                                </div>
                                                <Form.Item
                                                    name="requestMem"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please enter minimum memory!',
                                                        },
                                                        {
                                                            validator: validateResources
                                                        }
                                                    ]}
                                                >
                                                    <Input placeholder="Enter minimum" style={inputStyle} value={appData.deployments.resources && appData.deployments.resources.memory.requestMem} name="requestMem" suffix="mi" onChange={handleChange} />
                                                </Form.Item>
                                            </div>
                                            <div style={fieldStyles}>
                                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                    <Label style={{ ...subLabelStyle }}> Maximum Memory* </Label>
                                                    <CustomInfoTooltip title={deploymentHelpChecks.application.limitMem} placement="bottomLeft" />
                                                </div>
                                                <Form.Item
                                                    name="limitMem"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please enter maximum memory!',
                                                        },
                                                        {
                                                            validator: validateResources
                                                        }
                                                    ]}
                                                >
                                                    <Input placeholder="Enter maximum" style={inputStyle} value={appData.deployments.resources && appData.deployments.resources.memory.limitMem} name="limitMem" suffix="mi" onChange={handleChange} />
                                                </Form.Item>
                                            </div>
                                        </div>
                                        <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "20px" }}>
                                            <div style={fieldStyles}>
                                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                    <Label style={{ ...subLabelStyle, marginLeft: "10px" }}> Minimum Replica* </Label>
                                                    <CustomInfoTooltip title={deploymentHelpChecks.application.minimumReplica} placement="bottomLeft" />
                                                </div>
                                                <Form.Item
                                                    name="minimumReplica"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please enter minimum replica!',
                                                        },
                                                    ]}
                                                >
                                                    <InputNumber min={1} max={100000} placeholder="Choose" style={{ ...inputStyle, paddingTop: "5px", width: "100%" }} value={appData.deployments.autoScaling && appData.deployments.autoScaling.autoScalingData.minimumReplica} onChange={handleNumberChange("minimumReplica")} />
                                                </Form.Item>
                                            </div>
                                            <div style={fieldStyles}>
                                                <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                        <Label style={{ ...subLabelStyle }}> Nodegroup Name* </Label>
                                                        <CustomInfoTooltip title={deploymentHelpChecks.application.nodegroupName} placement="bottomLeft" style={{ marginLeft: "10px" }} />
                                                    </div>
                                                    {refreshNodegroups ? <RefreshLoader src={refreshIcon} /> : <img src={refreshIcon} onClick={() => {
                                                        setRefreshNodegroups(true);
                                                        setAfterRefresh(true);
                                                    }} />}
                                                </div>
                                                <Form.Item
                                                    name="nodeGroupName"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please select a nodegroup!',
                                                        },
                                                        {
                                                            validator: validateNodegroups
                                                        }
                                                    ]}
                                                >
                                                    <Select
                                                        mode="multiple"
                                                        placeholder="Select nodegroup name"
                                                        value={appData.deployments.nodeGroupName === "" ? null : appData.deployments.nodeGroupName}
                                                        onChange={handleMultiSelectChange}
                                                        getPopupContainer={(triggerNode) => triggerNode.parentNode}
                                                    >
                                                        {nodeGroups}
                                                    </Select>

                                                    {/* <Select
                                                    mode="multiple"
                                                    name="naz"
                                                    style={{ width: '100%' }}
                                                    placeholder={myClusterData.integration === 1 ? "Select availablity zones" : "Select Zones"}
                                                    defaultValue={myClusterData.prod.naz}
                                                    onChange={handleMultiSelectChange}
                                                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                                                >
                                                    {fetchingClusterPrerequisites ? 
                                                    <Option disabled>
                                                        <div style={{width: "inherit", display: "flex", justifyContent: "center", alignItems: "center"}}>
                                                        <Spin size="small" />
                                                        </div>
                                                    </Option>
                                                    :
                                                    optionAzs
                                                    }
                                                </Select> */}
                                                </Form.Item>
                                            </div>
                                        </div>
                                        <div style={{ width: "47%", display: "flex", justifyContent: "space-between", marginBottom: "25px" }}>
                                            {console.log("Auto scaling: ", appData.deployments.autoScaling)}
                                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                <Label style={{ ...headLabelStyle }}> Auto Scaling </Label>
                                                <CustomInfoTooltip title={deploymentHelpChecks.application.autoScaling} placement="bottomLeft" style={{ marginLeft: "10px" }} />
                                            </div>
                                            <Switch style={{ backgroundColor: appData.deployments.autoScaling.enabled && "#132f44" }} checked={appData.deployments.autoScaling.enabled} onChange={(checked) => {
                                                // setSwitchState(switchState => ({
                                                //     ...switchState,
                                                //     autoScaling: checked ? "true" : "false"
                                                // }));
                                                setAppData(appData => ({
                                                    ...appData,

                                                    deployments: {
                                                        ...appData.deployments,
                                                        autoScaling: {
                                                            ...appData.deployments.autoScaling,
                                                            enabled: checked,
                                                            maximumReplica: '',
                                                            cpuAuto: '',
                                                            memoryAuto: ''
                                                        }
                                                    }
                                                }));
                                                form.setFieldsValue({
                                                    'maximumReplica': '',
                                                    'cpuAuto': '',
                                                    'memoryAuto': ''
                                                });
                                            }} />
                                        </div>
                                        <div>
                                            {console.log("auto scaling: ", appData.deployments.autoScaling)}
                                            <div style={!appData.deployments.autoScaling.enabled ? { pointerEvents: "none", opacity: "0.5" } : {}} aria-disabled={!appData.deployments.autoScaling.enabled}>
                                                <div style={{ ...fieldStyles, display: "flex", marginBottom: "20px", justifyContent: "space-between" }}>
                                                    <div>
                                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                            <Label style={{ ...subLabelStyle }}> Maximum Replica{appData.deployments.autoScaling.enabled ? '*' : ''} </Label>
                                                            <CustomInfoTooltip title={deploymentHelpChecks.application.maximumReplica} placement="bottomLeft" />
                                                        </div>
                                                        <Form.Item
                                                            name="maximumReplica"
                                                            rules={[
                                                                {
                                                                    required: appData.deployments.autoScaling.enabled,
                                                                    message: 'Please enter maximum replicas!',
                                                                },
                                                                {
                                                                    validator: validateMaxReplica
                                                                }
                                                            ]}
                                                        >
                                                            <InputNumber min={1} max={100000} placeholder="Choose" style={{ ...inputStyle, paddingTop: "5px", width: "100%" }} value={appData.deployments.autoScaling.autoScalingData.maximumReplica} onChange={handleNumberChange("maximumReplica")} />
                                                        </Form.Item>
                                                    </div>
                                                </div>
                                                <div style={{ display: "flex", flexDirection: "column" }}>
                                                    <div style={{ display: "flex" }}>
                                                        <Label style={{ ...subLabelStyle }}> Auto Scaling Parameter </Label>
                                                        <CustomInfoTooltip title={deploymentHelpChecks.application.autoScalingParam} placement="bottomLeft" style={{ marginLeft: "10px" }} />
                                                    </div>
                                                    <div>
                                                        <div style={{ display: "flex", justifyContent: "space-between", marginTop: "20px" }}>
                                                            <div style={fieldStyles}>
                                                                <Label style={subLabelStyle}> CPU(%){appData.deployments.autoScaling.enabled ? '*' : ''} </Label>
                                                                <Form.Item
                                                                    name="cpuAuto"
                                                                    rules={[
                                                                        {
                                                                            required: appData.deployments.autoScaling.enabled,
                                                                            message: 'Please enter CPU percentage!',
                                                                        },
                                                                        {
                                                                            validator: validatePercentage
                                                                        }
                                                                    ]}
                                                                >
                                                                    <InputNumber min={0} max={100} placeholder="70" style={{ ...inputStyle, paddingTop: "5px", width: "100%" }} value={appData.deployments.autoScaling.autoScalingData.autoScalingParameter.cpuAuto} onChange={handleNumberChange("cpuAuto")} />
                                                                </Form.Item>
                                                            </div>
                                                            <div style={fieldStyles}>
                                                                <Label style={subLabelStyle}> RAM(%){appData.deployments.autoScaling.enabled ? '*' : ''} </Label>
                                                                <Form.Item
                                                                    name="memoryAuto"
                                                                    rules={[
                                                                        {
                                                                            required: appData.deployments.autoScaling.enabled,
                                                                            message: 'Please enter RAM paercentage!',
                                                                        },
                                                                        {
                                                                            validator: validatePercentage
                                                                        }
                                                                    ]}
                                                                >
                                                                    <InputNumber min={0} max={100} placeholder="70" style={{ ...inputStyle, paddingTop: "5px", width: "100%" }} value={appData.deployments.autoScaling.autoScalingData.autoScalingParameter.memoryAuto} onChange={handleNumberChange("memoryAuto")} />
                                                                </Form.Item>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {/* <div style={{ display: "flex", justifyContent: "space-between" }}>
                                    <div style={fieldStyles}>
                                        <Label style={subLabelStyle}> CPU </Label>
                                        <InputNumber min={0} max={100000} placeholder="Enter value" style={{ ...inputStyle, paddingTop: "5px", width: "100%" }} value={appData.deployments.autoScaling.autoScalingData.autoScalingParameter.cpuAuto} onChange={handleNumberChange("cpuAuto")} />
                                    </div>
                                    <div style={fieldStyles}>
                                        <Label style={subLabelStyle}> Memory </Label>
                                        <InputNumber min={0} max={100000} placeholder="Enter value" style={{ ...inputStyle, paddingTop: "5px", width: "100%" }} value={appData.deployments.autoScaling.autoScalingData.autoScalingParameter.memoryAuto} onChange={handleNumberChange("memoryAuto")} />
                                    </div>
                                </div> */}
                                                </div>
                                            </div>
                                        </div>
                                        {/* <Label style={subLabelStyle}> Server Preference </Label>
                                <div style={{ display: "flex", width: "48%", marginBottom: "20px" }}>
                                    
                                    <Select style={{ width: '100%' }} placeholder="Select" value={appData.deployments.serverPreference === "" ? null : appData.deployments.serverPreference} onChange={handleSelectChange}>
                                        {servicePreferences}
                                    </Select>
                                </div> */}
                                        {/* <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "20px" }}>
                                    <div style={{ border: "1px solid #D0D5DD", borderRadius: "10px", display: "flex", flexDirection: "column", width: "100%", padding: "20px" }}>
                                        <div style={{ display: "flex", justifyContent: "space-between", width: "90%" }}>
                                            <div></div>
                                            <div style={{ display: "flex", width: "60%", justifyContent: "space-between" }}>
                                                <div style={{ width: "47%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                    <Label style={{ fontSize: "10px" }}> Request </Label>
                                                </div>
                                                <div style={{ width: "47%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                    <Label style={{ fontSize: "10px" }}> Limit </Label>
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{ display: "flex", justifyContent: "space-between", width: "90%" }}>
                                            <Label style={subLabelStyle}> CPU (m) </Label>
                                            <div style={{ display: "flex", width: "60%", justifyContent: "space-between" }}>
                                                <Input style={{ marginBottom: "20px", width: "47%", textAlign: "center" }} value={appData.deployments.resources.cpu.requestCpu} name="requestCpu" onChange={handleChange} />
                                                <Input style={{ marginBottom: "20px", width: "47%", textAlign: "center" }} value={appData.deployments.resources.cpu.limitCpu} name="limitCpu" onChange={handleChange} />
                                            </div>
                                        </div>
                                        <div style={{ display: "flex", justifyContent: "space-between", width: "90%" }}>
                                            <Label style={subLabelStyle}> Memory (Mi) </Label>
                                            <div style={{ display: "flex", width: "60%", justifyContent: "space-between" }}>
                                                <Input style={{ marginBottom: "20px", width: "47%", textAlign: "center" }} value={appData.deployments.resources.memory.requestMem} name="requestMem" onChange={handleChange} />
                                                <Input style={{ marginBottom: "20px", width: "47%", textAlign: "center" }} value={appData.deployments.resources.memory.limitMem} name="limitMem" onChange={handleChange} />
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                                    </Box>
                                </div>
                                <div>
                                    <div style={{ width: "100%" }}>
                                        <div style={{ display: "flex", marginTop: "15px" }}>
                                            <div style={{ borderRadius: "100%", backgroundColor: depColor.tags ? "#132f44" : "lightgray", display: "flex", justifyContent: "center", alignItems: "center", padding: "0 10px", marginRight: "10px" }}><p style={{ fontFamily: "Poppins", fontSize: "22px", fontWeight: "bold", color: "white" }}>3</p></div>
                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}><p style={{ ...headLabelStyle }}> Tags </p></div>
                                        </div>
                                        <Form.List name="tags">
                                            {(fields, { add, remove }) => (
                                                <>
                                                    <Box onClick={() => {
                                                        setDepColor({
                                                            services: false,
                                                            resources: false,
                                                            taskSchedulers: false,
                                                            tags: true
                                                        });
                                                    }} style={{ border: "1px solid #f3f3f3", borderRadius: "10px", paddingTop: "0", marginTop: "20px", padding: "20px 20px 0 20px", backgroundColor: "#fbfbfb" }}>
                                                        {fields.map(({ name, ...restField }, ind) => (
                                                            <ContentHolder key={"tag-" + ind} style={{ display: "flex", justifyContent: 'space-between', alignItems: 'center' }}>
                                                                <div style={{ width: "48%" }}>
                                                                    <Form.Item
                                                                        name={[name, "name"]}
                                                                        rules={[
                                                                            {
                                                                                required: appData.deployments.tags[ind]['value'] !== "",
                                                                                message: 'Please enter a name for this tag!',
                                                                            },
                                                                            {
                                                                                validator: (_, value) => {
                                                                                    const interestingItems = new Set(["environment", "name", "provider"]);
                                                                                    let regExp = new RegExp(/^[a-z0-9]+$/i);
                                                                                    if (value && value !== "") {
                                                                                        value = value.replace(/\s/g, '');
                                                                                    }
                                                                                    if (value === "" || !value) {
                                                                                        return Promise.resolve();
                                                                                    }
                                                                                    else if (regExp.test(value)) {
                                                                                        if (!value || value === "" || !interestingItems.has(value.toLowerCase())) {
                                                                                            return Promise.resolve();
                                                                                        }
                                                                                        else {
                                                                                            return Promise.reject(new Error("A tag name can not be 'environment', 'name' or 'provider'."));
                                                                                        }
                                                                                    }
                                                                                    else {
                                                                                        return Promise.reject(new Error("A label can only have alphanumeric characters."));
                                                                                    }
                                                                                }
                                                                            },
                                                                        ]}
                                                                    >
                                                                        <Input
                                                                            name="name"
                                                                            id={ind}
                                                                            value={appData.deployments.tags[ind] && appData.deployments.tags[ind]['name']}
                                                                            onChange={(e) => { handleChange(e, ind) }}
                                                                            placeholder="Name"
                                                                            style={{ ...inputStyle }}
                                                                        />

                                                                    </Form.Item>
                                                                </div>
                                                                <div style={{ width: "48%" }}>
                                                                    <Form.Item
                                                                        name={[name, "value"]}
                                                                        rules={[
                                                                            {
                                                                                required: appData.deployments.tags[ind]['name'] !== "",
                                                                                message: 'Please enter a value for this tag!',
                                                                            },
                                                                            {
                                                                                validator: (_, value) => {
                                                                                    const interestingItems = new Set(["environment", "name", "provider"]);
                                                                                    let regExp = new RegExp(/^[a-z0-9]+$/i);
                                                                                    if (value && value !== "") {
                                                                                        value = value.replace(/\s/g, '');
                                                                                    } if (value === "" || !value) {
                                                                                        return Promise.resolve();
                                                                                    }
                                                                                    else if (regExp.test(value)) {
                                                                                        if (!value || value === "" || !interestingItems.has(value.toLowerCase())) {
                                                                                            return Promise.resolve();
                                                                                        }
                                                                                        else {
                                                                                            return Promise.reject(new Error("A tag name can not be 'environment', 'name' or 'provider'."));
                                                                                        }
                                                                                    }
                                                                                    else {
                                                                                        return Promise.reject(new Error("A label can only have alphanumeric characters."));
                                                                                    }
                                                                                }
                                                                            },
                                                                        ]}
                                                                    >
                                                                        <Input
                                                                            name="value"
                                                                            id={ind}
                                                                            value={appData.deployments.tags[ind] && appData.deployments.tags[ind]['value']}
                                                                            onChange={(e) => { handleChange(e, ind) }}
                                                                            placeholder="Value"
                                                                            style={{ ...inputStyle, margin: "0 10px" }}
                                                                        />
                                                                    </Form.Item>
                                                                </div>
                                                                {ind === 0 && !removeIcon && <Link id={ind} onClick={(e) => {
                                                                    if (appData.deployments.tags.length === 1) {
                                                                        appData.deployments.tags[e.currentTarget.id] = {
                                                                            name: "",
                                                                            value: ""
                                                                        };

                                                                        setAppData({ ...appData, deployments: appData.deployments });
                                                                        setRemoveIcon(true);
                                                                        const updatedItems = form.getFieldsValue().tags.map((item, idx) =>
                                                                            ind === idx ? { name: "", value: "" } : item
                                                                        );
                                                                        console.log("My updated items: ", updatedItems);
                                                                        form.setFieldValue("tags", updatedItems);
                                                                    }
                                                                    else {
                                                                        remove(name);
                                                                        appData.deployments.tags.splice(e.currentTarget.id, 1);
                                                                        setAppData({ ...appData, deployments: appData.deployments });
                                                                        setRemoveIcon(false);
                                                                    }
                                                                }} style={{ marginLeft: "10px", transform: "translateY(-10px)" }}>
                                                                    <img src={deleteIcon} />
                                                                </Link>}
                                                                {ind !== 0 && <Link id={ind} onClick={(e) => {
                                                                    remove(name);
                                                                    appData.deployments.tags.splice(e.currentTarget.id, 1);
                                                                    setAppData({ ...appData, deployments: appData.deployments });
                                                                    if (appData.deployments.tags.length > 1) {
                                                                        setRemoveIcon(false);
                                                                    }
                                                                    else {
                                                                        if (appData.deployments.tags[0].name === "" && appData.deployments.tags[0].value === "") {
                                                                            setRemoveIcon(true);
                                                                        }
                                                                        else {
                                                                            setRemoveIcon(false);
                                                                        }
                                                                    }
                                                                }} style={{ marginLeft: "10px", transform: "translateY(-10px)" }}>
                                                                    <img src={deleteIcon} />
                                                                </Link>}
                                                            </ContentHolder>
                                                        ))}
                                                        <div style={{ display: "flex", justifyContent: "flex-end", padding: "0 10px", marginBottom: "20px" }}>
                                                            <Button onClick={(e) => {
                                                                add();
                                                                appData.deployments.tags.push({
                                                                    name: "",
                                                                    value: ""
                                                                });
                                                                setAppData({ ...appData, deployments: appData.deployments });
                                                                setRemoveIcon(false);
                                                            }}
                                                                style={{
                                                                    backgroundColor: "#132f44", marginTop: "20px"
                                                                }}><p style={{ fontFamily: "Nunito Sans", fontSize: "14px", fontWeight: "600", color: "white" }}><PlusOutlined /> Add Tags </p></Button>
                                                        </div>
                                                    </Box>
                                                </>
                                            )}
                                        </Form.List>
                                    </div>
                                </div>
                            </div>
                        </div>}
                        {appData.deployments.deploymentOption === "yaml" && allOptions.deployments && <div>
                            {(yamlContent.Deployment === yamlData || yamlContent["Job & CronJob"] === yamlData || yamlContent.StatefulSet === yamlData) && <div>
                                <InfoCircleFilled style={{ color: "#8896A1" }} />
                                <span style={{ fontSize: "12px", marginLeft: "6px", marginBottom: "10px", fontFamily: "Nunito Sans" }}>This is a sample YAML file that provides a basic structure for deployment configurations. You can use it as a starting point and modify the content to match your specific requirements.</span>
                            </div>}
                            {console.log("YAML form: ", form.getFieldsValue("yamlData"))}
                            <Form.Item name="yamlData" rules={[{ required: true, message: "Please fill yaml content to proceed." }]} getValueFromEvent={(e) => e} valuePropName="value">
                                {/* <Suspense> */}
                                <CodeViewGeneral /*onChange={form.getFieldValue('yamlData')}*/ data={yamlData} setData={setYamlData} height={"400px"} customPadding={"0"} readOnly={false} form={form} />
                                {/* </Suspense> */}
                            </Form.Item>
                            {/* <Box style={{border: "0", padding: "0"}}>
                                <p key={idx} className="isoHelperText"></p>
                            </Box> */}
                            <div style={{ position: "relative", height: "50px" }}>
                                <Button style={{ position: "absolute", right: "0", backgroundColor: "#132f44", fontFamily: "Nunito Sans", fontSize: "14px", fontWeight: "600", color: "white", marginTop: "20px" }} onClick={async () => {
                                    try {
                                        const myDepData = updateDep ? updatedData.deploymentData : addDep ? addUpdatedData.deploymentData : cloneDep ? cloneDeploymentData : deploymentData;
                                        let myData = await validateYaml(yamlData, myDepData.clusterId, true, form);
                                        toggleAlert("success", "Validation Successful", "YAML file has been validated successfully.");
                                    }
                                    catch (err) {
                                        console.log("Error thrown", err);
                                        toggleAlert("error", "Validation Unsuccessful", "Your YAML code provided is invalid.");
                                    }
                                }}>Validate K8s YAML</Button>
                            </div>
                        </div>}
                    </Card>
                    {/* </Box> */}
                </div>

                {/* <Box title={
                    <div style={{ display: "flex", justifyContent: "space-between", cursor: "pointer" }} onClick={() => {
                        // setOptionsSelected(optionsSelected => ({
                        //     ...optionsSelected,
                        //     volumes: !optionsSelected.volumes
                        // }));
                        let options = {
                            // basicDetails: false,
                            // envVars: false,
                            // deployments: false,
                            ...allOptions.application,
                            volumes: allOptions.application.volumes
                        };
                        options["volumes"] = !options["volumes"];

                        setAllOptions((allOptions) => ({
                            ...allOptions,
                            application: options
                        }));
                        setApplicationKey(2);
                    }}>
                        <p> Volumes </p>
                        <img src={!allOptions.application.volumes ? downIcon : upIcon} style={{ cursor: "pointer", width: "12px", height: "16px" }} />
                    </div>
                } titleStyle={innerTitleStyle} style={{ borderRadius: "20px" }}>
                    {allOptions.application.volumes &&
                        <></>}
                </Box> */}
                {appData.deployments.deploymentOption === "visual" && <div style={{ marginBottom: "40px", cursor: "pointer" }} onClick={() => {
                    // setOptionsSelected(optionsSelected => ({
                    //     ...optionsSelected,
                    //     volumes: !optionsSelected.volumes
                    // }));
                    let options = {
                        // basicDetails: false,
                        // envVars: false,
                        // deployments: false,
                        ...allOptions,
                        advancedSettings: !allOptions.advancedSettings
                    };
                    setAllOptions({
                        ...options,
                    });
                    setApplicationKey(2);
                }}>
                    <Box title={
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <p> Advanced Settings </p>
                            <img src={!allOptions.advancedSettings ? downIcon : upIcon} style={{ width: "12px", height: "16px" }} />
                        </div>
                    } titleStyle={innerTitleStyle} style={{ borderRadius: "20px" }}>

                        {allOptions.advancedSettings && <div style={{ marginTop: "20px", cursor: "default" }} onClick={(e) => {
                            e.stopPropagation();
                        }}>
                            <div style={{ display: "flex" }}>
                                <div style={{ borderRadius: "100%", backgroundColor: depColor.volumes ? "#132f44" : "lightgray", display: "flex", justifyContent: "center", alignItems: "center", padding: "0 12px", marginRight: "10px" }}><p style={{ fontFamily: "Poppins", fontSize: "22px", fontWeight: "bold", color: "white" }}>1</p></div>
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}><p style={{ ...headLabelStyle }}> Volumes </p></div>
                            </div>
                            <div onClick={() => {
                                setDepColor({
                                    services: false,
                                    resources: false,
                                    taskSchedulers: false,
                                    tags: false,
                                    volumes: true
                                });
                            }}>
                                <Form.List name="volumeList">
                                    {(fields, { add, remove }) => (
                                        <>
                                            <div style={{ marginTop: "20px" }}>
                                                {fields.map(({ key, name, ...restField }, ind) => {
                                                    return (<Box style={{ borderRadius: "10px", backgroundColor: "#fbfbfb" }}>
                                                        <div>
                                                            <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "25px" }}>
                                                                {/* <Label style={labelStyle}> Configure </Label>
                                        <Switch style={{ backgroundColor: switchState.configure === "true" && "#132f44" }} checked={appData.volumes[ind].config.configEnabled} onChange={(checked) => {
                                            setSwitchState(switchState => ({
                                                ...switchState,
                                                configure: checked ? "true" : "false"
                                            }));
                                            // setAppData(appData => ({
                                            //     ...appData,
                                            //     [appData.volumes[ind]]: {
                                            //         ...appData.volumes,
                                            //         config: {
                                            //             ...appData.volumes[ind].config,
                                            //             configEnabled: checked
                                            //         }
                                            //     }
                                            // }));
                                            appData.volumes[ind].config.configEnabled = checked;
                                            setAppData({ ...appData });
                                        }} /> */}
                                                                <div style={{ width: "100%", display: "flex" }}>
                                                                    <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                                                                        <Label style={labelStyle}> Volume Type </Label>
                                                                        <div style={{ width: "100%" }}>
                                                                            <Select style={{ width: '47%' }} id={ind} placeholder="Select Volume Type" getPopupContainer={(triggerNode) => triggerNode.parentNode} value={appData.volumes[ind].volumeType === "" ? null : appData.volumes[ind].volumeType} onChange={(value, event) => { console.log("triggering: ", applicationData); handleSelectChange(value, event, ind); }}>
                                                                                {volumeTypes}
                                                                            </Select>
                                                                        </div>
                                                                    </div>
                                                                    {console.log(applicationData)}
                                                                    {ind === 0 && !removeVolumeIcon && <Link id={ind} onClick={(e) => {
                                                                        if (appData.volumes.length === 1) {
                                                                            appData.volumes[e.currentTarget.id] = {
                                                                                volumeType: "",
                                                                                config: {
                                                                                    configEnabled: false,
                                                                                    createConfig: "",
                                                                                    createMountPath: "",
                                                                                    configFileName: "",
                                                                                    configName: ""
                                                                                },
                                                                                storage: {
                                                                                    createStorage: false,
                                                                                    storageDes: {
                                                                                        name: "",
                                                                                        size: "",
                                                                                        type: "", //class
                                                                                        path: ""
                                                                                    }
                                                                                },
                                                                                secrets: {
                                                                                    secretsEnabled: false,
                                                                                    createSecrets: "",
                                                                                    createSecretsMountPath: "",
                                                                                    secretsFileName: "",
                                                                                    secretsName: ""
                                                                                }
                                                                            };

                                                                            setAppData({ ...appData, volumes: appData.volumes });
                                                                            setRemoveVolumeIcon(true);
                                                                        }
                                                                        else {
                                                                            remove(name);
                                                                            appData.volumes.splice(e.currentTarget.id, 1);
                                                                            setAppData({ ...appData, volumes: appData.volumes });
                                                                            setRemoveVolumeIcon(false);
                                                                        }
                                                                    }} style={{ marginRight: "10px" }}>
                                                                        <img src={deleteIcon} />
                                                                    </Link>}
                                                                    {ind !== 0 && <Link id={ind} onClick={(e) => {
                                                                        remove(name);
                                                                        appData.volumes.splice(e.currentTarget.id, 1);
                                                                        setAppData({ ...appData, volumes: appData.volumes });
                                                                        if (appData.volumes.length > 1) {
                                                                            setRemoveVolumeIcon(false);
                                                                        }
                                                                        else {
                                                                            if (appData.volumes[0].volumeType === "") {
                                                                                setRemoveVolumeIcon(true);
                                                                            }
                                                                            else {
                                                                                setRemoveVolumeIcon(false);
                                                                            }
                                                                        }
                                                                    }} style={{ marginRight: "10px" }}>
                                                                        <img src={deleteIcon} />
                                                                    </Link>}
                                                                </div>
                                                            </div>
                                                            {console.log("App data outer", appData)}
                                                            {appData.volumes[ind].volumeType === "Configmap" && <div aria-disabled={switchState.configure === "false" ? true : false}>
                                                                {console.log("App data inner", appData)}
                                                                <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "20px" }}>
                                                                    <div style={fieldStyles}>
                                                                        <Label style={subLabelStyle}> Name{appData.volumes[ind].volumeType && appData.volumes[ind].volumeType !== "" ? '*' : ''} </Label>
                                                                        <Form.Item
                                                                            name={[name, "configName"]}
                                                                            rules={[
                                                                                {
                                                                                    required: true,
                                                                                    message: 'Please enter a name for the selected volume type!',
                                                                                },
                                                                            ]}
                                                                        >
                                                                            <Input placeholder="Enter name" style={inputStyle} value={appData.volumes[ind].config.configName} name="configName" onChange={(e) => {
                                                                                handleChange(e, ind);
                                                                            }} />
                                                                        </Form.Item>
                                                                    </div>
                                                                    <div style={fieldStyles}>
                                                                        <Label style={subLabelStyle}> Mount Path{appData.volumes[ind].volumeType && appData.volumes[ind].volumeType !== "" ? '*' : ''} </Label>
                                                                        <Form.Item
                                                                            name={[name, "createMountPath"]}
                                                                            rules={[
                                                                                {
                                                                                    required: true,
                                                                                    message: 'Please enter mount path for the selected volume type!',
                                                                                },
                                                                            ]}
                                                                        >
                                                                            <Input placeholder="Enter mount path" style={inputStyle} value={appData.volumes[ind].config.createMountPath} name="createMountPath" onChange={(e) => {
                                                                                handleChange(e, ind);
                                                                            }} />
                                                                        </Form.Item>
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <Label style={subLabelStyle}> Data{appData.volumes[ind].volumeType && appData.volumes[ind].volumeType !== "" ? '*' : ''} </Label>
                                                                    {/* <TextArea autoSize={{ minRows: 6, maxRows: 12 }} value={appData.volumes[ind].config.createConfig} placeholder="Enter Variable Here" style={{ marginBottom: "20px" }} name="createConfig" onChange={handleChange} /> */}
                                                                    {/* <Textarea value={appData.volumes[ind].config.createConfig} placeholder="Enter Variable Here" style={{ marginBottom: "20px", height: "100px" }} name="createConfig" onChange={handleChange} /> */}
                                                                </div>
                                                                <Form.Item
                                                                    name={[name, "createConfig"]}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'Please enter data for the secret!',
                                                                        },
                                                                    ]}
                                                                >
                                                                    <TextArea
                                                                        placeholder="Enter Data"
                                                                        autoSize={{ minRows: 6, maxRows: 12 }}
                                                                        name="createConfig"
                                                                        value={appData.volumes[ind].config.createConfig}
                                                                        onChange={(e) => {
                                                                            handleChange(e, ind);
                                                                        }}
                                                                        style={{ marginBottom: "20px" }}
                                                                    />
                                                                </Form.Item>
                                                            </div>}
                                                            {appData.volumes[ind].volumeType === "Secrets" && <div aria-disabled={switchState.configure === "false" ? true : false}>
                                                                {console.log("App data inner", appData)}
                                                                <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "20px" }}>
                                                                    <div style={fieldStyles}>
                                                                        <Label style={subLabelStyle}> Name{appData.volumes[ind].volumeType && appData.volumes[ind].volumeType !== "" ? '*' : ''} </Label>
                                                                        <Form.Item
                                                                            name={[name, "secretsName"]}
                                                                            rules={[
                                                                                {
                                                                                    required: true,
                                                                                    message: 'Please enter a name for the selected volume!',
                                                                                },
                                                                            ]}
                                                                        >
                                                                            <Input placeholder="Enter name" style={inputStyle} value={appData.volumes[ind].secrets.secretsName} name="secretsName" onChange={(e) => {
                                                                                handleChange(e, ind);
                                                                            }} />
                                                                        </Form.Item>
                                                                    </div>
                                                                    <div style={fieldStyles}>
                                                                        <Label style={subLabelStyle}> Mount Path{appData.volumes[ind].volumeType && appData.volumes[ind].volumeType !== "" ? '*' : ''} </Label>
                                                                        <Form.Item
                                                                            name={[name, "createSecretsMountPath"]}
                                                                            rules={[
                                                                                {
                                                                                    required: true,
                                                                                    message: 'Please enter a mount path!',
                                                                                },
                                                                            ]}
                                                                        >
                                                                            <Input placeholder="Enter mount path" style={inputStyle} value={appData.volumes[ind].secrets.createSecretsMountPath} name="createSecretsMountPath" onChange={(e) => {
                                                                                handleChange(e, ind);
                                                                            }} />
                                                                        </Form.Item>
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <Label style={subLabelStyle}> Data{appData.volumes[ind].volumeType && appData.volumes[ind].volumeType !== "" ? '*' : ''} </Label>
                                                                    {/* <TextArea autoSize={{ minRows: 6, maxRows: 12 }} value={appData.volumes[ind].config.createConfig} placeholder="Enter Variable Here" style={{ marginBottom: "20px" }} name="createConfig" onChange={handleChange} /> */}
                                                                    {/* <Textarea value={appData.volumes[ind].config.createConfig} placeholder="Enter Variable Here" style={{ marginBottom: "20px", height: "100px" }} name="createConfig" onChange={handleChange} /> */}
                                                                </div>
                                                                <Form.Item
                                                                    name={[name, "createSecrets"]}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'Please enter data for the secret!',
                                                                        },
                                                                    ]}
                                                                >
                                                                    <TextArea
                                                                        placeholder="Enter Data"
                                                                        autoSize={{ minRows: 6, maxRows: 12 }}
                                                                        name="createSecrets"
                                                                        value={appData.volumes[ind].secrets.createSecrets}
                                                                        onChange={(e) => {
                                                                            handleChange(e, ind);
                                                                        }}
                                                                        style={{ marginBottom: "20px" }}
                                                                    />
                                                                </Form.Item>
                                                            </div>}
                                                            {appData.volumes[ind].volumeType === "Storage" && <div>
                                                                {/* <div style={{ width: "20%", display: "flex", justifyContent: "space-between", marginBottom: "25px" }}>
                                        <Label style={labelStyle}> Create Storage </Label>
                                        <Switch style={{ backgroundColor: switchState.createStorage === "true" && "#132f44" }} checked={appData.volumes[ind].storage.createStorage} onChange={(checked) => {
                                            setSwitchState(switchState => ({
                                                ...switchState,
                                                createStorage: checked ? "true" : "false"
                                            }));
                                            // setAppData(appData => ({
                                            //     ...appData,
                                            //     volumes: [{
                                            //         ...appData.volumes[ind],
                                            //         storage: {
                                            //             ...appData.volumes[ind].storage,
                                            //             createStorage: checked
                                            //         }
                                            //     }]
                                            // }));
                                            appData.volumes[ind].storage.createStorage = checked;
                                            setAppData({ ...appData });
                                        }} />
                                    </div> */}
                                                                {/* <div style={switchState.createStorage === "false" ? { ...subDivStyle, pointerEvents: "none", opacity: "0.5" } : { ...subDivStyle }} aria-disabled={switchState.createStorage === "false" ? true : false} aria-readonly="true">
                                        <Label style={subLabelStyle}> Storage </Label>
                                        <div style={{ display: "flex", marginBottom: "20px" }}>
                                            <Input placeholder="Size" style={{ ...inputStyle, marginRight: "10px" }} value={appData.volumes[ind].storage.storageDes.size} name="size" onChange={handleChange} />
                                            <Input placeholder="Type" style={{ ...inputStyle, marginRight: "10px" }} value={appData.volumes[ind].storage.storageDes.type} name="type" onChange={handleChange} />
                                            <Input placeholder="Path" style={{ ...inputStyle, marginRight: "10px" }} value={appData.volumes[ind].storage.storageDes.path} name="path" onChange={handleChange} />
                                        </div>
                                    </div> */}

                                                                <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "20px" }}>
                                                                    <div style={fieldStyles}>
                                                                        <Label style={subLabelStyle}> Name{appData.volumes[ind].volumeType && appData.volumes[ind].volumeType !== "" ? '*' : ''} </Label>
                                                                        <Form.Item
                                                                            name={[name, "storageName"]}
                                                                            rules={[
                                                                                {
                                                                                    required: true,
                                                                                    message: 'Please enter a name for the selected volume!',
                                                                                },
                                                                            ]}
                                                                        >
                                                                            <Input placeholder="Enter name" style={inputStyle} value={appData.volumes[ind].storage.storageDes.storageName} name="storageName" onChange={(e) => {
                                                                                handleChange(e, ind);
                                                                            }} />
                                                                        </Form.Item>
                                                                    </div>
                                                                    <div style={fieldStyles}>
                                                                        <Label style={subLabelStyle}> Class{appData.volumes[ind].volumeType && appData.volumes[ind].volumeType !== "" ? '*' : ''} </Label>
                                                                        {/* <Input placeholder="Enter Variable Here" style={inputStyle} value={appData.volumes[ind].storage.storageDes.type} name="type" onChange={handleChange} /> */}
                                                                        <div style={{ width: "100%" }}>
                                                                            <Form.Item
                                                                                name={[name, "type"]}
                                                                                rules={[
                                                                                    {
                                                                                        required: true,
                                                                                        message: 'Please select type of storage!',
                                                                                    },
                                                                                ]}
                                                                            >
                                                                                <Select placeholder="Select storage class" style={{ width: "100%" }} getPopupContainer={(triggerNode) => triggerNode.parentNode} value={appData.volumes[ind].storage.storageDes.type === "" ? null : appData.volumes[ind].storage.storageDes.type} onChange={(event, value) => {
                                                                                    handleSelectChange(event, value, ind)
                                                                                }}>
                                                                                    {volumeClasses}
                                                                                </Select>
                                                                            </Form.Item>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "20px" }}>
                                                                    <div style={fieldStyles}>
                                                                        <Label style={subLabelStyle}> Size{appData.volumes[ind].volumeType && appData.volumes[ind].volumeType !== "" ? '*' : ''} </Label>
                                                                        <Form.Item
                                                                            name={[name, "size"]}
                                                                            rules={[
                                                                                {
                                                                                    required: true,
                                                                                    message: 'Please enter size for the selected volume!',
                                                                                },
                                                                                {
                                                                                    validator: validateSize
                                                                                }
                                                                            ]}
                                                                        >
                                                                            <Input placeholder="Enter size" style={inputStyle} value={appData.volumes[ind].storage.storageDes.size} suffix="GB" name="size" onChange={(e) => {
                                                                                handleChange(e, ind);
                                                                            }} />
                                                                        </Form.Item>
                                                                    </div>
                                                                    <div style={fieldStyles}>
                                                                        <Label style={subLabelStyle}> Mount Path{appData.volumes[ind].volumeType && appData.volumes[ind].volumeType !== "" ? '*' : ''} </Label>
                                                                        <Form.Item
                                                                            name={[name, "path"]}
                                                                            rules={[
                                                                                {
                                                                                    required: true,
                                                                                    message: 'Please enter a mount path!',
                                                                                },
                                                                            ]}
                                                                        >
                                                                            <Input placeholder="Enter mount path" style={inputStyle} value={appData.volumes[ind].storage.storageDes.path} name="path" onChange={(e) => {
                                                                                handleChange(e, ind);
                                                                            }} />
                                                                        </Form.Item>
                                                                    </div>
                                                                </div>
                                                            </div>}
                                                        </div>
                                                    </Box>);
                                                })}
                                                <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: "20px" }}>
                                                    <Button onClick={(e) => {
                                                        add();
                                                        appData.volumes.push({
                                                            volumeType: "",
                                                            config: {
                                                                configEnabled: false,
                                                                createConfig: "",
                                                                createMountPath: "",
                                                                configFileName: "",
                                                                configName: ""
                                                            },
                                                            storage: {
                                                                createStorage: false,
                                                                storageDes: {
                                                                    name: "",
                                                                    size: "",
                                                                    type: "", //class
                                                                    path: ""
                                                                }
                                                            },
                                                            secrets: {
                                                                secretsEnabled: false,
                                                                createSecrets: "",
                                                                createSecretsMountPath: "",
                                                                secretsFileName: "",
                                                                secretsName: ""
                                                            }
                                                        });
                                                        setAppData({ ...appData, volumes: appData.volumes });
                                                    }}
                                                        style={{ marginRight: "10px", backgroundColor: "#132f44" }}
                                                    ><div> <p style={{ fontFamily: "Nunito Sans", fontSize: "14px", fontWeight: "600", color: "white" }}> <PlusOutlined style={{ color: "white", marginRight: "10px" }} /> Add Volumes </p> </div></Button>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </Form.List>
                            </div>
                            <div style={{ marginBottom: "20px" }}>
                                <div style={{ display: "flex", marginTop: "15px" }}>
                                    <div style={{ borderRadius: "100%", backgroundColor: depColor.taskSchedulers ? "#132f44" : "lightgray", display: "flex", justifyContent: "center", alignItems: "center", padding: "0 10px", marginRight: "10px" }}><p style={{ fontFamily: "Poppins", fontSize: "22px", fontWeight: "bold", color: "white" }}>2</p></div>
                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}><p style={{ ...headLabelStyle }}> Task Schedulers </p></div>
                                </div>
                                <Box onClick={() => {
                                    setDepColor({
                                        services: false,
                                        resources: false,
                                        taskSchedulers: true,
                                        tags: false
                                    });
                                }} style={{ border: "1px solid #f3f3f3", borderRadius: "10px", marginTop: "20px", padding: "20px 20px 0 20px", backgroundColor: "#fbfbfb" }}>
                                    <div style={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
                                        {console.log("First app data: ", appData)}
                                        <div style={{ width: "20%", display: "flex", justifyContent: "space-between", marginBottom: "25px", width: "47%" }}>
                                            <Label style={headLabelStyle}> Crons </Label>
                                            <Switch style={{ backgroundColor: appData.deployments.taskSchedulers.crons && "#132f44" }} checked={appData.deployments.taskSchedulers.crons} onChange={(checked) => {
                                                setAppData(appData => ({
                                                    ...appData,

                                                    deployments: {
                                                        ...appData.deployments,
                                                        taskSchedulers: {
                                                            ...appData.deployments.taskSchedulers,
                                                            entryPointCommandCrons: "",
                                                            crons: checked
                                                        }
                                                    }
                                                }));
                                            }} />
                                        </div>
                                        <div style={{ width: "20%", display: "flex", justifyContent: "space-between", marginBottom: "25px", width: "47%" }}>
                                            <Label style={headLabelStyle}> Queues and Workers </Label>
                                            <Switch style={{ backgroundColor: appData.deployments.taskSchedulers.queuesAndWorkers && "#132f44" }} checked={appData.deployments.taskSchedulers.queuesAndWorkers} onChange={(checked) => {

                                                setAppData(appData => ({
                                                    ...appData,

                                                    deployments: {
                                                        ...appData.deployments,
                                                        taskSchedulers: {
                                                            ...appData.deployments.taskSchedulers,
                                                            entryPointCommandQueues: "",
                                                            queuesAndWorkers: checked
                                                        }
                                                    }
                                                }));
                                            }} />
                                        </div>
                                    </div>
                                    <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                                        <div style={!appData.deployments.taskSchedulers.crons ? { width: "47%", opacity: "0.7", pointerEvents: "none" } : { width: "47%" }}>
                                            <div>
                                                <Label style={subLabelStyle}> Entry Point Command(Enter foreground command only){appData.deployments.taskSchedulers.crons ? '*' : ''} </Label>
                                            </div>
                                            <Form.Item
                                                name="entryPointCommandCrons"
                                                rules={[
                                                    {
                                                        required: appData.deployments.taskSchedulers.crons,
                                                        message: 'Please enter entry point command!',
                                                    },
                                                ]}
                                            >
                                                <TextArea
                                                    placeholder="Enter Command"
                                                    autoSize={{ minRows: 6, maxRows: 12 }}
                                                    name="entryPointCommandCrons"
                                                    value={appData.deployments.taskSchedulers.entryPointCommandCrons}
                                                    onChange={(e) => {
                                                        handleChange(e);
                                                    }}
                                                />
                                            </Form.Item>
                                        </div>
                                        <div style={!appData.deployments.taskSchedulers.queuesAndWorkers ? { width: "47%", opacity: "0.7", pointerEvents: "none" } : { width: "47%" }}>
                                            <div>
                                                <Label style={subLabelStyle}> Entry Point Command(Enter foreground command only){appData.deployments.taskSchedulers.queuesAndWorkers ? '*' : ''} </Label>
                                            </div>
                                            <Form.Item
                                                name="entryPointCommandQueues"
                                                rules={[
                                                    {
                                                        required: appData.deployments.taskSchedulers.queuesAndWorkers,
                                                        message: 'Please enter entry point command!',
                                                    },
                                                ]}
                                            >
                                                <TextArea
                                                    placeholder="Enter Command"
                                                    autoSize={{ minRows: 6, maxRows: 12 }}
                                                    name="entryPointCommandQueues"
                                                    value={appData.deployments.taskSchedulers.entryPointCommandQueues}
                                                    onChange={(e) => {
                                                        handleChange(e);
                                                    }} />
                                            </Form.Item>
                                        </div>
                                    </div>
                                </Box>
                            </div>
                        </div>}
                    </Box>
                </div>}

                {/* <Box title={<div style={{ display: "flex", justifyContent: "space-between", width: "100%", cursor: "pointer" }} onClick={() => {
                    let options = {
                        ...allOptions.application,
                        pipelines: allOptions.application.pipelines,
                    };
                    options["pipelines"] = !options["pipelines"];

                    setAllOptions((allOptions) => ({
                        ...allOptions,
                        application: options
                    }));
                    setApplicationKey(4);
                }}>
                    <p> Pipeline </p>
                    <img src={!allOptions.application.pipelines ? downIcon : upIcon} style={{ cursor: "pointer", width: "12px", height: "12px" }} />
                </div>} titleStyle={innerTitleStyle} style={{ borderRadius: "20px", boxShadow: allOptions.application.pipelines ? "0 4px 4px 0 rgba(0, 0, 0, 0.25)" : "" }}>
                    {allOptions.application.pipelines && <div style={{ marginTop: "20px" }}>
                        <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "250px" }}>
                            <div style={fieldStyles}>
                                <Label style={labelStyle}> Agent Name </Label>
                                <Form.Item
                                    name="agentName"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Please select Agent Name!',
                                        },
                                    ]}
                                >
                                    <Select style={{ width: '100%' }} showSearch filterOption={filterBranches} placeholder="Select branch" value={appData.basicDetails.branch === "" ? null : appData.basicDetails.branch} onChange={handleSelectChange}>
                                        {agentList}
                                    </Select>
                                </Form.Item>
                            </div>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "250px", marginInline: "100px" }}>
                            <div style={{...fieldStyles, flexDirection: "row"}}>
                                    <span>
                                        <img src={pipelineSourceIcon} style={pipelineImgStyle} alt="source"  />
                                    </span>
                                    <span style={{position: "relative", display: "flex", alignItems: "center"}}>
                                        <hr style={hzLineStyle} />
                                        <img src={pipelinePlusIcon} style={plusImgStyle} alt="plus-icon" />
                                    </span>
                                    <span>
                                        <img src={pipelinePreBuildIcon} alt="source" style={pipelineImgStyle}  />
                                    </span>
                                    <span style={{position: "relative", display: "flex", alignItems: "center"}}>
                                        <hr style={hzLineStyle} />
                                        <img src={pipelinePlusIcon} style={plusImgStyle} alt="plus-icon" />
                                    </span>
                                    <span>
                                        <img src={pipelineBuildIcon} alt="source" style={pipelineImgStyle}  />
                                    </span>
                                    <span style={{position: "relative", display: "flex", alignItems: "center"}}>
                                        <hr style={hzLineStyle} />
                                        <img src={pipelinePlusIcon} style={plusImgStyle} alt="plus-icon" />
                                    </span>
                                    <span>
                                        <img src={pipelinePreBuildIcon} alt="source" style={pipelineImgStyle}  />
                                    </span>
                                    <span style={{position: "relative", display: "flex", alignItems: "center"}}>
                                        <hr style={hzLineStyle} />
                                        <img src={pipelinePlusIcon} style={plusImgStyle} alt="plus-icon" />
                                    </span>
                                    <span>
                                        <img src={pipelineDeployIcon} alt="source" style={pipelineImgStyle}  />
                                    </span>
                            </div>
                        </div>
                    </div>}
                    {error && error.status && <p className="isoHelperText">  {error.msg} </p>}
                </Box> */}

            </Form>
            {console.log("Current app Data", cloneApplicationData)}
            {console.log("Service enabled: ", appData.serviceEnabled, serviceSelected)}
            <div ref={buttonRef1}>
                {(!updateDep && !addDep && (!cloneDep && applicationData && applicationData[serviceSelected.name] && applicationData[serviceSelected.name].serviceEnabled) ||
                    (cloneDep && cloneApplicationData && cloneApplicationData[serviceSelected.name] && cloneApplicationData[serviceSelected.name].serviceEnabled)) &&
                    /*(addDep && addUpdatedData.applicationData && addUpdatedData.applicationData[serviceSelected.name] && addUpdatedData.applicationData[serviceSelected.name].serviceEnabled)) &&*/
                    <div style={{ marginTop: "20px", display: "flex", justifyContent: "flex-end" }}>
                        <Button style={{ backgroundColor: "#132f44", marginTop: "20px", fontFamily: "Nunito Sans", fontSize: "14px", fontWeight: "600", color: "white" }} onClick={() => {
                            if (keySelected !== lastEnabledService) {
                                handleSave();
                            }
                            else {
                                setPrerequisite(true);
                                setIsSubmitted(true);
                                //handleSubmit();
                            }
                        }}> {(keySelected !== lastEnabledService) ? "Save and Next" : "Create Environment"}
                        </Button>
                    </div>}
            </div>
            {updateDep && <div style={{ marginTop: "20px", display: "flex", justifyContent: "flex-end" }}>
                <Button name="cancel" onClick={handleCancelButton} style={{ marginTop: "20px", marginRight: "10px", border: "1px solid #132f44", color: "#12f44", fontSize: "14px", fontFamily: "Nunito Sans", fontWeight: "600", height: "44px" }}>
                    Cancel
                </Button>
                <Button style={{ backgroundColor: "#132f44", marginTop: "20px", fontFamily: "Nunito Sans", fontSize: "14px", fontWeight: "600", color: "white", height: "44px", opacity: updateEnabled ? "1" : "0.7", pointerEvents: !updateEnabled && "none" }} onClick={() => {
                    if (updateEnabled) {
                        handleSave();
                    }
                    //setPrerequisite(true);
                }}> Re-deploy
                </Button>
            </div>}
        </div>
        // </Scrollbars>
    );
});