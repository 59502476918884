import React, { useCallback } from 'react';

// debounce function
const debounce = (func, wait = 200) => {
    let timeout;
    return function executedFunction(...args) {
        const later = () => {
            clearTimeout(timeout);
            func(...args);
        };
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
    };
};

function useDebounce(callback, delay = 1000, deps = []) {
    const debouncedCallback = useCallback(debounce(callback, delay), [delay, ...deps]);

    React.useEffect(() => {
        return () => {
            debouncedCallback.cancel?.(); // Optional chaining to avoid undefined errors
        };
    }, [delay, ...deps]);

    return debouncedCallback;
}

export default useDebounce;
